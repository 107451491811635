import { listToObject } from 'utils/data';

import * as actions from './actions';

const INITIAL_STATE = {
  initialized: false,
  products: {},
  productBrands: {},
  productCategories: {},
  productTypes: {},
  productRanges: {},
  skinConcerns: {},
  skinTypes: {},
};

const productsReducers = (currentState, action) => {
  const state = currentState ? currentState : INITIAL_STATE;

  let products;
  let productBrands;
  let productCategories;
  let productTypes;
  let productRanges;
  let skinConcerns;
  let skinTypes;

  switch (action.type) {
    case actions.INITIALIZE:
      return { ...state, initialized: true };

    case actions.SET_PRODUCTS:
      products = listToObject(action.data, 'id');
      return { ...state, products };
    case actions.SET_PRODUCT:
      const product = action.data;
      products = { ...state.products };
      products[product.id] = product;
      return { ...state, products };

    case actions.SET_PRODUCT_BRANDS:
      productBrands = listToObject(action.data, 'id');
      return { ...state, productBrands };
    case actions.SET_PRODUCT_BRAND:
      const productBrand = action.data;
      productBrands = { ...state.productBrands };
      productBrands[productBrand.id] = productBrand;
      return { ...state, productBrands };
    case actions.DELETE_PRODUCT_BRAND:
      productBrands = { ...state.productBrands };
      delete productBrands[action.id];
      return { ...state, productBrands };

    case actions.SET_PRODUCT_CATEGORIES:
      productCategories = listToObject(action.data, 'id');
      return { ...state, productCategories };
    case actions.SET_PRODUCT_CATEGORY:
      const productCategory = action.data;
      productCategories = { ...state.productCategories };
      productCategories[productCategory.id] = productCategory;
      return { ...state, productCategories };

    case actions.SET_PRODUCT_TYPES:
      productTypes = listToObject(action.data, 'id');
      return { ...state, productTypes };
    case actions.SET_PRODUCT_TYPE:
      const productType = action.data;
      productTypes = { ...state.productTypes };
      productTypes[productType.id] = productType;
      return { ...state, productTypes };
    case actions.DELETE_PRODUCT_TYPE:
      productTypes = { ...state.productTypes };
      delete productTypes[action.id];
      return { ...state, productTypes };

    case actions.SET_PRODUCT_RANGES:
      productRanges = listToObject(action.data, 'id');
      return { ...state, productRanges };
    case actions.SET_PRODUCT_RANGE:
      const productRange = action.data;
      productRanges = { ...state.productRanges };
      productRanges[productRange.id] = productRange;
      return { ...state, productRanges };
    case actions.DELETE_PRODUCT_RANGE:
      productRanges = { ...state.productRanges };
      delete productRanges[action.id];
      return { ...state, productRanges };

    case actions.SET_SKIN_CONCERNS:
      skinConcerns = listToObject(action.data, 'id');
      return { ...state, skinConcerns };
    case actions.SET_SKIN_CONCERN:
      const skinConcern = action.data;
      skinConcerns = { ...state.skinConcerns };
      skinConcerns[skinConcern.id] = skinConcern;
      return { ...state, skinConcerns };
    case actions.DELETE_SKIN_CONCERN:
      skinConcerns = { ...state.skinConcerns };
      delete skinConcerns[action.id];
      return { ...state, skinConcerns };

    case actions.SET_SKIN_TYPES:
      skinTypes = listToObject(action.data, 'id');
      return { ...state, skinTypes };
    case actions.SET_SKIN_TYPE:
      const skinType = action.data;
      skinTypes = { ...state.skinTypes };
      skinTypes[skinType.id] = skinType;
      return { ...state, skinTypes };
    case actions.DELETE_SKIN_TYPE:
      skinTypes = { ...state.skinTypes };
      delete skinTypes[action.id];
      return { ...state, skinTypes };

    default:
      return state;
  }
};

export const reducers = {
  products: productsReducers,
};
