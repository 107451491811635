import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { Page, Breadcrumbs, Block, Button } from '@borrow-ui/ui';

import { PRODUCTS_PRODUCTS_BASE_URL, PRODUCTS_PRODUCTS_BREADCRUMBS } from 'apps/products/constants';
import { ProductDetail } from 'apps/products/components/product/ProductDetail';
import { ProductPublishToDdbButton } from 'apps/products/components/product/product-publish-to-ddb-button/ProductPublishToDdbButton';
import { ProductRevalidateButton } from 'apps/products/components/product/product-revalidate-button/ProductRevalidateButton';
import { userCanPublishDdb } from 'utils/userGroups';

export function ProductDetailPage() {
  const { products } = useSelector((s) => s.products);
  const { activeEnvironment } = useSelector((s) => s.portal);

  const { id: productId } = useParams();

  const product = products[+productId];

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PRODUCTS_PRODUCTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">{product.title}</h2>
        </>
      }
      pageHeaderProps={{
        controls: (
          <>
            {userCanPublishDdb(activeEnvironment.codename) && (
              <ProductPublishToDdbButton product={product} />
            )}
            <ProductRevalidateButton product={product} />
            <Button
              tag={Link}
              to={`${PRODUCTS_PRODUCTS_BASE_URL}/${productId}/edit`}
              className="m-r-5"
              mean="regular-reverse"
            >
              Edit
            </Button>
          </>
        ),
      }}
    >
      <Block outstanding={true}>
        <ProductDetail product={product} />
      </Block>
    </Page>
  );
}
