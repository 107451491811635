import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { Block, Row, Col, Badge, Title, Button, Icon } from '@borrow-ui/ui';

import { authenticationService } from 'libs/authentication/authentication';
import { getInitials } from 'utils/user';
import { PREFERENCES_BASE_URL } from 'apps/portal/constants';

export function UserHeaderBody({ user, resetState }) {
  return (
    <Block>
      <Row>
        <Col colClassName="col-xs-6 col-sm-2 flex-center-center">
          <Badge type="circular" color="accent" style={{ width: 62, height: 62 }}>
            <Title>{getInitials(user)}</Title>
          </Badge>
        </Col>
        <Col colClassName="col-xs-6 col-sm-4"></Col>
        <Col colClassName="col-xs-6 col-sm-3"></Col>
        <Col colClassName="col-xs-6 col-sm-3">
          <Title tag="h3" className="m-t-0">
            Actions
          </Title>
          <div>
            <Button
              mean="regular-reverse"
              onClick={resetState}
              tag={RouterLink}
              to={`${PREFERENCES_BASE_URL}/user#change-password`}
              className="w-200"
            >
              <Icon name="vpn_key" size="small" className="m-r-5" /> Change Password
            </Button>
          </div>
          <div>
            <Button
              data-user-header-button="logout"
              mean="negative"
              onClick={authenticationService.logout}
              className="w-200"
            >
              <Icon name="logout" size="small" className="m-r-5" /> Logout
            </Button>
          </div>
        </Col>
      </Row>
    </Block>
  );
}

UserHeaderBody.propTypes = {
  user: PropTypes.object.isRequired,
  resetState: PropTypes.func.isRequired,
};
