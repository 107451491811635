import { postRequest } from 'libs/requests/requests';
import { asyncTaskEntity } from 'models/common/asyncTask';
import BaseEntity from 'models/common/base';

import { SET_TREATMENT, SET_TREATMENTS } from '../actions';

export const BASE_URL = 'api/treatments/treatments/';
export const TREATMENT_INGESTION_TASK_NAME = 'TREATMENT_INGESTION';

export function newTreatment() {
  return {
    id: null,
    tenant: null,
    ecomm_id: '',
    crm_id: '',
    status: 'DRAFT',
    treatment_type: 'SERVICE',
    title: '',
    crm_title: '',
    slug: '',
    nua_slug: '',
    description: '',
    category: null,
    price: 0,
    compare_price: null,
    number_of_sessions: 1,
    number_of_cycles: 1,
    pay_in_clinic: false,
    on_sale: false,
    bnpl_enabled: false,
    bnpl_surcharge_percentage: null,
    bnpl_installments: 0,
    handle: false,
    image_original: '',
    image_thumbnail: '',
    course_services: [],
    has_errors: false,
    errors: '',
  };
}

export class TreatmentEntity extends BaseEntity {
  constructor(properties) {
    super('TreatmentsTreatment', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  get(id, options) {
    return super.get(id, { actionSingleSet: SET_TREATMENT, ...options });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_TREATMENTS,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_TREATMENT });
  }

  publishToDashDdb(source, environment, tenant, id) {
    return postRequest('api/treatments/publish-to-dash-ddb/', {
      source,
      environment,
      tenant,
      treatment_id: id,
    });
  }

  createIngestionTask() {
    return asyncTaskEntity.create(TREATMENT_INGESTION_TASK_NAME);
  }

  getIngestionTask(taskId) {
    return asyncTaskEntity.get(taskId);
  }

  startIngestionTask(environment, tenant, taskId) {
    return postRequest(`api/treatments/ingest/${tenant}/${environment}/`, {
      async_task_id: taskId,
    });
  }
}

export const treatmentsEntity = new TreatmentEntity();
