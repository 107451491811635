export const productHasWarnings = (p) =>
  !p.image_original || !p.image_thumbnail || !p.slug || !p.nua_slug || !p.sku;

export function productWarningAttrs(product) {
  if (!product) return [];

  const warningAttrs = [];
  if (!product.slug) warningAttrs.push('slug');
  if (!product.nua_slug) warningAttrs.push('nua_slug');
  if (!product.sku) warningAttrs.push('sku');
  if (!product.image_original) warningAttrs.push('image_original');
  if (!product.image_thumbnail) warningAttrs.push('image_thumbnail');
  return warningAttrs;
}
