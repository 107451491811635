import React from 'react';
import PropTypes from 'prop-types';

import { propTypesChildren } from '../../../utils/types';
import { UI_PREFIX } from '../../../config';
import { LAYOUTS, ALIGNMENTS } from '../constants';

const LABEL_CLASS = `${UI_PREFIX}__form__label`;
// alignment class is autogenerated:
// const LABEL_ALIGNMENT_LEFT_CLASS = `${UI_PREFIX}__form__label--alignment-${alignment}`;
const LABEL_VERTICAL_CLASS = `${UI_PREFIX}__form__label--${LAYOUTS.VERTICAL}`;
const LABEL_HORIZONTAL_CLASS = `${UI_PREFIX}__form__label--${LAYOUTS.HORIZONTAL}`;
const LABEL_REQUIRED_CLASS = `${UI_PREFIX}__form__label--required`;

export function Label({
  label,
  className = '',
  layout = LAYOUTS.DEFAULT,
  width,
  alignment = ALIGNMENTS.DEFAULT,
  style,
  required,
  ...rest
}) {
  const layoutClass = layout === LAYOUTS.VERTICAL ? LABEL_VERTICAL_CLASS : LABEL_HORIZONTAL_CLASS;
  const labelWidthStyle = {};
  if (width) {
    labelWidthStyle.width = width;
    // Required for long texts in the controller part of field due to flex property
    labelWidthStyle.minWidth = width;
  }
  const alignmentClass = `${UI_PREFIX}__form__label--alignment-${alignment}`;

  return (
    <div
      className={`${LABEL_CLASS} ${layoutClass} ${alignmentClass} ${className}`.trim()}
      style={{ ...labelWidthStyle, ...style }}
      {...rest}
    >
      {label} {required && <span className={LABEL_REQUIRED_CLASS}>*</span>}
    </div>
  );
}

Label.propTypes = {
  label: propTypesChildren.isRequired,
  required: PropTypes.bool,
  layout: PropTypes.oneOf([LAYOUTS.HORIZONTAL, LAYOUTS.VERTICAL]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignment: PropTypes.oneOf([ALIGNMENTS.LEFT, ALIGNMENTS.CENTER, ALIGNMENTS.RIGHT]),
  className: PropTypes.string,
  style: PropTypes.object,
};
