import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL } from 'apps/products/constants';
import { ProductsConsolidationHomePage } from './ProductsConsolidationHomePage';

export { PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL };

export function ProductsConsolidationRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={`${PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL}`}
        component={ProductsConsolidationHomePage}
      />
    </Switch>
  );
}
