import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from '@borrow-ui/ui';

import { ProductForm } from './ProductForm';

export function EditProductModal({ product }) {
  return (
    <Modal
      Trigger={({ setVisible }) => {
        return (
          <div className="flex-end-center">
            <Button mean="primary" onClick={() => setVisible(true)}>
              Edit
            </Button>
          </div>
        );
      }}
      getModalWindowProps={({ setVisible }) => ({
        title: 'Edit product',
        maximized: true,
        content: (
          <ProductForm
            product={product}
            onSubmitSuccess={() => setVisible(false)}
            onCancel={() => setVisible(false)}
          />
        ),
      })}
    />
  );
}

EditProductModal.propTypes = {
  product: PropTypes.object,
};
