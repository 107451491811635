import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Block, Breadcrumbs, Loader, Page } from '@borrow-ui/ui';

import { TreatmentsList } from 'apps/treatments/components/treatment/TreatmentList';
import { TREATMENTS_TREATMENTS_CONSOLIDATION_BREADCRUMBS } from 'apps/treatments/constants';
import { treatmentsEntity } from 'apps/treatments/models/treatment';

export function TreatmentsConsolidationHomePage() {
  const [loaded, setLoaded] = useState(false);
  const [treatmentsIdsList, setTreatmentsIdsList] = useState();
  const { activeTenant } = useSelector((s) => s.portal);
  const { treatments } = useSelector((s) => s.treatments);

  useEffect(() => {
    treatmentsEntity
      .fetch({ qs: { tenant_id: activeTenant.id, in_collection: 1, include_archived: true } })
      .then((loadedTreatments) => {
        setTreatmentsIdsList(loadedTreatments.map((t) => t.id));
        setLoaded(true);
      });
  }, [activeTenant]);

  if (!loaded)
    return (
      <Block contentCentered={true} className="h-100pc">
        <Loader />
      </Block>
    );

  const treatmentsList = treatmentsIdsList.map((tId) => treatments[tId]);

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={TREATMENTS_TREATMENTS_CONSOLIDATION_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Treatments Consolidation</h2>
        </>
      }
    >
      <TreatmentsList
        treatmentsList={treatmentsList}
        treatments={treatments}
        showStatusFilter={false}
        showTypeFilter={false}
        showWarningFilter={true}
        showEdit={true}
      />
    </Page>
  );
}
