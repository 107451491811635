import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Forms, Loader, Button, Row, Col } from '@borrow-ui/ui';

import { FormsErrors } from 'components/common/FormErrors';
import {
  ecommerceSubsectionsEntity,
  newEcommerceSubsection,
} from 'apps/treatments/models/ecommerceSubsection';

const { Field, Input, Checkbox, Select } = Forms;

const collapseByAttributeOptions = [
  { label: 'None', value: '' },
  { label: 'Sessions', value: 'sessions' },
  { label: 'Cycles', value: 'cycles' },
];

export function EcommerceSubsectionForm({
  ecommerceSubsection,
  ecommerceSection,
  order,
  onSubmitSuccess,
  onCancel,
}) {
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [data, setData] = useState(ecommerceSubsection || newEcommerceSubsection());

  const onSubmit = (e) => {
    e.preventDefault();
    const message = { errors: {} };
    setSubmitMessage(null);

    if (!data.title) message.errors.title = 'Title is required';

    if (Object.values(message.errors).length > 0) {
      setSubmitMessage(message);
      return;
    }

    const newSubsection = {
      ...data,
      order: ecommerceSubsection ? ecommerceSubsection.order : order,
      section: ecommerceSubsection ? ecommerceSubsection.section : ecommerceSection.id,
      tenant: ecommerceSubsection ? ecommerceSubsection.tenant : ecommerceSection.tenant,
    };

    setLoading(true);
    ecommerceSubsectionsEntity
      .save(newSubsection)
      .then((newS) => {
        setLoading(false);
        onSubmitSuccess && onSubmitSuccess(newS);
      })
      .catch(() => setLoading(false));
  };

  const { errors } = submitMessage || { errors: {} };

  return (
    <form onSubmit={onSubmit}>
      <FormsErrors submitMessage={submitMessage} />
      <Field label="Title">
        <Input
          invalid={!!errors.title}
          disabled={loading}
          onChange={(e) => setData({ ...data, title: e.target.value })}
          value={data.title}
        />
      </Field>
      <Field>
        <Checkbox
          disabled={loading}
          onClick={() => setData({ ...data, hide_title: !data.hide_title })}
          checked={data.hide_title}
          label="Hide Title"
        />
      </Field>
      <Row>
        <Col>
          <Field label="Collapse">
            <Checkbox
              disabled={loading}
              onClick={() => setData({ ...data, collapse: !data.collapse })}
              checked={data.collapse}
            />
          </Field>
        </Col>
        <Col>
          <Field label="Collapse by Attribute">
            <div className="w-200">
              <Select
                disabled={loading}
                options={collapseByAttributeOptions}
                onChange={(entry) => setData({ ...data, collapse_by_attribute: entry.value })}
                value={data.collapse_by_attribute}
              />
            </div>
          </Field>
        </Col>
      </Row>
      <Field>
        <Checkbox
          disabled={loading}
          onClick={() => setData({ ...data, read_only: !data.read_only })}
          checked={data.read_only}
          label={
            <div>
              Read Only{' '}
              <span style={{ color: '#b1b1b1', marginLeft: 10 }}>Hides the CTA buttons</span>
            </div>
          }
        />
      </Field>
      <div className="flex-end-center">
        {loading && (
          <span className="m-r-10">
            <Loader type="inline" />
          </span>
        )}
        <Button type="submit" mean="positive" disabled={loading}>
          Save
        </Button>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </div>
    </form>
  );
}

EcommerceSubsectionForm.propTypes = {
  ecommerceSubsection: PropTypes.object,
  ecommerceSection: PropTypes.object,
  order: PropTypes.number,
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};
