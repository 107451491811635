import { PORTAL_BREADCRUMBS } from 'apps/portal/constants';
import { BACKEND_URL } from 'config';

export const TREATMENTS_ICON_NAME = 'format_list_bulleted';
export const TREATMENT_GROUPS_ICON_NAME = 'device_hub';

export const TREATMENTS_BASE_URL = `/treatments`;

export const TREATMENTS_BREADCRUMBS = [
  ...PORTAL_BREADCRUMBS,
  { link: TREATMENTS_BASE_URL, label: 'Treatments' },
];

export const TREATMENTS_TREATMENT_GROUPS_BASE_URL = `${TREATMENTS_BASE_URL}/treatment-groups`;

export const TREATMENTS_TREATMENT_GROUPS_BREADCRUMBS = [
  ...TREATMENTS_BREADCRUMBS,
  { link: TREATMENTS_TREATMENT_GROUPS_BASE_URL, label: 'Treatment Groups' },
];

export const TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL = `${TREATMENTS_BASE_URL}/treatments-consolidation`;

export const TREATMENTS_TREATMENTS_CONSOLIDATION_BREADCRUMBS = [
  ...TREATMENTS_BREADCRUMBS,
  { link: TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL, label: 'Treatments Consolidation' },
];

export const TREATMENTS_TREATMENTS_BASE_URL = `${TREATMENTS_BASE_URL}/treatments`;

export const TREATMENTS_TREATMENTS_BREADCRUMBS = [
  ...TREATMENTS_BREADCRUMBS,
  { link: TREATMENTS_TREATMENTS_BASE_URL, label: 'Treatments' },
];

export const TREATMENTS_EXPORT_BASE_URL = `/treatments/export`;

export const BACKEND_EXPORT_TREATMENTS_URL = `${BACKEND_URL}api/treatments/export/treatments`;
export const BACKEND_EXPORT_COLLECTIONS_URL = `${BACKEND_URL}api/treatments/export/collections`;
export const BACKEND_EXPORT_TREATMENTS_LINKS_URL = `${BACKEND_URL}api/treatments/export/treatments-links`;

export const TREATMENTS_INGEST_BASE_URL = `${TREATMENTS_BASE_URL}/ingest`;

export const crmStatusesOptions = [
  { value: 'LIVE', label: 'Live' },
  { value: 'ARCHIVED', label: 'Archived' },
];

export const treatmentTypeOptions = [
  { value: 'SERVICE', label: 'SERVICE' },
  { value: 'COURSE', label: 'COURSE' },
];
