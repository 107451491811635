import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Col, Row, Forms, Icon } from '@borrow-ui/ui';

import './product-detail.scss';
import { Image } from 'components/common/Image';
import { useTaxRate } from 'apps/portal/hooks/useTaxRate';

const { VField } = Forms;

export function ProductDetail({ product }) {
  const { productBrands, productRanges, skinTypes, skinConcerns, productTypes } = useSelector(
    (s) => s.products
  );
  const taxRate = useTaxRate({ id: product?.tax_rate });
  return (
    <div className="product-detail">
      <Row>
        <Col>
          <VField label="Title">{product.title}</VField>
        </Col>
        <Col>
          <VField label="CRM Title">{product.crm_title}</VField>
          <VField label="CRM ID">{product.crm_id}</VField>
        </Col>
      </Row>
      <Row>
        <Col>
          <VField label="Brand">{productBrands[product.brand].name}</VField>
          <VField label="Status">{product.status}</VField>
          <Row>
            <Col>
              <Row>
                <Col>
                  <VField label="Price">{product.price}</VField>
                </Col>
                <Col>
                  <VField label="Compare Price">{product.compare_price}</VField>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <VField label="Tax Rates">
                    {taxRate ? (
                      <>
                        {taxRate.tax_rate} {taxRate.name ? `(${taxRate.name})` : null}
                      </>
                    ) : (
                      'Tax Rate not set'
                    )}
                  </VField>
                </Col>
                <Col>
                  <VField label="Price with Tax">{product.price_with_tax}</VField>
                </Col>
              </Row>
            </Col>
            <Col>
              <VField label="Price without Tax">{product.price_without_tax}</VField>
            </Col>
          </Row>
          <Row>
            <Col>
              {product.out_of_stock ? (
                <div className="color-negative">Out of Stock</div>
              ) : (
                <div className="color-positive">Product in Stock</div>
              )}
            </Col>
          </Row>
        </Col>

        <Col>
          <VField label="SKU">{product.sku || <Warning />}</VField>
          <VField label="Barcode">{product.barcode}</VField>
          <VField label="Measurement (unit)">
            {product.measurement} {product.measurement_unit}
          </VField>
        </Col>
      </Row>
      <Row>
        <Col>
          <VField label="Slug">{product.slug ? product.slug : <Warning />}</VField>
          <VField label="NUA Slug">{product.nua_slug ? product.nua_slug : <Warning />}</VField>
        </Col>
        <Col>
          <Row>
            <Col colClassName="col-xs-12 col-sm-12 col-md-6">
              <VField label="Image - Desktop">
                <Image src={product.image_original} />
              </VField>
            </Col>
            <Col colClassName="col-xs-12 col-sm-12 col-md-6">
              <VField label="Image - Mobile">
                <Image src={product.image_thumbnail} />
              </VField>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <VField label="Product Types">
            <div>
              <ul>
                {product.product_types.map((s) => {
                  const so = productTypes[s];
                  if (!so) return null;
                  return (
                    <li key={so.id} className="product-detail__list-item">
                      {so.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </VField>
        </Col>
        <Col>
          <VField label="Product Ranges">
            <div>
              <ul>
                {product.product_ranges.map((s) => {
                  const so = productRanges[s];
                  if (!so) return null;
                  return (
                    <li key={so.id} className="product-detail__list-item">
                      {so.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </VField>
        </Col>
      </Row>
      <Row>
        <Col>
          <VField label="Skin Concerns">
            <div>
              <ul>
                {product.skin_concerns.map((s) => {
                  const so = skinConcerns[s];
                  if (!so) return null;
                  return (
                    <li key={so.id} className="product-detail__list-item">
                      {so.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </VField>
        </Col>
        <Col>
          <VField label="Skin Types">
            <div>
              <ul>
                {product.skin_types.map((s) => {
                  const so = skinTypes[s];
                  if (!so) return null;
                  return (
                    <li key={so.id} className="product-detail__list-item">
                      {so.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </VField>
        </Col>
      </Row>
      <Row>
        <Col>
          <VField label="Description">
            <div dangerouslySetInnerHTML={{ __html: product.description }} />
            {/* <MultiLineText text={product.description} /> */}
          </VField>
        </Col>
      </Row>
    </div>
  );
}

ProductDetail.propTypes = {
  product: PropTypes.any,
};

function Warning() {
  return <Icon name="warning" className="color-warning" />;
}
