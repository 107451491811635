import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { USERS_BASE_URL } from 'apps/portal/constants';

import { UsersHomePage } from './UsersHomePage';
import { UserDetailPage } from './UserDetailPage';

export { USERS_BASE_URL };

export function UsersRoutes() {
  return (
    <Switch>
      <Route exact path={`${USERS_BASE_URL}`} component={UsersHomePage} />
      <Route exact path={`${USERS_BASE_URL}/:id`} component={UserDetailPage} />
    </Switch>
  );
}
