import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Forms, Loader, Button, Row, Col, Block } from '@borrow-ui/ui';

import { FormsErrors } from 'components/common/FormErrors';
import { beautify } from 'utils/strings';
import { createSelectOptionsFromStore } from 'utils/select';
import { productsEntity } from 'apps/products/models/product';
import { statusesOptions } from 'apps/products/constants';
import { useTaxRate } from 'apps/portal/hooks/useTaxRate';

const { Field, Input, Select, Checkbox, Textarea } = Forms;

const requiredFields = ['crm_id', 'title', 'crm_title', 'price'];

export function ProductForm({ product, onSubmitSuccess, onCancel }) {
  const { activeTenant } = useSelector((s) => s.portal);
  const { user } = useSelector((s) => s.authentication);
  const { productRanges, productTypes, productBrands, skinTypes, skinConcerns } = useSelector(
    (s) => s.products
  );
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [data, setData] = useState(product); // no add for now || newProduct());
  const filterByTenant = (list) => list.filter((v) => v._entry.tenant === activeTenant.id);

  const productBrandsOptions = filterByTenant(createSelectOptionsFromStore(productBrands));
  const productTypesOptions = filterByTenant(createSelectOptionsFromStore(productTypes));
  const productRangesOptions = filterByTenant(createSelectOptionsFromStore(productRanges));
  const skinConcernsOptions = filterByTenant(createSelectOptionsFromStore(skinConcerns));
  const skinTypesOptions = filterByTenant(createSelectOptionsFromStore(skinTypes));
  const taxRate = useTaxRate({ id: product?.tax_rate });

  const canEditBase =
    user.groups.includes('products_managers') || user.groups.includes('products_base_editor');
  const canEditAll = user.groups.includes('products_managers');
  const canEditOutOfStock =
    user.groups.includes('products_out_of_stock') || canEditBase || canEditAll;

  const onSubmit = (e) => {
    e.preventDefault();
    const message = { errors: {} };
    setSubmitMessage(null);

    for (let requiredField of requiredFields) {
      if (!data[requiredField])
        message.errors[requiredField] = `${beautify(requiredField)} is required`;
    }

    const price = parseFloat(data.price);
    if (isNaN(price)) message.errors.price = `Price should be a number`;
    const compare_price = data.compare_price ? parseFloat(data.compare_price) : null;
    if (compare_price !== null && isNaN(compare_price))
      message.errors.compare_price = `Compare Price should be a number`;
    const measurement = data.measurement ? parseFloat(data.measurement) : null;
    if (isNaN(measurement)) message.errors.measurement = `Measurement should be a number`;

    if (Object.values(message.errors).length > 0) {
      setSubmitMessage(message);
      return;
    }

    const toSave = {
      ...data,
      price: price.toFixed(2),
      compare_price: compare_price ? compare_price.toFixed(2) : null,
      tenant: product ? product.tenant : activeTenant.id,
    };

    setLoading(true);
    productsEntity
      .save(toSave)
      .then((saved) => {
        setLoading(false);
        onSubmitSuccess && onSubmitSuccess(saved);
      })
      .catch(() => setLoading(false));
  };

  const { errors } = submitMessage || { errors: {} };

  return (
    <form onSubmit={onSubmit}>
      <FormsErrors submitMessage={submitMessage} />
      <Block outstanding={true}>
        <Row>
          <Col>
            <Field label="Title">
              <Input
                invalid={!!errors.title}
                disabled={loading || !canEditBase}
                onChange={(e) => setData({ ...data, title: e.target.value })}
                value={data.title}
              />
            </Field>
          </Col>
          <Col>
            <Field label="CRM Title">
              <Input
                invalid={!!errors.crm_title}
                disabled={loading || !canEditAll}
                onChange={(e) => setData({ ...data, crm_title: e.target.value })}
                value={data.crm_title}
              />
            </Field>
            <Field label="CRM ID">{data.crm_id}</Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field label="Brand">
              <div className="w-200">
                <Select
                  disabled={loading || !canEditAll}
                  options={productBrandsOptions}
                  onChange={(entry) => setData({ ...data, brand: entry.value })}
                  value={data.brand}
                />
              </div>
            </Field>
            <Field label="Status">
              <div className="w-200">
                <Select
                  disabled={loading || !canEditAll}
                  options={statusesOptions}
                  onChange={(entry) => setData({ ...data, status: entry.value })}
                  value={data.status}
                />
              </div>
            </Field>
            <Row>
              <Col>
                <Field label="Price">
                  <Input
                    className="w-100"
                    invalid={!!errors.price}
                    disabled={loading || !canEditBase}
                    onChange={(e) => setData({ ...data, price: e.target.value })}
                    value={data.price}
                  />
                </Field>
              </Col>
              <Col>
                <Field label="Compare Price">
                  <Input
                    className="w-100"
                    invalid={!!errors.compare_price}
                    disabled={loading || !canEditBase}
                    onChange={(e) => setData({ ...data, compare_price: e.target.value })}
                    value={data.compare_price || ''}
                  />
                </Field>
              </Col>
              <Col>
                <Field label="Tax rate">
                  <Input className="w-100" value={taxRate.tax_rate || ''} disabled />
                </Field>
              </Col>
            </Row>
          </Col>
          <Col>
            <Field label="SKU">
              <Input
                className="w-100"
                invalid={!!errors.sku}
                disabled={loading || !canEditAll}
                onChange={(e) => setData({ ...data, sku: e.target.value })}
                value={data.sku}
              />
            </Field>
            <Field label="Barcode">
              <Input
                className="w-100"
                invalid={!!errors.barcode}
                disabled={loading || !canEditAll}
                onChange={(e) => setData({ ...data, barcode: e.target.value })}
                value={data.barcode}
              />
            </Field>
            <Row>
              <Col>
                <Field label="Measurement">
                  <Input
                    className="w-100"
                    invalid={!!errors.measurement}
                    disabled={loading || !canEditBase}
                    onChange={(e) => setData({ ...data, measurement: e.target.value })}
                    value={data.measurement}
                  />
                </Field>
              </Col>
              <Col>
                <Field label="Unit">
                  <Input
                    className="w-100"
                    invalid={!!errors.measurement_unit}
                    disabled={loading || !canEditBase}
                    onChange={(e) => setData({ ...data, measurement_unit: e.target.value })}
                    value={data.measurement_unit}
                  />
                </Field>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field label="Out Of Stock">
              <Checkbox
                checked={data.out_of_stock || false}
                disabled={loading || !canEditOutOfStock}
                onClick={() => setData({ ...data, out_of_stock: !data.out_of_stock })}
              />
            </Field>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <Field label="Slug">
              <Input
                invalid={!!errors.slug}
                disabled={loading || !canEditBase}
                onChange={(e) => setData({ ...data, slug: e.target.value })}
                value={data.slug}
              />
            </Field>
            <Field label="NUA Slug">
              <Input
                invalid={!!errors.nua_slug}
                disabled={loading || !canEditBase}
                onChange={(e) => setData({ ...data, nua_slug: e.target.value })}
                value={data.nua_slug}
              />
            </Field>
          </Col>
          <Col>
            <Field label="Image - Desktop">
              <Input
                invalid={!!errors.image_original}
                disabled={loading || !canEditAll}
                onChange={(e) => setData({ ...data, image_original: e.target.value })}
                value={data.image_original || ''}
                placeholder="Image name or full URL, i.e. LHR-Underarms-desktop.png or https://..."
              />
            </Field>
            <Field label="Image - Mobile">
              <Input
                invalid={!!errors.image_thumbnail}
                disabled={loading || !canEditAll}
                onChange={(e) => setData({ ...data, image_thumbnail: e.target.value })}
                value={data.image_thumbnail || ''}
                placeholder="Image name or full URL, i.e. LHR-Underarms-mobile.png or https://..."
              />
            </Field>
          </Col>
        </Row>

        <Row>
          <Col>
            <Field label="Product Types">
              <Select
                isMulti={true}
                disabled={loading || !canEditBase}
                options={productTypesOptions}
                value={data.product_types}
                onChange={(values) =>
                  setData({
                    ...data,
                    product_types: values ? values.map((a) => a.value) : [],
                  })
                }
              />
            </Field>
          </Col>
          <Col>
            <Field label="Product Ranges">
              <Select
                isMulti={true}
                disabled={loading || !canEditBase}
                options={productRangesOptions}
                value={data.product_ranges}
                onChange={(values) =>
                  setData({
                    ...data,
                    product_ranges: values ? values.map((a) => a.value) : [],
                  })
                }
              />
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field label="Skin Concerns">
              <Select
                isMulti={true}
                disabled={loading || !canEditBase}
                options={skinConcernsOptions}
                value={data.skin_concerns}
                onChange={(values) =>
                  setData({
                    ...data,
                    skin_concerns: values ? values.map((a) => a.value) : [],
                  })
                }
              />
            </Field>
          </Col>
          <Col>
            <Field label="Skin Types">
              <Select
                isMulti={true}
                disabled={loading || !canEditBase}
                options={skinTypesOptions}
                value={data.skin_types}
                onChange={(values) =>
                  setData({
                    ...data,
                    skin_types: values ? values.map((a) => a.value) : [],
                  })
                }
              />
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field label="Description">
              {loading || !canEditBase ? (
                <Textarea disabled={true} value={data.description || ''} />
              ) : (
                <ReactQuill
                  theme="snow"
                  value={data.description || ''}
                  onChange={(v) => setData({ ...data, description: v })}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Block>
      <div className="flex-end-center">
        {loading && (
          <span className="m-r-10">
            <Loader type="inline" />
          </span>
        )}
        <Button type="submit" mean="positive" disabled={loading}>
          Save
        </Button>
        <Button onClick={onCancel} disabled={loading}>
          Close
        </Button>
      </div>
    </form>
  );
}

ProductForm.propTypes = {
  product: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};
