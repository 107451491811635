import { BACKEND_URL } from 'config';
import { PORTAL_BREADCRUMBS } from 'apps/portal/constants';

export const PRODUCTS_ICON_NAME = 'format_list_bulleted';
export const PRODUCT_FILTERS_ICON_NAME = 'filter_alt';

/** Constants */

export const productStatuses = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
};

export const statusesOptions = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'PUBLISHED', label: 'Published' },
  { value: 'ARCHIVED', label: 'Archived' },
];

/** URLS */

export const PRODUCTS_BASE_URL = `/products`;

export const PRODUCTS_BREADCRUMBS = [
  ...PORTAL_BREADCRUMBS,
  { link: PRODUCTS_BASE_URL, label: 'Products' },
];

export const PRODUCTS_PRODUCTS_BASE_URL = `${PRODUCTS_BASE_URL}/products`;

export const PRODUCTS_PRODUCTS_BREADCRUMBS = [
  ...PRODUCTS_BREADCRUMBS,
  { link: PRODUCTS_PRODUCTS_BASE_URL, label: 'Products' },
];

export const PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL = `${PRODUCTS_BASE_URL}/products-consolidation`;

export const PRODUCTS_PRODUCTS_CONSOLIDATION_BREADCRUMBS = [
  ...PRODUCTS_BREADCRUMBS,
  { link: PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL, label: 'Products Consolidation' },
];

export const PRODUCTS_PRODUCT_FILTERS_BASE_URL = `${PRODUCTS_BASE_URL}/filters`;

export const PRODUCTS_PRODUCT_FILTERS_BREADCRUMBS = [
  ...PRODUCTS_BREADCRUMBS,
  { link: PRODUCTS_PRODUCT_FILTERS_BASE_URL, label: 'Filters' },
];

export const PRODUCTS_EXPORT_BASE_URL = `${PRODUCTS_BASE_URL}/export`;

export const BACKEND_EXPORT_PRODUCTS_URL = `${BACKEND_URL}api/products/export/products`;
export const BACKEND_EXPORT_PRODUCTS_LINKS_URL = `${BACKEND_URL}api/products/export/products-links`;

export const PRODUCTS_INGESTION_URL = `${PRODUCTS_BASE_URL}/ingest`;
