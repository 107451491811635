import BaseEntity from 'models/common/base';

import { DELETE_SKIN_CONCERN, SET_SKIN_CONCERN, SET_SKIN_CONCERNS } from '../actions';
export const BASE_URL = 'api/products/skin-concerns/';

export class SkinConcernEntity extends BaseEntity {
  constructor(properties) {
    super('ProductsSkinConcern', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_SKIN_CONCERNS,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_SKIN_CONCERN });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_SKIN_CONCERN });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_SKIN_CONCERN);
  }
}

export const skinConcernsEntity = new SkinConcernEntity();
