import { store } from 'store/store';
import { SET_APPLICATION_LOADING } from 'store/actions';

import { usersEntity } from './models/user';

import { INITIALIZE } from './actions';
import { tenantsEntity } from './models/tenant';
import { environmentsEntity } from './models/environment';

export function preload() {
  return new Promise((resolve) => {
    store.dispatch({ type: SET_APPLICATION_LOADING, loading: true });
    const preloadPromises = [
      tenantsEntity.fetch(),
      usersEntity.fetch(),
      environmentsEntity.fetch(),
    ];

    Promise.all(preloadPromises).then(() => {
      store.dispatch({ type: SET_APPLICATION_LOADING, loading: false });
      store.dispatch({ type: INITIALIZE });
      resolve();
    });
  });
}
