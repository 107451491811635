import { postRequest } from 'libs/requests/requests';
import BaseEntity from 'models/common/base';

import {
  SET_ECOMMERCE_SECTIONS,
  SET_ECOMMERCE_SECTION,
  DELETE_ECOMMERCE_SECTION,
} from '../actions';
export const BASE_URL = 'api/treatments/ecommerce-sections/';

export function newEcommerceSection() {
  return {
    id: null,
    tenant: null,
    category: null,
    title: '',
    order: 0,
  };
}

export class EcommerceSectionEntity extends BaseEntity {
  constructor(properties) {
    super('TreatmentsEcommerceSection', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  static emptyEntry() {
    return newEcommerceSection();
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_ECOMMERCE_SECTIONS,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_ECOMMERCE_SECTION });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_ECOMMERCE_SECTION });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_ECOMMERCE_SECTION);
  }

  reorderSubsections(id, newOrder) {
    return postRequest(`${BASE_URL}${id}/reorder-subsections/`, { new_order: newOrder }).then(
      (updatedSection) => {
        return this.localUpdate(updatedSection);
      }
    );
  }
}

export const ecommerceSectionsEntity = new EcommerceSectionEntity();
