import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Page, Breadcrumbs, Block, Loader } from '@borrow-ui/ui';

import { TREATMENTS_TREATMENT_GROUPS_BREADCRUMBS } from 'apps/treatments/constants';
import { ecommerceCategoriesEntity } from 'apps/treatments/models/ecommerceCategory';
import { ecommerceSectionsEntity } from 'apps/treatments/models/ecommerceSection';
import { ecommerceSubsectionsEntity } from 'apps/treatments/models/ecommerceSubsection';
import { EcommerceCategoryPreview } from 'apps/treatments/components/ecommerceCategoryPreview/EcommerceCategoryPreview';
import { treatmentsEntity } from 'apps/treatments/models/treatment';

export function EcommerceCategoryPreviewPage() {
  const [data, setData] = useState(null);

  const { treatmentGroups } = useSelector((s) => s.treatments);

  const { id: treatmenGroupId, categoryId } = useParams();

  const treatmentGroup = treatmentGroups[+treatmenGroupId];

  const reload = useCallback(() => {
    setData(null);
    const promises = [
      ecommerceCategoriesEntity.fetch({ qs: { treatment_group: treatmenGroupId } }),
      ecommerceSectionsEntity.fetch({ qs: { treatment_group: treatmenGroupId } }),
      ecommerceSubsectionsEntity.fetch({ qs: { treatment_group: treatmenGroupId } }),
      treatmentsEntity.fetch({ qs: { tenant: treatmentGroup.tenant } }),
    ];
    Promise.all(promises).then((responses) => {
      const categories = responses[0];
      const sections = responses[1];
      const subsections = responses[2];

      const newData = {
        ecommerceCategoriesIds: categories.map((c) => c.id),
        ecommerceSectionsIds: sections.map((s) => s.id),
        ecommerceSubsectionsIds: subsections.map((s) => s.id),
        ecommerceCategory: categories.find((c) => c.id === +categoryId),
      };

      setData(newData);
    });
  }, [treatmenGroupId, categoryId, treatmentGroup]);

  useEffect(() => {
    reload();
  }, [reload]);

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={TREATMENTS_TREATMENT_GROUPS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">{treatmentGroup.name} Preview</h2>
        </>
      }
    >
      <Block outstanding={true}>
        {data ? (
          <EcommerceCategoryPreview ecommerceCategory={data.ecommerceCategory} data={data} />
        ) : (
          <div className="flex-center-center w-100pc h-300">
            <Loader />
          </div>
        )}
      </Block>
    </Page>
  );
}
