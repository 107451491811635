import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '@borrow-ui/ui';

import { UserLink } from './UserLink';

const usersColumns = [
  { prop: 'id', title: 'ID', width: 50 },
  { prop: 'name_link', title: 'Name' },
];

export function UsersList({ users }) {
  const entries = Object.values(users)
    .map((user) => {
      return {
        ...user,
        name_link: <UserLink user={user} />,
      };
    })
    .sort((a, b) => (a.full_name > b.full_name ? 1 : -1));

  return (
    <Table
      columns={usersColumns}
      entries={entries}
      searchBar={{ visible: true }}
      config={{ zebra: false }}
    />
  );
}

UsersList.propTypes = {
  users: PropTypes.object.isRequired,
};
