import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PRODUCTS_PRODUCTS_BASE_URL } from 'apps/products/constants';
import { ProductsHomePage } from './ProductsHomePage';
import { ProductDetailPage } from './ProductDetailPage';
import { EditProductFormPage } from './EditProductFormPage';

export { PRODUCTS_PRODUCTS_BASE_URL };

export function ProductsRoutes() {
  return (
    <Switch>
      <Route exact path={`${PRODUCTS_PRODUCTS_BASE_URL}`} component={ProductsHomePage} />
      <Route
        exact
        path={`${PRODUCTS_PRODUCTS_BASE_URL}/:id/edit`}
        component={EditProductFormPage}
      />
      <Route exact path={`${PRODUCTS_PRODUCTS_BASE_URL}/:id`} component={ProductDetailPage} />
    </Switch>
  );
}
