import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@borrow-ui/ui';

export function YesNo({ value }) {
  if (value) return <Icon name="check_circle" className="color-positive" size="small" />;
  return <Icon name="close" className="color-negative" size="small" />;
}

YesNo.propTypes = {
  value: PropTypes.bool,
};
