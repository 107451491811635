import { store } from 'store/store';

export const userCanPublishDdb = (environment) => {
  const { user } = store.getState().authentication;
  const PRODUCTION_GROUP = 'publish_to_ddb';
  const STAGING_GROUP = 'publish_to_ddb_staging';

  if (!user) return;

  if (!environment) {
    return [PRODUCTION_GROUP, STAGING_GROUP].some((g) => user.groups.includes(g));
  }

  return user.groups.includes(environment === 'production' ? PRODUCTION_GROUP : STAGING_GROUP);
};

export const userCanLookupUser = () => {
  const { user } = store.getState().authentication;
  const GROUP = 'user_lookup';
  return user?.groups.includes(GROUP);
};

export const userCanManageProducts = () => {
  const { user } = store.getState().authentication;
  const GROUP = 'products_managers';
  return user?.groups.includes(GROUP);
};

export const userCanManageTreatments = () => {
  const { user } = store.getState().authentication;
  const GROUP = 'treatments_managers';
  return user?.groups.includes(GROUP);
};
