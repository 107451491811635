import BaseEntity from 'models/common/base';

import { SET_TREATMENT_GROUP, SET_TREATMENT_GROUPS, DELETE_TREATMENT_GROUP } from '../actions';
export const BASE_URL = 'api/treatments/treatment-groups/';

export function newTreatmentGroup() {
  return {
    id: null,
    tenant: null,
    name: '',
    codename: '',
  };
}

export class TreatmentGroupEntity extends BaseEntity {
  constructor(properties) {
    super('TreatmentsTreatmentGroup', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  static emptyEntry() {
    return newTreatmentGroup();
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_TREATMENT_GROUPS,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_TREATMENT_GROUP });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_TREATMENT_GROUP });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_TREATMENT_GROUP);
  }
}

export const treatmentGroupsEntity = new TreatmentGroupEntity();
