import { store } from 'store/store';
import { SET_APPLICATION_LOADING } from 'store/actions';

import { treatmentGroupsEntity } from './models/treatmentGroup';

import { INITIALIZE, INITIALIZE_TENANT } from './actions';

export function preload(activeTenant) {
  return new Promise((resolve) => {
    store.dispatch({ type: SET_APPLICATION_LOADING, loading: true });
    const preloadPromises = [treatmentGroupsEntity.fetch()];

    Promise.all(preloadPromises).then(() => {
      store.dispatch({ type: SET_APPLICATION_LOADING, loading: false });
      store.dispatch({ type: INITIALIZE });
      store.dispatch({ type: INITIALIZE_TENANT, tenantId: activeTenant.id });
      resolve();
    });
  });
}
