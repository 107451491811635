import React from 'react';

import { Button, Modal } from '@borrow-ui/ui';

import { TreatmentForm } from './TreatmentForm';
import { TreatmentPropType } from './TreatmentPropTypes';

export function EditTreatmentModal({ treatment }) {
  return (
    <Modal
      Trigger={({ setVisible }) => {
        return (
          <div className="flex-end-center">
            <Button mean="primary" onClick={() => setVisible(true)}>
              Edit
            </Button>
          </div>
        );
      }}
      getModalWindowProps={({ setVisible }) => ({
        title: 'Edit treatment',
        maximized: true,
        content: (
          <TreatmentForm
            treatment={treatment}
            onSubmitSuccess={() => setVisible(false)}
            onCancel={() => setVisible(false)}
          />
        ),
      })}
    />
  );
}

EditTreatmentModal.propTypes = {
  treatment: TreatmentPropType,
};
