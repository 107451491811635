import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Table, Link } from '@borrow-ui/ui';

import { TREATMENTS_TREATMENT_GROUPS_BASE_URL } from 'apps/treatments/constants';

const treatmentGroupsColumns = [
  { prop: 'id', title: 'ID', width: 50 },
  { prop: 'tenant', title: 'Tenant', width: 50 },
  { prop: 'name_link', title: 'Name' },
  { prop: 'codename', title: 'Codename' },
];

export function TreatmentGroupsList({ treatmentGroups, tenants }) {
  const { activeTenant } = useSelector((s) => s.portal);

  const entries = Object.values(treatmentGroups)
    .filter((tg) => tg.tenant === activeTenant.id)
    .map((treatmentGroup) => {
      return {
        ...treatmentGroup,
        tenant: tenants[treatmentGroup.tenant].name,
        name_link: (
          <Link to={`${TREATMENTS_TREATMENT_GROUPS_BASE_URL}/${treatmentGroup.id}`}>
            {treatmentGroup.name}
          </Link>
        ),
      };
    });

  return (
    <Table
      columns={treatmentGroupsColumns}
      entries={entries}
      searchBar={{ visible: true }}
      config={{ zebra: false }}
    />
  );
}

TreatmentGroupsList.propTypes = {
  treatmentGroups: PropTypes.object.isRequired,
  tenants: PropTypes.object.isRequired,
};
