import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  TREATMENTS_TREATMENTS_BASE_URL,
  TREATMENTS_TREATMENT_GROUPS_BASE_URL,
} from 'apps/treatments/constants';
import { TreatmentsHomePage } from './TreatmentsHomePage';
import { TreatmentDetailPage } from './TreatmentDetailPage';
import { EditTreatmentFormPage } from './EditTreatmentFormPage';

export { TREATMENTS_TREATMENT_GROUPS_BASE_URL };

export function TreatmentsRoutes() {
  return (
    <Switch>
      <Route exact path={`${TREATMENTS_TREATMENTS_BASE_URL}`} component={TreatmentsHomePage} />
      <Route
        exact
        path={`${TREATMENTS_TREATMENTS_BASE_URL}/:id/edit`}
        component={EditTreatmentFormPage}
      />
      <Route exact path={`${TREATMENTS_TREATMENTS_BASE_URL}/:id`} component={TreatmentDetailPage} />
    </Switch>
  );
}
