import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Page, Breadcrumbs, Loader, Block, TileLink } from '@borrow-ui/ui';

import { postRequest } from 'libs/requests/requests';
import { IMPORT_DATA_ENDPOINT, PORTAL_IMPORT_DATA_BREADCRUMBS } from 'apps/portal/constants';
import { TREATMENTS_ICON_NAME, TREATMENT_GROUPS_ICON_NAME } from 'apps/treatments/constants';

export function ImportDataPage() {
  const { user } = useSelector((s) => s.authentication);
  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PORTAL_IMPORT_DATA_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Import Data</h2>
        </>
      }
    >
      {user.groups.includes('site_admin') && <ImportDataActions />}
    </Page>
  );
}

function ImportDataActions() {
  const [loading, setLoading] = useState(false);

  const { activeTenant } = useSelector((s) => s.portal);

  const importData = (action) => {
    const url = `${IMPORT_DATA_ENDPOINT}/${action}/${activeTenant.codename}/`;
    setLoading(true);

    postRequest(url, undefined, {})
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  return (
    <Block outstanding={true}>
      <div>
        <TileLink
          onClick={() => !loading && importData('treatments')}
          size="small"
          name="Treatments Data"
          iconProps={{ style: { color: loading ? '#cecece' : undefined } }}
          icon={TREATMENTS_ICON_NAME}
          getPath={() => '#'}
          description="Import Treatments"
        />
        <TileLink
          onClick={() => !loading && importData('ecommerce')}
          size="small"
          name="Ecommerce Data"
          iconProps={{ style: { color: loading ? '#cecece' : undefined } }}
          icon={TREATMENT_GROUPS_ICON_NAME}
          getPath={() => '#'}
          description="Import Ecommerce collections"
        />
      </div>
      {loading && (
        <div className="w-100pc h-300 flex-center-center">
          <Loader />
        </div>
      )}
    </Block>
  );
}
