import React from 'react';
import PropTypes from 'prop-types';

import './divider.scss';

export function Divider({ className = '', ...rest }) {
  const dividerClassName = `common__divider ${className}`.trim();

  return <hr className={dividerClassName} {...rest} />;
}

Divider.propTypes = {
  className: PropTypes.string,
};
