import { listToObject } from 'utils/data';

import * as actions from './actions';

const INITIAL_STATE = {
  initialized: false,
  config: {},
  users: {},
  environments: {},
  tenants: {},
  activeTenant: null,
  activeEnvironment: null,
  taxRates: {},
};

const portal = (currentState, action) => {
  const state = currentState ? currentState : INITIAL_STATE;

  switch (action.type) {
    case actions.INITIALIZE:
      return { ...state, initialized: true };

    case actions.SET_USERS:
      const users = listToObject(action.data, 'id');
      return { ...state, users };
    case actions.SET_USER:
      const user = action.data;
      return { ...state, users: { ...state.users, [user.id]: user } };

    case actions.SET_TENANTS:
      const tenants = listToObject(action.data, 'id');
      return { ...state, tenants };
    case actions.SET_TENANT:
      const tenant = action.data;
      return { ...state, tenants: { ...state.tenants, [tenant.id]: tenant } };

    case actions.SET_ENVIRONMENTS:
      const environments = listToObject(action.data, 'id');
      return { ...state, environments };
    case actions.SET_ENVIRONMENT:
      const environment = action.data;
      return { ...state, environments: { ...state.environments, [environment.id]: environment } };

    case actions.SET_ACTIVE_TENANT:
      return { ...state, activeTenant: action.data };

    case actions.SET_ACTIVE_ENVIRONMENT:
      return { ...state, activeEnvironment: action.data };

    case actions.SET_TAX_RATES:
      const taxRates = listToObject(action.data, 'id');
      return { ...state, taxRates };
    case actions.SET_TAX_RATE:
      const taxRate = action.data;
      return { ...state, taxRates: { ...state.taxRates, [taxRate.id]: taxRate } };

    default:
      return state;
  }
};

export const reducers = {
  portal,
};
