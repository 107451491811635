import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Page, Breadcrumbs, Loader, Block, TileLink } from '@borrow-ui/ui';

import { postRequest } from 'libs/requests/requests';
import {
  CLEAR_DATA_ENDPOINT,
  PORTAL_CLEAR_DATA_BREADCRUMBS,
  CLEAR__PRODUCT_DATA_ENDPOINT,
} from 'apps/portal/constants';
import { TREATMENTS_ICON_NAME, TREATMENT_GROUPS_ICON_NAME } from 'apps/treatments/constants';
import { PRODUCTS_ICON_NAME } from 'apps/products/constants';

export function ClearDataPage() {
  const { user } = useSelector((s) => s.authentication);
  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PORTAL_CLEAR_DATA_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Clear Data</h2>
        </>
      }
    >
      {user.groups.includes('site_admin') && <ClearDataActions />}
    </Page>
  );
}

function ClearDataActions() {
  const [loading, setLoading] = useState(false);

  const { activeTenant } = useSelector((s) => s.portal);

  const clearData = (endpoint, action) => {
    const clear = window.confirm(
      `Are you sure you want to clear all ${activeTenant.name} ${action}?`
    );
    if (!clear) return;

    const url = `${endpoint}/${action}/${activeTenant.codename}/`;
    setLoading(true);

    postRequest(url, undefined, {})
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  return (
    <Block outstanding={true}>
      <div>
        <TileLink
          onClick={() => !loading && clearData(CLEAR__PRODUCT_DATA_ENDPOINT, 'products')}
          size="small"
          name="Products Data"
          iconProps={{ style: { color: loading ? '#cecece' : undefined } }}
          icon={PRODUCTS_ICON_NAME}
          getPath={() => '#'}
          description="Clear Products"
        />
        <TileLink
          onClick={() => !loading && clearData(CLEAR_DATA_ENDPOINT, 'treatments')}
          size="small"
          name="Treatments Data"
          iconProps={{ style: { color: loading ? '#cecece' : undefined } }}
          icon={TREATMENTS_ICON_NAME}
          getPath={() => '#'}
          description="Clear Treatments"
        />
        <TileLink
          onClick={() => !loading && clearData(CLEAR_DATA_ENDPOINT, 'ecommerce')}
          size="small"
          name="Ecommerce Data"
          iconProps={{ style: { color: loading ? '#cecece' : undefined } }}
          icon={TREATMENT_GROUPS_ICON_NAME}
          getPath={() => '#'}
          description="Clear Ecommerce collections"
        />
      </div>
      {loading && (
        <div className="w-100pc h-300 flex-center-center">
          <Loader />
        </div>
      )}
    </Block>
  );
}
