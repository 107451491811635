import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button, IconControl, Modal, Title } from '@borrow-ui/ui';

import { BuilderContext } from './context';
import { TGBEcommerceSubsectionItem } from './TGBEcommerceSubsectionItem';
import { EcommerceSectionPropType, EcommerceSubsectionPropType } from './TGBPropTypes';
import { TreatmentSelector } from './TreatmentSelector';
import { ecommerceSubsectionsEntity } from 'apps/treatments/models/ecommerceSubsection';
import { immutableMove } from './utils';
import { ecommerceSectionsEntity } from 'apps/treatments/models/ecommerceSection';
import { EcommerceSubsectionForm } from '../ecommerceSubsection/EcommerceSubsectionForm';

export function TGBEcommerceSubsection({
  ecommerceSubsection,
  ecommerceSection,
  subsections,
  reload,
}) {
  const { contextData, setContextData } = useContext(BuilderContext);
  const { isLoading } = contextData;
  const { treatments } = useSelector((s) => s.treatments);
  const { user } = useSelector((s) => s.authentication);
  const [newTreatment, setNewTreatment] = useState(null);

  const userCanEdit = user.groups.includes('treatments_managers');

  const addItem = async (setVisible) => {
    setContextData({ ...contextData, isLoading: true });
    await ecommerceSubsectionsEntity.addItem(ecommerceSubsection.id, newTreatment);
    setNewTreatment(null);
    setContextData({ ...contextData, isLoading: false });
    setVisible(false);
  };

  const removeSubsection = async (id) => {
    const proceed = window.confirm('Are you sure you want to delete this subsection?');
    if (!proceed) return;

    setContextData({ ...contextData, isLoading: true });
    await ecommerceSubsectionsEntity.delete(ecommerceSubsection.id, id);
    setContextData((cd) => ({
      ...cd,
      isLoading: false,
      data: {
        ...cd.data,
        ecommerceSubsectionsIds: cd.data.ecommerceSubsectionsIds.filter(
          (esi) => esi !== ecommerceSubsection.id
        ),
      },
    }));

    setContextData({ ...contextData });
  };

  const move = async (from, to) => {
    setContextData({ ...contextData, isLoading: true });
    const newOrder = immutableMove(
      subsections.map((ss) => ss.id),
      from,
      to
    );
    await ecommerceSectionsEntity.reorderSubsections(ecommerceSection.id, newOrder);
    setContextData({ ...contextData, isLoading: false });
    reload();
  };

  return (
    <div className="tgb__ecommerce-subsection">
      <div className="tgb__ecommerce-subsection__header">
        <Title tag="h4" className="m-0">
          {ecommerceSubsection.order}. {ecommerceSubsection.title}
        </Title>
        {userCanEdit && (
          <div className="tgb__ecommerce-subsection__controls">
            <Modal
              Trigger={({ setVisible }) => {
                return <IconControl name="edit" onClick={() => setVisible(true)} />;
              }}
              getModalWindowProps={({ setVisible }) => ({
                title: `Edit ${ecommerceSubsection.title} `,
                canMaximize: true,
                startWidth: 500,
                startHeight: 380,
                content: (
                  <EcommerceSubsectionForm
                    ecommerceSubsection={ecommerceSubsection}
                    onCancel={() => setVisible(false)}
                    onSubmitSuccess={() => {
                      reload();
                    }}
                  />
                ),
              })}
            />
            <IconControl
              name="delete"
              disabled={isLoading}
              onClick={() => removeSubsection(ecommerceSubsection.id)}
            />
            {ecommerceSubsection.order !== 1 && (
              <IconControl
                name="arrow_upward"
                onClick={() => move(ecommerceSubsection.order - 1, ecommerceSubsection.order - 2)}
                disabled={isLoading}
              />
            )}
            {ecommerceSubsection.order !== subsections.length && (
              <IconControl
                name="arrow_downward"
                disabled={isLoading}
                onClick={() => move(ecommerceSubsection.order - 1, ecommerceSubsection.order)}
              />
            )}
          </div>
        )}
      </div>
      <div className="tgb__ecommerce-subsection-items">
        {ecommerceSubsection.items.map((ecommerceSubsectionItem, i) => {
          return (
            <div key={ecommerceSubsectionItem.id}>
              <TGBEcommerceSubsectionItem
                index={i}
                isLast={i === ecommerceSubsection.items.length - 1}
                ecommerceSubsectionItem={ecommerceSubsectionItem}
                ecommerceSubsection={ecommerceSubsection}
                treatment={treatments[ecommerceSubsectionItem.treatment]}
              />
            </div>
          );
        })}
        {userCanEdit && (
          <div className="tgb__ecommerce-subsection-items__bottom-controls">
            <Modal
              Trigger={({ setVisible }) => {
                return (
                  <Button
                    size="small"
                    mean="regular-reverse"
                    disabled={isLoading}
                    onClick={() => setVisible(true)}
                  >
                    Add new course/service
                  </Button>
                );
              }}
              getModalWindowProps={({ setVisible }) => ({
                title: 'Treatment picker',
                maximized: true,
                content: (
                  <TreatmentSelector
                    selectedTreatment={newTreatment}
                    setSelectedTreatment={setNewTreatment}
                  />
                ),

                footer: (
                  <>
                    <div />

                    <div>
                      <Button onClick={() => setVisible(false)} disabled={isLoading}>
                        Close
                      </Button>
                      <Button
                        mean="positive"
                        disabled={!newTreatment || isLoading}
                        onClick={() => addItem(setVisible)}
                      >
                        Add selected treatment
                      </Button>
                    </div>
                  </>
                ),
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
}

TGBEcommerceSubsection.propTypes = {
  ecommerceSubsection: EcommerceSubsectionPropType,
  subsections: PropTypes.arrayOf(EcommerceSubsectionPropType),
  ecommerceSection: EcommerceSectionPropType,
  reload: PropTypes.func,
};
