import React, { useState } from 'react';

import { Text, Title, Forms, Button } from '@borrow-ui/ui';
import { postRequest } from 'libs/requests/requests';

import { PREFERENCES_USER_PASSWORD_CHANGE_URL } from 'apps/portal/constants';

const { Field, Input } = Forms;

function getEmptyData() {
  return {
    current_password: '',
    new_password: '',
    new_password_confirm: '',
  };
}

export function AccountPreferences() {
  const [data, setData] = useState(getEmptyData());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const validPasswordChange = validatePassword(data);

  const submitPasswordChange = () => {
    setLoading(true);
    setMessage(null);
    postRequest(PREFERENCES_USER_PASSWORD_CHANGE_URL, data)
      .then(() => {
        setLoading(false);
        setMessage({ success: true });
        setData(getEmptyData());
      })
      .catch((error) => {
        setLoading(false);
        setMessage({ success: false, error });
      });
  };

  return (
    <div style={{ maxWidth: 500 }} data-user-preferences="account">
      <Title className="m-t-0">Account</Title>
      <Text>
        In this section you can change your password. You will need to input your current password
        before continue.
      </Text>
      <Text size="small">Your password should be at least 8 characters long.</Text>
      {message && message.success && (
        <>
          <Text className="color-positive-bg color-on-positive p-10">
            Your password has been changed successfully.
          </Text>
        </>
      )}
      <form onSubmit={(e) => e.preventDefault()}>
        <Field label="Current Password" required={true}>
          <Input
            name="current_password"
            type="password"
            disabled={loading}
            value={data.current_password}
            onChange={(e) => setData({ ...data, current_password: e.target.value })}
          />
        </Field>
        <Field label="New Password" required={true}>
          <Input
            name="new_password"
            type="password"
            disabled={loading}
            value={data.new_password}
            onChange={(e) => setData({ ...data, new_password: e.target.value })}
          />
        </Field>
        <Field label="Confirm New Password" required={true}>
          <Input
            name="new_password_confirm"
            type="password"
            disabled={loading}
            value={data.new_password_confirm}
            onChange={(e) => setData({ ...data, new_password_confirm: e.target.value })}
          />
        </Field>

        <div className="flex-end-center">
          <Button mean="positive" disabled={!validPasswordChange} onClick={submitPasswordChange}>
            Change Password
          </Button>
        </div>
      </form>
    </div>
  );
}

function validatePassword(data) {
  if (!data.current_password) return false;
  if (!data.new_password || !data.new_password_confirm) return false;
  if (data.new_password !== data.new_password_confirm) return false;
  if (data.new_password.length < 8) return false;

  return true;
}
