import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Home } from 'pages/Home';
import { PortalLanding } from 'apps/portal/PortalLanding';
import { TreatmentsLanding } from 'apps/treatments/TreatmentsLanding';
import { TREATMENTS_BASE_URL } from 'apps/treatments/constants';
import { PRODUCTS_BASE_URL } from 'apps/products/constants';
import { ProductsLanding } from 'apps/products/ProductsLanding';
import { DashLanding } from 'apps/dash/DashLanding';

export function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path={PRODUCTS_BASE_URL} component={ProductsLanding} />
      <Route path={TREATMENTS_BASE_URL} component={TreatmentsLanding} />
      <Route path="/portal" component={PortalLanding} />
      <Route path="/dash" component={DashLanding} />
    </Switch>
  );
}
