import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Block, Breadcrumbs, Loader, Page } from '@borrow-ui/ui';

import { store } from 'store/store';
import { TreatmentsList } from 'apps/treatments/components/treatment/TreatmentList';
import { TREATMENTS_TREATMENTS_BREADCRUMBS } from 'apps/treatments/constants';
import { treatmentsEntity } from 'apps/treatments/models/treatment';
import { SET_LOADED_ARCHIVED } from 'apps/treatments/actions';

export function TreatmentsHomePage() {
  const [loaded, setLoaded] = useState(false);
  const { activeTenant } = useSelector((s) => s.portal);
  const { treatments, loadedArchived } = useSelector((s) => s.treatments);

  useEffect(() => {
    if (loadedArchived) {
      setLoaded(true);
      return;
    }

    treatmentsEntity.fetch({ qs: { tenant_id: activeTenant.id, include_archived: 1 } }).then(() => {
      store.dispatch({ type: SET_LOADED_ARCHIVED });
      setLoaded(true);
    });
  }, [activeTenant, loadedArchived]);

  const treatmentsList = useMemo(() => {
    return Object.values(treatments).filter((t) => t.tenant === activeTenant.id);
  }, [treatments, activeTenant]);

  if (!loaded)
    return (
      <Block contentCentered={true} className="h-100pc">
        <Loader />
      </Block>
    );

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={TREATMENTS_TREATMENTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Treatments List</h2>
        </>
      }
    >
      <TreatmentsList treatmentsList={treatmentsList} treatments={treatments} />
    </Page>
  );
}
