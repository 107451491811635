import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Loader } from '@borrow-ui/ui';

import {
  TreatmentGroupsRoutes,
  TREATMENTS_TREATMENT_GROUPS_BASE_URL,
} from './pages/treatmentGroups/TreatmentGroupsRoutes';
import { preload } from './preload';
import {
  TREATMENTS_BASE_URL,
  TREATMENTS_EXPORT_BASE_URL,
  TREATMENTS_INGEST_BASE_URL,
  TREATMENTS_TREATMENTS_BASE_URL,
  TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL,
} from './constants';
import { TreatmentsHomePage } from './pages/TreatmentsHomePage';
import { ExportHomePage } from './pages/export/ExportHomePage';
import { TreatmentsRoutes } from './pages/treatments/TreatmentsRoutes';
import { TreatmentsConsolidationRoutes } from './pages/treatmentsConsolidation/TreatmentsConsolidationRoutes';
import { TreatmentIngestHomePage } from './pages/ingest/TreatmentIngestHomePage';

export function TreatmentsLanding() {
  const { activeTenant } = useSelector((s) => s.portal);
  const [state, setState] = useState({ appInitialized: false });
  const { initializedTenants } = useSelector((state) => state.treatments);

  const setInitialized = () => setState((state) => ({ ...state, appInitialized: true }));

  useEffect(() => {
    if (initializedTenants[activeTenant.id]) {
      setInitialized();
      return;
    }
    setState((state) => ({ ...state, appInitialized: false }));
    preload(activeTenant).then(setInitialized);
  }, [activeTenant, initializedTenants]);

  const { appInitialized } = state;

  if (!appInitialized) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route path={`${TREATMENTS_BASE_URL}`} exact component={TreatmentsHomePage} />
      <Route path={`${TREATMENTS_TREATMENT_GROUPS_BASE_URL}`} component={TreatmentGroupsRoutes} />
      <Route
        path={`${TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL}`}
        component={TreatmentsConsolidationRoutes}
      />
      <Route path={`${TREATMENTS_EXPORT_BASE_URL}`} component={ExportHomePage} />
      <Route path={`${TREATMENTS_TREATMENTS_BASE_URL}`} component={TreatmentsRoutes} />
      <Route path={`${TREATMENTS_INGEST_BASE_URL}`} component={TreatmentIngestHomePage} />
    </Switch>
  );
}
