import { postRequest } from 'libs/requests/requests';
import BaseEntity from 'models/common/base';

import {
  SET_ECOMMERCE_CATEGORIES,
  SET_ECOMMERCE_CATEGORY,
  DELETE_ECOMMERCE_CATEGORY,
} from '../actions';
export const BASE_URL = 'api/treatments/ecommerce-categories/';

export function newEcommerceCategory() {
  return {
    id: null,
    tenant: null,
    name: '',
    title: '',
    slug: '',
    order: 0,
    image_original: '',
    image_thumbnail: '',
    treatment_group: null,
    description: '',
    status: 'DRAFT',
    group_sections: false,
  };
}

export class EcommerceCategoryEntity extends BaseEntity {
  constructor(properties) {
    super('TreatmentsEcommerceCategory', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  static emptyEntry() {
    return newEcommerceCategory();
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_ECOMMERCE_CATEGORIES,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_ECOMMERCE_CATEGORY });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_ECOMMERCE_CATEGORY });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_ECOMMERCE_CATEGORY);
  }

  reorderSections(id, newOrder) {
    return postRequest(`${BASE_URL}${id}/reorder-sections/`, { new_order: newOrder }).then(
      (updatedCategory) => {
        return this.localUpdate(updatedCategory);
      }
    );
  }

  updateTreatmentsImages(id, payload) {
    return postRequest(`${BASE_URL}${id}/update-treatments-images/`, payload);
  }
}

export const ecommerceCategoriesEntity = new EcommerceCategoryEntity();
