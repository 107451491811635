import React from 'react';

import { Page, Breadcrumbs, TileLink } from '@borrow-ui/ui';

import {
  FILE_UPLOADER_ICON_NAME,
  PORTAL_BREADCRUMBS,
  PORTAL_CLEAR_DATA_BASE_URL,
  PORTAL_FILE_UPLOADER_BASE_URL,
  PORTAL_IMPORT_DATA_BASE_URL,
} from 'apps/portal/constants';

export function PortalHomePage() {
  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PORTAL_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Portal</h2>
        </>
      }
    >
      <div>
        <TileLink
          size="small"
          name="File uploader"
          icon={FILE_UPLOADER_ICON_NAME}
          path={`${PORTAL_FILE_UPLOADER_BASE_URL}`}
          description="Upload a file"
        />
        <TileLink
          size="small"
          name="Data import"
          icon="import_export"
          path={`${PORTAL_IMPORT_DATA_BASE_URL}`}
          description="Import data"
        />
        <TileLink
          size="small"
          name="Data removal"
          icon="dangerous"
          path={`${PORTAL_CLEAR_DATA_BASE_URL}`}
          description="Clear data"
        />
      </div>
    </Page>
  );
}
