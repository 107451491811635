export function createSelectOptionsFromStore(
  storedEntries,
  {
    valueAttribute = 'id',
    labelAttribute = 'name',
    labelAttributeGetter,
    preserveOrder = false,
    sortAttribute,
  } = {}
) {
  /** Creates a list of entries used by Select components.
   *
   * Returns a list of objects, each of them with 3 attributes:
   * - value: used by Select to set the value of the option
   * - label: used by Select as the label shown in the menu
   * - _entry: full entry, can be used to customize behaviour of Select lists
   *
   * storedEntries: an object like { id: {attributes}} with all entries to use.
   *
   * Options:
   * - valueAttribute (string): attribute to use to set the "value" (default: "id")
   * - labelAttribute (string): attribute to use to set the "label" (default: "name")
   * - labelAttributeGetter (function): function that takes the entry as argument and must
   *      return a string, that will set the "label" attribute (use for composite labels,
   *    like "${id} - ${code}")
   * - preserveOrder (boolean): preserve the order of the object. If false, will sort
   *      by sortAttribute (default: false)
   * - sortAttribute (string): attribute to use to sort the final list (default: labelAttribute)
   */
  const selectOptions = Object.values(storedEntries).reduce((options, entry) => {
    const label = labelAttributeGetter ? labelAttributeGetter(entry) : entry[labelAttribute];
    options.push({ value: entry[valueAttribute], label, _entry: entry });
    return options;
  }, []);

  if (preserveOrder) return selectOptions;

  const sortBy = sortAttribute || labelAttribute;
  return selectOptions.sort((a, b) => (a._entry[sortBy] > b._entry[sortBy] ? 1 : -1));
}
