import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { IconControl, Panel, Title } from '@borrow-ui/ui';

import { ecommerceSubsectionsEntity } from 'apps/treatments/models/ecommerceSubsection';

import { TreatmentForm } from '../treatment/TreatmentForm';
import { EditTreatmentModal } from '../treatment/EditTreatmentModal';
import { TreatmentPropType } from '../treatment/TreatmentPropTypes';
import { TreatmentDetail } from '../treatment/TreatmentDetail';
import { TreatmentWarningAttrsPanel } from '../treatment/TreatmentWarningAttrsPanel';
import { immutableMove } from './utils';
import { BuilderContext } from './context';
import { EcommerceSubsectionItemPropType, EcommerceSubsectionPropType } from './TGBPropTypes';

export function TGBEcommerceSubsectionItem({
  ecommerceSubsection,
  ecommerceSubsectionItem,
  treatment,
  index,
  isLast,
}) {
  const { contextData, setContextData } = useContext(BuilderContext);
  const { isLoading } = contextData;
  const { user } = useSelector((s) => s.authentication);

  if (!treatment) {
    console.log('MISSING TREATMENT', ecommerceSubsection, ecommerceSubsectionItem);
    return null;
  }

  const userCanEdit = user.groups.includes('treatments_managers');

  const move = async (from, to) => {
    setContextData({ ...contextData, isLoading: true });
    const currentOrder = ecommerceSubsection.items.map((esi) => esi.id);
    const newOrder = immutableMove(currentOrder, from, to);
    await ecommerceSubsectionsEntity.reorderItems(ecommerceSubsection.id, newOrder);
    setContextData({ ...contextData, isLoading: false });
  };

  const removeItem = async (id) => {
    const proceed = window.confirm('Are you sure you want to delete this item?');
    if (!proceed) return;

    setContextData({ ...contextData, isLoading: true });
    await ecommerceSubsectionsEntity.removeItem(ecommerceSubsection.id, id);
    setContextData({ ...contextData, isLoading: false });
  };

  return (
    <div className="tgb__ecommerce-subsection-item">
      <div className="tgb__ecommerce-subsection-item__order">
        {userCanEdit && (
          <div className="tgb__ecommerce-subsection-item__order__content">
            {ecommerceSubsectionItem.order !== 1 && (
              <IconControl
                name="arrow_upward"
                onClick={() => move(index, index - 1)}
                disabled={isLoading}
              />
            )}
            {!isLast && (
              <IconControl
                name="arrow_downward"
                disabled={isLoading}
                className={
                  ecommerceSubsectionItem.order === 1
                    ? 'tgb__ecommerce-subsection-item__order__first-down'
                    : ''
                }
                onClick={() => move(index, index + 1)}
              />
            )}
          </div>
        )}
      </div>
      <Title tag="h5" className="tgb__ecommerce-subsection-item__title">
        {ecommerceSubsectionItem.order}. {treatment.title} ({treatment.price})
      </Title>

      <div className="tgb__ecommerce-subsection-item__notes">
        <TreatmentWarningAttrsPanel treatment={treatment} />
      </div>
      {userCanEdit && (
        <div className="tgb__ecommerce-subsection-item__controls">
          <div className="tgb__ecommerce-subsection-item__controls__content">
            <Panel
              Trigger={({ setVisible }) => (
                <IconControl name="article" disabled={isLoading} onClick={() => setVisible(true)} />
              )}
              getPanelContentProps={() => ({
                title: `Treatment Detail - ${treatment.title}`,
                content: (
                  <>
                    <EditTreatmentModal treatment={treatment} />
                    <TreatmentDetail treatment={treatment} />
                  </>
                ),
                width: 700,
              })}
            />

            <Panel
              Trigger={({ setVisible }) => (
                <IconControl name="edit" disabled={isLoading} onClick={() => setVisible(true)} />
              )}
              getPanelContentProps={({ setVisible }) => ({
                title: `Edit Treatment - ${treatment.title}`,
                content: (
                  <TreatmentForm
                    treatment={treatment}
                    onSubmitSuccess={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                  />
                ),
                width: 700,
              })}
            />

            <IconControl
              name="delete"
              disabled={isLoading}
              onClick={() => removeItem(ecommerceSubsectionItem.id)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

TGBEcommerceSubsectionItem.propTypes = {
  ecommerceSubsection: EcommerceSubsectionPropType,
  ecommerceSubsectionItem: EcommerceSubsectionItemPropType,
  treatment: TreatmentPropType,
  index: PropTypes.number,
  isLast: PropTypes.bool,
};
