import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button, Modal } from '@borrow-ui/ui';

import { Divider } from 'components/common/Divider';
import { EcommerceCategoryForm } from '../ecommerceCategory/EcommerceCategoryForm';
import { EcommerceSectionForm } from '../ecommerceSection/EcommerceSectionForm';
import { DataPropType } from './TGBPropTypes';
import { TGBEcommerceCategory } from './TGBEcommerceCategory';
import { TGBEcommerceSection } from './TGBEcommerceSection';
import { BuilderContext } from './context';
import './treatment-group-builder.scss';

export function TreatmentGroupBuilder({ treatmentGroup, data, reload }) {
  const [contextData, setContextData] = useState({ isLoading: false, data });
  const { user } = useSelector((s) => s.authentication);
  const { ecommerceCategories, ecommerceSections } = useSelector((s) => s.treatments);
  const categories = data.ecommerceCategoriesIds
    .map((ecId) => ecommerceCategories[ecId])
    .filter((ec) => ec && ec.treatment_group === treatmentGroup.id);
  const userCanEdit = user.groups.includes('treatments_managers');
  return (
    <BuilderContext.Provider value={{ contextData, setContextData }}>
      <div className="tgb__treatment-group-builder">
        {categories.map((ecommerceCategory) => {
          const sections = data.ecommerceSectionsIds
            .map((esId) => ecommerceSections[esId])
            .filter((es) => es && es.category === ecommerceCategory.id);
          return (
            <Fragment key={ecommerceCategory.id}>
              {' '}
              <div className="tgb__ecommerce-category-container">
                <TGBEcommerceCategory
                  ecommerceCategory={ecommerceCategory}
                  reload={reload}
                  sections={sections}
                />
                <div className="tgb__ecommerce-sections">
                  {sections.map((ecommerceSection) => {
                    return (
                      <div key={ecommerceSection.id}>
                        <TGBEcommerceSection
                          ecommerceSection={ecommerceSection}
                          ecommerceCategory={ecommerceCategory}
                          reload={reload}
                          sections={sections}
                        />
                      </div>
                    );
                  })}
                  {userCanEdit && (
                    <>
                      {sections.length > 0 ? <Divider /> : null}
                      <Modal
                        Trigger={({ setVisible }) => {
                          return (
                            <Button
                              size="small"
                              mean="positive-reverse"
                              onClick={() => setVisible(true)}
                            >
                              Add new section
                            </Button>
                          );
                        }}
                        getModalWindowProps={({ setVisible }) => ({
                          title: 'Add new Section',
                          canMaximize: true,
                          content: (
                            <EcommerceSectionForm
                              ecommerceCategory={ecommerceCategory}
                              order={sections.length + 1}
                              onCancel={() => setVisible(false)}
                              onSubmitSuccess={() => reload()}
                            />
                          ),
                        })}
                      />
                    </>
                  )}
                </div>
              </div>
              <hr className="tgb__divider" />
            </Fragment>
          );
        })}
        {userCanEdit && (
          <div className="tgb__new-category">
            <Modal
              Trigger={({ setVisible }) => {
                return (
                  <Button mean="positive" onClick={() => setVisible(true)}>
                    Add new category
                  </Button>
                );
              }}
              getModalWindowProps={({ setVisible }) => ({
                title: 'Add new Category',
                canMaximize: true,
                maximized: true,
                content: (
                  <EcommerceCategoryForm
                    treatmentGroup={treatmentGroup}
                    order={categories.length + 1}
                    onCancel={() => setVisible(false)}
                    onSubmitSuccess={() => {
                      reload();
                    }}
                  />
                ),
              })}
            />
          </div>
        )}
      </div>
    </BuilderContext.Provider>
  );
}

TreatmentGroupBuilder.propTypes = {
  treatmentGroup: PropTypes.object.isRequired,
  data: DataPropType,
  reload: PropTypes.func,
};
