import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Forms, Loader, Button } from '@borrow-ui/ui';

import { FormsErrors } from 'components/common/FormErrors';
import {
  ecommerceCategoriesEntity,
  newEcommerceCategory,
} from 'apps/treatments/models/ecommerceCategory';

const { Field, Input, Textarea, Select, Checkbox } = Forms;

const statusesOptions = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'LIVE', label: 'Live' },
];

export function EcommerceCategoryForm({
  ecommerceCategory,
  treatmentGroup,
  order,
  onSubmitSuccess,
  onCancel,
}) {
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [data, setData] = useState(ecommerceCategory || newEcommerceCategory());

  const onSubmit = (e) => {
    e.preventDefault();
    const message = { errors: {} };
    setSubmitMessage(null);

    if (!data.title) message.errors.title = 'Title is required';
    if (!data.name) message.errors.name = 'Name is required';
    if (!data.slug) message.errors.slug = 'Slug is required';

    if (Object.values(message.errors).length > 0) {
      setSubmitMessage(message);
      return;
    }

    const newCategory = {
      ...data,
      order: ecommerceCategory ? ecommerceCategory.order : order,
      treatment_group: ecommerceCategory ? ecommerceCategory.treatment_group : treatmentGroup.id,
      tenant: ecommerceCategory ? ecommerceCategory.tenant : treatmentGroup.tenant,
    };

    setLoading(true);
    ecommerceCategoriesEntity
      .save(newCategory)
      .then((newC) => {
        setLoading(false);
        onSubmitSuccess && onSubmitSuccess(newC);
      })
      .catch(() => setLoading(false));
  };

  const { errors } = submitMessage || { errors: {} };

  return (
    <form onSubmit={onSubmit}>
      <FormsErrors submitMessage={submitMessage} />
      <Field label="Title">
        <Input
          invalid={!!errors.title}
          disabled={loading}
          onChange={(e) => setData({ ...data, title: e.target.value })}
          value={data.title}
        />
      </Field>
      <Field label="Name">
        <Input
          invalid={!!errors.name}
          disabled={loading}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          value={data.name}
        />
      </Field>
      <Field label="Slug">
        <Input
          invalid={!!errors.slug}
          disabled={loading}
          onChange={(e) => setData({ ...data, slug: e.target.value })}
          value={data.slug}
        />
      </Field>
      <Field label="Status">
        <div className="w-200">
          <Select
            disabled={loading}
            options={statusesOptions}
            onChange={(entry) => setData({ ...data, status: entry.value })}
            value={data.status}
          />
        </div>
      </Field>
      <Field label="Image - Desktop">
        <Input
          invalid={!!errors.image_original}
          disabled={loading}
          onChange={(e) => setData({ ...data, image_original: e.target.value })}
          value={data.image_original}
        />
      </Field>
      <Field label="Image - Mobile">
        <Input
          invalid={!!errors.image_thumbnail}
          disabled={loading}
          onChange={(e) => setData({ ...data, image_thumbnail: e.target.value })}
          value={data.image_thumbnail}
        />
      </Field>
      <Field label="Catalog Cover (image)">
        <Input
          invalid={!!errors.catalog_cover}
          disabled={loading}
          onChange={(e) => setData({ ...data, catalog_cover: e.target.value })}
          value={data.catalog_cover}
        />
      </Field>
      <Field label="Group Sections">
        <Checkbox
          disabled={loading}
          onClick={() => setData({ ...data, group_sections: !data.group_sections })}
          checked={data.group_sections}
        />
        <div className="color-neutral-light m-l-10" style={{ fontSize: 12 }}>
          If selected, the sections will be rendered as tabs and only one of them will be visible
          per time. Otherwise, sections will be shown one below the other.
        </div>
      </Field>
      <Field label="Description">
        <Textarea
          disabled={loading}
          onChange={(e) => setData({ ...data, description: e.target.value })}
          value={data.description || ''}
        />
      </Field>

      <div className="flex-end-center">
        {loading && (
          <span className="m-r-10">
            <Loader type="inline" />
          </span>
        )}
        <Button type="submit" mean="positive" disabled={loading}>
          Save
        </Button>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </div>
    </form>
  );
}

EcommerceCategoryForm.propTypes = {
  ecommerceCategory: PropTypes.object,
  treatmentGroup: PropTypes.object,
  order: PropTypes.number,
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};
