import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { Page, Breadcrumbs, Block, Button, Loader } from '@borrow-ui/ui';

import { store } from 'store/store';
import { treatmentsEntity } from 'apps/treatments/models/treatment';
import { SET_LOADED_ARCHIVED } from 'apps/treatments/actions';
import {
  TREATMENTS_TREATMENTS_BASE_URL,
  TREATMENTS_TREATMENTS_BREADCRUMBS,
} from 'apps/treatments/constants';
import { TreatmentDetail } from 'apps/treatments/components/treatment/TreatmentDetail';
import { TreatmentPublishToDdbButton } from 'apps/treatments/components/treatment/treatment-publish-to-ddb-button/TreatmentPublishToDdbButton';
import { userCanPublishDdb } from 'utils/userGroups';

export function TreatmentDetailPage() {
  const [loaded, setLoaded] = useState(false);
  const { activeEnvironment, activeTenant } = useSelector((s) => s.portal);
  const { treatments } = useSelector((s) => s.treatments);

  const { id: treatmentId } = useParams();

  const treatment = treatments[+treatmentId];

  useEffect(() => {
    if (treatment) {
      setLoaded(true);
      return;
    }
    treatmentsEntity.fetch({ qs: { tenant_id: activeTenant.id, include_archived: 1 } }).then(() => {
      store.dispatch({ type: SET_LOADED_ARCHIVED });
      setLoaded(true);
    });
  }, [treatment, activeTenant]);

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={TREATMENTS_TREATMENTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">{treatment ? treatment.title : ''}</h2>
        </>
      }
      pageHeaderProps={{
        controls: (
          <>
            {userCanPublishDdb(activeEnvironment.codename) && (
              <TreatmentPublishToDdbButton treatment={treatment} />
            )}
            <Button
              tag={Link}
              to={`${TREATMENTS_TREATMENTS_BASE_URL}/${treatmentId}/edit`}
              className="m-r-5"
              mean="regular-reverse"
            >
              Edit
            </Button>
          </>
        ),
      }}
    >
      <Block outstanding={true}>
        {loaded ? <TreatmentDetail treatment={treatment} /> : <Loader />}
      </Block>
    </Page>
  );
}
