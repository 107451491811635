import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Page, Breadcrumbs, Loader } from '@borrow-ui/ui';

import { treatmentsEntity } from 'apps/treatments/models/treatment';
import {
  TREATMENTS_TREATMENTS_BASE_URL,
  TREATMENTS_TREATMENTS_BREADCRUMBS,
} from 'apps/treatments/constants';
import { TreatmentForm } from 'apps/treatments/components/treatment/TreatmentForm';

export function EditTreatmentFormPage() {
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();
  const { treatments } = useSelector((s) => s.treatments);

  const { id: treatmentId } = useParams();

  const treatment = treatments[+treatmentId];

  useEffect(() => {
    if (loaded || !treatmentId) return;

    treatmentsEntity.get(treatmentId).then(() => {
      setLoaded(true);
    });
  }, [loaded, treatmentId]);

  const onSubmitSuccess = (savedTreatment) => {
    history.push(`${TREATMENTS_TREATMENTS_BASE_URL}/${savedTreatment.id}`);
  };

  const onCancel = () => {
    history.push(`${TREATMENTS_TREATMENTS_BASE_URL}/${treatment.id}`);
  };

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={TREATMENTS_TREATMENTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Edit {treatment ? treatment.title : ''}</h2>
        </>
      }
    >
      {loaded ? (
        <TreatmentForm
          treatment={treatment}
          onSubmitSuccess={onSubmitSuccess}
          onCancel={onCancel}
        />
      ) : (
        <Loader />
      )}
    </Page>
  );
}
