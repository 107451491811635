import React from 'react';
import PropTypes from 'prop-types';

import { Text, Button, Loader, Icon, Forms } from '@borrow-ui/ui';
const { Field, Input } = Forms;

export function LoginForm({ username, password, isLoading, updateField, login, error }) {
  return (
    <div className="login__form">
      <Field label="Username" required={true}>
        <Input
          id="username"
          onChange={(e) => updateField('username', e.target.value)}
          value={username}
          disabled={isLoading}
        />
        <Icon name="person" size="small" className="color-neutral m-l-10" />
      </Field>
      {error && !username && (
        <Text size="small" className="color-negative m-t-0">
          Username is required.
        </Text>
      )}

      <Field label="Password" required={true}>
        <Input
          id="password"
          type="password"
          onChange={(e) => updateField('password', e.target.value)}
          value={password}
          disabled={isLoading}
        />
        <Icon name="vpn_key" size="small" className="color-neutral m-l-10" />
      </Field>
      {error && !password && (
        <Text size="small" className="color-negative m-t-0">
          Password is required.
        </Text>
      )}

      <div className="flex-center-center m-t-20">
        <Button
          id="login-submit"
          className="w-100pc"
          mean="positive"
          onClick={login}
          disabled={isLoading}
        >
          Login
        </Button>
      </div>
      <div>{isLoading && <Loader />}</div>
      {error && password && username && (
        <div>
          <h4 className="color-negative">Error</h4>
          {error.non_field_errors && (
            <Text className="color-negative">
              {error.non_field_errors.map((err) => {
                return <div key={err}>{err}</div>;
              })}
            </Text>
          )}
          {!error.non_field_errors && <pre>JSON.parse(error)</pre>}
        </div>
      )}
    </div>
  );
}

export const baseLoginPropTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

LoginForm.propTypes = baseLoginPropTypes;
