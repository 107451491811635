export const LAYOUTS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  DEFAULT: 'vertical',
};

export const ALIGNMENTS = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  DEFAULT: 'left',
};
