import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button, IconControl, Modal, Title } from '@borrow-ui/ui';

import { sortObjectsListByAttributes } from 'utils/data';
import { ecommerceCategoriesEntity } from 'apps/treatments/models/ecommerceCategory';
import { ecommerceSectionsEntity } from 'apps/treatments/models/ecommerceSection';

import { EcommerceSubsectionForm } from '../ecommerceSubsection/EcommerceSubsectionForm';
import { EcommerceSectionForm } from '../ecommerceSection/EcommerceSectionForm';
import { EcommerceCategoryPropType, EcommerceSectionPropType } from './TGBPropTypes';
import { TGBEcommerceSubsection } from './TGBEcommerceSubsection';
import { BuilderContext } from './context';
import { immutableMove } from './utils';

export function TGBEcommerceSection({ ecommerceSection, ecommerceCategory, reload, sections }) {
  const { user } = useSelector((s) => s.authentication);
  const { contextData, setContextData } = useContext(BuilderContext);
  const { isLoading, data } = contextData;

  const [showSubsections, setShowSubsections] = useState(true);
  const { ecommerceSubsections } = useSelector((s) => s.treatments);
  const subsections = sortObjectsListByAttributes(
    data.ecommerceSubsectionsIds
      .map((esId) => ecommerceSubsections[esId])
      .filter((es) => es && es.section === ecommerceSection.id),
    ['order']
  );

  const userCanEdit = user.groups.includes('treatments_managers');

  const removeSection = async (id) => {
    const proceed = window.confirm('Are you sure you want to delete this section?');
    if (!proceed) return;

    setContextData({ ...contextData, isLoading: true });
    await ecommerceSectionsEntity.delete(ecommerceSection.id, id);
    setContextData((cd) => ({
      ...cd,
      isLoading: false,
    }));
    reload();
  };

  const move = async (from, to) => {
    setContextData({ ...contextData, isLoading: true });
    const newOrder = immutableMove(
      sections.map((ss) => ss.id),
      from,
      to
    );
    await ecommerceCategoriesEntity.reorderSections(ecommerceCategory.id, newOrder);
    setContextData({ ...contextData, isLoading: false });
    reload();
  };

  return (
    <div className="tgb__ecommerce-section">
      <div className="tgb__ecommerce-section__header">
        <Title tag="h3" className="m-0">
          {ecommerceSection.order}. {ecommerceSection.title}
        </Title>
        <div className="tgb__ecommerce-section__show">
          <IconControl
            onClick={() => setShowSubsections((s) => !s)}
            name={showSubsections ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
          />
        </div>
        {userCanEdit && (
          <div className="tgb__ecommerce-section__controls">
            <Modal
              Trigger={({ setVisible }) => {
                return <IconControl name="edit" onClick={() => setVisible(true)} />;
              }}
              getModalWindowProps={({ setVisible }) => ({
                title: `Edit ${ecommerceSection.title} `,
                canMaximize: true,
                content: (
                  <EcommerceSectionForm
                    ecommerceSection={ecommerceSection}
                    onCancel={() => setVisible(false)}
                    onSubmitSuccess={() => {
                      reload();
                    }}
                  />
                ),
              })}
            />
            <IconControl
              name="delete"
              disabled={isLoading}
              onClick={() => removeSection(ecommerceSection.id)}
            />
            {ecommerceSection.order !== 1 && (
              <IconControl
                name="arrow_upward"
                onClick={() => move(ecommerceSection.order - 1, ecommerceSection.order - 2)}
                disabled={isLoading}
              />
            )}
            {ecommerceSection.order !== sections.length && (
              <IconControl
                name="arrow_downward"
                disabled={isLoading}
                onClick={() => move(ecommerceSection.order - 1, ecommerceSection.order)}
              />
            )}
          </div>
        )}
      </div>
      {showSubsections && (
        <>
          <div className="tgb__ecommerce-subsections">
            {subsections.map((ecommerceSubsection) => {
              return (
                <div key={ecommerceSubsection.id}>
                  <TGBEcommerceSubsection
                    ecommerceSubsection={ecommerceSubsection}
                    subsections={subsections}
                    ecommerceSection={ecommerceSection}
                    reload={reload}
                  />
                </div>
              );
            })}
          </div>
          {userCanEdit && (
            <div className="tgb__ecommerce-subsections m-b-20">
              <Modal
                Trigger={({ setVisible }) => {
                  return (
                    <Button
                      size="small"
                      mean="positive-reverse"
                      disabled={isLoading}
                      onClick={() => setVisible(true)}
                    >
                      Add new subsection
                    </Button>
                  );
                }}
                getModalWindowProps={({ setVisible }) => ({
                  title: 'Add new Subsection',
                  canMaximize: true,
                  content: (
                    <EcommerceSubsectionForm
                      ecommerceSection={ecommerceSection}
                      order={subsections.length + 1}
                      onCancel={() => setVisible(false)}
                      onSubmitSuccess={(newSubsection) => {
                        setContextData((cd) => ({
                          ...cd,
                          data: {
                            ...cd.data,
                            ecommerceSubsectionsIds: [
                              ...data.ecommerceSubsectionsIds,
                              newSubsection.id,
                            ],
                          },
                        }));

                        setVisible(false);
                      }}
                    />
                  ),
                })}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

TGBEcommerceSection.propTypes = {
  ecommerceSection: EcommerceSectionPropType,
  ecommerceCategory: EcommerceCategoryPropType,
  sections: PropTypes.arrayOf(EcommerceSectionPropType),
  reload: PropTypes.func,
};
