import React from 'react';

import { Page, Breadcrumbs, Block } from '@borrow-ui/ui';

import { PORTAL_BREADCRUMBS } from 'apps/portal/constants';
import { AccountPreferences } from './userPreferences/AccountPreferences';

export function UserPreferencesPage() {
  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PORTAL_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Your preferences</h2>
        </>
      }
    >
      <Block outstanding={true}>
        <AccountPreferences />
      </Block>
    </Page>
  );
}
