import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Loader } from '@borrow-ui/ui';

import { preload } from './preload';
import { DASH_USER_LOOKUP_BASE_URL } from './constants';
import { UserLookupPage } from './pages/userLookup/UserLookupPage';

export function DashLanding() {
  const portal = useSelector((state) => state.portal);
  const [state, setState] = useState({ appInitialized: false });

  const setInitialized = () => setState((state) => ({ ...state, appInitialized: true }));
  const initialize = () => {
    preload().then(setInitialized);
  };

  useEffect(() => {
    if (!portal.initialized) initialize();
    else setInitialized();
  }, []); // eslint-disable-line

  const { appInitialized } = state;

  if (!appInitialized) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route exact path={DASH_USER_LOOKUP_BASE_URL} component={UserLookupPage} />
    </Switch>
  );
}
