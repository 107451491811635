export function treatmentHasWarnings(treatment) {
  return (
    !treatment.nua_slug ||
    !treatment.slug ||
    !treatment.image_original ||
    !treatment.image_thumbnail ||
    treatment.crm_status === 'ARCHIVED' ||
    treatment.status !== 'LIVE' ||
    !treatment.available_online
  );
}

export function treatmentWarningAttrs(treatment) {
  if (!treatment) return [];

  const warningAttrs = [];
  if (!treatment.crm_id) warningAttrs.push('crm_id');
  if (!treatment.slug) warningAttrs.push('slug');
  if (!treatment.nua_slug) warningAttrs.push('nua_slug');
  if (!treatment.image_original) warningAttrs.push('image_original');
  if (!treatment.image_thumbnail) warningAttrs.push('image_thumbnail');
  if (treatment.crm_status === 'ARCHIVED') warningAttrs.push('archived');
  if (treatment.status !== 'LIVE') warningAttrs.push('status');
  if (!treatment.available_online) warningAttrs.push('available_online');
  return warningAttrs;
}
