import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Page, Breadcrumbs, Block } from '@borrow-ui/ui';

import { USERS_BREADCRUMBS } from 'apps/portal/constants';
import { UserDetail } from '../../components/users/UserDetail';

export function UserDetailPage() {
  const { users } = useSelector((s) => s.portal);

  const { id: userId } = useParams();

  const user = users[+userId];

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={USERS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">{user.full_name}</h2>
        </>
      }
    >
      <Block outstanding={true}>
        <UserDetail user={user} />
      </Block>
    </Page>
  );
}
