export function formatAddress(address) {
  if (!address) return '';

  return [
    address.line1,
    address.line2,
    address.city,
    address.county,
    address.state,
    address.country,
    address.postcode,
  ]
    .filter((field) => field && field.trim() !== '')
    .join(', ');
}

export function getFormattedPrice(price) {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'eur',
  });

  const formattedPriceInfo = formatter.formatToParts(price).reduce(
    (acc, p) => {
      let { fullPrice, noCurrencyPrice, currencySymbol, integerPrice } = acc;

      if (p.type === 'currency') {
        currencySymbol += p.value;
        integerPrice += p.value;
      } else {
        noCurrencyPrice += p.value;
        if (p.type === 'integer') {
          integerPrice += p.value;
        }
      }
      fullPrice += p.value;

      return { fullPrice, noCurrencyPrice, currencySymbol, integerPrice };
    },
    { fullPrice: '', noCurrencyPrice: '', currencySymbol: '', integerPrice: '' }
  );

  return formattedPriceInfo.noCurrencyPrice;
}
