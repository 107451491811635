import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import { Page, Breadcrumbs, Block, TileLink, Title } from '@borrow-ui/ui';

import { getRequest } from 'libs/requests/requests';
import { beautify } from 'utils/strings';
import {
  BACKEND_EXPORT_PRODUCTS_URL,
  BACKEND_EXPORT_PRODUCTS_LINKS_URL,
  PRODUCTS_BREADCRUMBS,
  PRODUCTS_ICON_NAME,
} from 'apps/products/constants';
import { productsEntity } from 'apps/products/models/product';
import { userCanPublishDdb } from 'utils/userGroups';

export function ProductsExportHomePage() {
  const { activeTenant, activeEnvironment } = useSelector((s) => s.portal);
  const [isLoading, setIsLoading] = useState(false);

  const exportFile = (source) => {
    if (isLoading) return;

    setIsLoading(true);
    let url;
    let fileName;
    let fileType;
    if (source === 'products') {
      url = `${BACKEND_EXPORT_PRODUCTS_URL}/${activeTenant.codename}/${activeEnvironment.codename}/`;
      fileName = `ecommerce_products_${activeTenant.codename}.csv`;
      fileType = 'text/csv;charset=utf-8';
    }
    if (source === 'products-links') {
      url = `${BACKEND_EXPORT_PRODUCTS_LINKS_URL}/${activeTenant.codename}/${activeEnvironment.codename}/`;
      fileName = `ecommerce_products_links_${activeTenant.codename}.csv`;
      fileType = 'text/csv;charset=utf-8';
    }
    getRequest(url, { responseType: 'text' }).then((r) => {
      const blob = new Blob([r], { type: fileType });
      saveAs(blob, fileName);
      setIsLoading(false);
    });
  };

  const publishProductsToDdb = (environment) => {
    if (!userCanPublishDdb(environment) || isLoading) return;

    const confirmed = window.confirm(
      `Are you sure? All Products will be published to ${beautify(environment)} - ${
        activeTenant.name
      }.`
    );

    if (!confirmed) return;

    setIsLoading(true);
    productsEntity
      .publishToDashDdb(environment, activeTenant.codename)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        alert('An error occurred while publishing Products.');
        console.error(error);
      });
  };

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PRODUCTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Export</h2>
        </>
      }
    >
      <Block outstanding={true}>
        <TileLink
          id="export-products"
          size="big"
          name="Export Products"
          icon={isLoading ? 'access_time' : PRODUCTS_ICON_NAME}
          onClick={() => exportFile('products')}
          getPath={() => '#'}
          description={`Products CSV - ${activeTenant.name}`}
        />
        <TileLink
          id="export-products-links"
          size="big"
          name="Export Links"
          icon={isLoading ? 'access_time' : 'share'}
          onClick={() => exportFile('products-links')}
          getPath={() => '#'}
          description={`Products Links CSV - ${activeTenant.name}`}
        />
      </Block>
      {userCanPublishDdb() && (
        <>
          <Title tag="h3">Publish to Dash DDB</Title>
          <Block outstanding={true}>
            {userCanPublishDdb('staging') && (
              <TileLink
                id="publish-products-ddb"
                size="big"
                name="Publish Products"
                icon={isLoading ? 'access_time' : PRODUCTS_ICON_NAME}
                onClick={() => publishProductsToDdb('staging')}
                getPath={() => '#'}
                description={
                  <>
                    Publish Products to <b>Staging</b> - {activeTenant.name}
                  </>
                }
              />
            )}
            {userCanPublishDdb('production') && (
              <TileLink
                id="publish-products-ddb"
                size="big"
                name="Publish Products"
                icon={isLoading ? 'access_time' : PRODUCTS_ICON_NAME}
                onClick={() => publishProductsToDdb('production')}
                getPath={() => '#'}
                description={
                  <>
                    Publish Products to <b>Production</b> - {activeTenant.name}
                  </>
                }
              />
            )}
          </Block>
        </>
      )}
    </Page>
  );
}
