import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Link, Table, Forms } from '@borrow-ui/ui';

import { sortObjectsListByAttributes } from 'utils/data';
import {
  productStatuses,
  PRODUCTS_PRODUCTS_BASE_URL,
  statusesOptions,
} from 'apps/products/constants';

import './products-list.scss';
import { productHasWarnings } from 'apps/products/utils';
import { ProductWarningAttrsPanel } from 'apps/products/components/product/ProductWarningAttrsPanel';
import { EditProductModal } from 'apps/products/components/product/EditProductModal';
import { Image } from 'components/common/Image';
import { ProductPublishToDdbButton } from './product-publish-to-ddb-button/ProductPublishToDdbButton';
import { ProductRevalidateButton } from './product-revalidate-button/ProductRevalidateButton';
import { userCanPublishDdb } from 'utils/userGroups';

const { Input, Field, Select } = Forms;

export function ProductsList({
  productsList,
  defaultStatus = productStatuses.PUBLISHED,
  showStatusFilter = true,
  showBrandFilter = true,
  showWarningFilter = true,
  showImages = false,
  showEdit = false,
}) {
  const { productCategories, productBrands } = useSelector((s) => s.products);
  const { activeEnvironment, activeTenant } = useSelector((s) => s.portal);
  const [filters, setFilters] = useState({
    status: defaultStatus,
    warningsOnly: 'no',
  });
  const [search, setSearch] = useState('');

  const productsColumns = useMemo(() => {
    const columns = [
      { prop: 'content' },
      { prop: 'price_c', title: 'Price', width: 60 },
      { prop: 'measurement_c', title: 'Measur.', width: 90 },
      { prop: 'status', title: 'Status', width: 120 },
      { prop: 'actions', title: 'Actions', width: 60 },
      { prop: 'has_warnings', width: 30 },
    ];
    if (showImages) columns.push({ prop: 'images', title: 'Images', width: 120 });
    if (showEdit) columns.push({ prop: 'edit_button', title: '', width: 70 });
    return columns;
  }, [showEdit, showImages]);

  const brandsOptions = useMemo(() => {
    return sortObjectsListByAttributes(
      Object.values(productBrands)
        .filter((pb) => pb.tenant === activeTenant.id)
        .map((pb) => ({ value: pb.id, label: pb.name })),
      ['label']
    );
  }, [productBrands, activeTenant]);

  const entries = productsList
    .map((p) => {
      return {
        ...p,
        price_c: (
          <div className="flex-center-center" style={{ gap: 5 }}>
            <span>{p.price}</span>
            {p.compare_price ? (
              <span className="color-accent" style={{ whiteSpace: 'nowrap' }}>
                / {p.compare_price}
              </span>
            ) : null}
          </div>
        ),
        brand_name: productBrands[p.brand].name,
        category_name: productCategories[p.category].name,
        content: (
          <div>
            <div className="m-b-10">
              <Link to={`${PRODUCTS_PRODUCTS_BASE_URL}/${p.id}`}>{p.title}</Link>
            </div>
            <div className="flex-start-start" style={{ fontSize: 11, gap: 10 }}>
              <div>
                <span className="color-neutral-light m-r-5">CRM ID:</span>
                {p.crm_id}
              </div>
              <div>
                <span className="color-neutral-light m-r-5">Category:</span>
                {productCategories[p.category].name}
              </div>
              <div>
                <span className="color-neutral-light m-r-5">Brand:</span>
                {productBrands[p.brand].name}
              </div>
            </div>
          </div>
        ),
        measurement_c: p.measurement ? (
          <span style={{ whiteSpace: 'nowrap' }}>
            {p.measurement.replace('.00', '')} {p.measurement_unit}
          </span>
        ) : null,
        edit_button: showEdit ? <EditProductModal product={p} /> : null,
        actions: (
          <>
            {userCanPublishDdb(activeEnvironment.codename) && (
              <ProductPublishToDdbButton product={p} />
            )}
            <ProductRevalidateButton product={p} />
          </>
        ),
        has_warnings: <ProductWarningAttrsPanel product={p} />,
        images: (
          <div className="flex-spacebetween-start">
            <Image src={p.image_original} showName={false} width={50} />
            <Image src={p.image_thumbnail} showName={false} width={50} />
          </div>
        ),
      };
    })
    .filter((p) => {
      const hasFilters = Object.values(filters).length > 0;
      if (!search && !hasFilters) return true;
      let include = false;
      if (hasFilters) {
        if (filters.status && p.status !== filters.status) return false;
        if (filters.brand && p.brand !== filters.brand) return false;
        if (filters.warningsOnly === 'yes' && !productHasWarnings(p)) return false;
      }
      if (!search) return true;
      if (search) {
        const searchL = search.toLowerCase();
        include =
          p.crm_id.toLowerCase().includes(searchL) ||
          p.title.toLowerCase().includes(searchL) ||
          p.status.toLowerCase().includes(searchL) ||
          p.brand_name.toLowerCase().includes(searchL) ||
          p.category_name.toLowerCase().includes(searchL);
      }
      return include;
    });

  return (
    <div className="pl__products-list">
      <div className="pl__filters">
        <div className="pl__search-bar">
          <Field label="Search">
            <Input
              className="w-100pc"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Type your search here..."
            />
          </Field>
        </div>
        <div className="pl__filters__selectors">
          {showStatusFilter && (
            <Field label="Status">
              <div className="w-150">
                <Select
                  options={statusesOptions}
                  onChange={(e) => setFilters({ ...filters, status: e ? e.value : null })}
                  value={filters.status}
                  isClearable
                />
              </div>
            </Field>
          )}
          {showBrandFilter && (
            <Field label="Brand">
              <div className="w-300">
                <Select
                  options={brandsOptions}
                  onChange={(e) => setFilters({ ...filters, brand: e ? e.value : null })}
                  value={filters.brand}
                  isClearable
                />
              </div>
            </Field>
          )}
          {showWarningFilter && (
            <Field label="Warnings Only">
              <div className="w-150">
                <Select
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  onChange={(e) => setFilters({ ...filters, warningsOnly: e ? e.value : null })}
                  value={filters.warningsOnly}
                  isClearable
                />
              </div>
            </Field>
          )}
        </div>
      </div>
      <div className="pl__list">
        <Table columns={productsColumns} entries={entries} />
      </div>
    </div>
  );
}

ProductsList.propTypes = {
  productsList: PropTypes.array,
  defaultStatus: PropTypes.oneOf(Object.values(productStatuses.PUBLISHED)),
  showStatusFilter: PropTypes.bool,
  showBrandFilter: PropTypes.bool,
  showWarningFilter: PropTypes.bool,
  showImages: PropTypes.bool,
  showEdit: PropTypes.bool,
};
