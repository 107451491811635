import React, { useState } from 'react';

import { Page, Breadcrumbs, Loader, Button, Forms, Block } from '@borrow-ui/ui';

import { FILE_UPLOADER_ENDPOINT, PORTAL_FILE_UPLOADER_BREADCRUMBS } from 'apps/portal/constants';
import { postRequest } from 'libs/requests/requests';

const { Field, Dropzone, Input } = Forms;

export function FileUploaderPage() {
  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PORTAL_FILE_UPLOADER_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">File Uploader</h2>
        </>
      }
    >
      <UploaderForm />
    </Page>
  );
}

function UploaderForm() {
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [data, setData] = useState({
    folder: '',
    name: '',
  });
  const [attachments, setAttachments] = useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    let message = '';
    setSubmitMessage(null);

    if (!attachments.length) message = 'Attachment is required';

    if (message) {
      setSubmitMessage(message);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', attachments[0]);

    if (data.name) formData.append('name', data.name);
    if (data.folder) formData.append('folder', data.folder);

    postRequest(FILE_UPLOADER_ENDPOINT, undefined, {
      body: formData,
    })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setSubmitMessage('Something went wrong!');
        setLoading(false);
      });
  };

  return (
    <Block outstanding={true}>
      <form onSubmit={onSubmit}>
        <div style={{ width: 500 }}>
          {submitMessage && <div className="color-negative">{submitMessage}</div>}
          <Field label="Name">
            <Input
              name="name"
              value={data.name}
              disabled={loading}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              placeholder="Keep original name"
            />
          </Field>
          <Field label="Folder">
            <Input
              name="folder"
              value={data.folder}
              disabled={loading}
              onChange={(e) => setData({ ...data, folder: e.target.value })}
              placeholder="Default folder"
            />
          </Field>
          <Field label="File" required>
            <Dropzone
              dragMessage="Drop the file or click here..."
              onDrop={setAttachments}
              maxFiles={1}
              disabled={loading}
            />
          </Field>

          <div className="flex-end-center">
            {loading && (
              <span className="m-r-10">
                <Loader type="inline" />
              </span>
            )}
            <Button type="submit" mean="positive" disabled={loading}>
              Upload
            </Button>
          </div>
        </div>
      </form>
    </Block>
  );
}
