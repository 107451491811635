import BaseEntity from 'models/common/base';

import { SET_USERS, SET_USER } from '../actions';
export const BASE_URL = 'api/company-portal/users/';

export function newUser() {
  return {
    id: null,
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    is_active: true,
  };
}

export class UserEntity extends BaseEntity {
  constructor(properties) {
    super('PortalUser', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  static emptyEntry() {
    throw new Error('User entity is read only');
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_USERS,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_USER });
  }

  delete() {
    throw new Error('User entity cannot be deleted.');
  }

  /*     localUpdateMany(entries, { mapFields } = {}) {
        const updateEntries = mapFields ? entries.map(convertEstimations) : entries;
        return super.localUpdateMany(updateEntries, SET_ACTIVITIES);
    }

    copyActivitiesFromActivitiesList(projectId, selectedActivities) {
        return postRequest(
            PROJECTS_COPY_ACTIVITIES_FROM_ACTIVITIES_LIST_URL.replace('<project_id>', projectId),
            { activities: selectedActivities }
        ).then((newActivities) => {
            return this.localUpdateMany(newActivities, { mapFields: true });
        });
    }

    setActivitiesOrder(projectId, activities) {
        return postRequest(PROJECTS_SET_ACTIVITIES_ORDER_URL.replace('<project_id>', projectId), {
            activities_order: activities,
        }).then((updatedActivities) => {
            return this.localUpdateMany(updatedActivities, { mapFields: true });
        });
    }    
 */
}

export const usersEntity = new UserEntity();
