import React, { useEffect, useState } from 'react';

import { Block, Loader, Page, Breadcrumbs, TileLink } from '@borrow-ui/ui';

import { PORTAL_BREADCRUMBS } from 'apps/portal/constants';
import {
  TREATMENTS_EXPORT_BASE_URL,
  TREATMENTS_ICON_NAME,
  TREATMENTS_TREATMENTS_BASE_URL,
  TREATMENTS_TREATMENT_GROUPS_BASE_URL,
  TREATMENT_GROUPS_ICON_NAME,
  TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL,
  TREATMENTS_INGEST_BASE_URL,
} from '../constants';
import { useSelector } from 'react-redux';

export function TreatmentsHomePage() {
  const [loaded, setLoaded] = useState(false);
  const { user } = useSelector((s) => s.authentication);
  const userIsTreatmentManager = user.groups.includes('treatments_managers');

  // keep the same logic of all other pages, even if the entity
  // is already available in the store as it is a "reference"
  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded)
    return (
      <Block contentCentered={true} className="h-100pc">
        <Loader />
      </Block>
    );

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PORTAL_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Treatments Home</h2>
        </>
      }
    >
      <div>
        <TileLink
          size="small"
          name="Treatments"
          icon={TREATMENTS_ICON_NAME}
          path={`${TREATMENTS_TREATMENTS_BASE_URL}`}
          description="List of all Treatments (courses and services)"
        />
        <TileLink
          size="small"
          name="Groups and Collections"
          icon={TREATMENT_GROUPS_ICON_NAME}
          path={`${TREATMENTS_TREATMENT_GROUPS_BASE_URL}`}
          description="Dash Treatments Organisation"
        />
        <TileLink
          size="small"
          name="Treatments Consolidation"
          icon="warning"
          path={`${TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL}`}
          description="Dash Treatments Consolitation"
        />
        {userIsTreatmentManager && (
          <>
            <TileLink
              size="small"
              name="Export or Publish"
              icon={'file_download'}
              path={`${TREATMENTS_EXPORT_BASE_URL}`}
              description="Export data in XLS/JSON or Publish to Dash"
            />
            <TileLink
              size="small"
              name="Phorest Ingestion"
              icon={'cloud_download'}
              path={`${TREATMENTS_INGEST_BASE_URL}`}
              description="Ingest Treatments from Phorest"
            />
          </>
        )}
      </div>
    </Page>
  );
}
