import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Block, Loader, Page, Breadcrumbs } from '@borrow-ui/ui';

import { USERS_BREADCRUMBS } from 'apps/portal/constants';

import { UsersList } from '../../components/users/UsersList';

export function UsersListPage() {
  const [loaded, setLoaded] = useState(false);
  const { users } = useSelector((s) => s.portal);

  // keep the same logic of all other pages, even if the entity
  // is already available in the store as it is a "reference"
  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded)
    return (
      <Block contentCentered={true} className="h-100pc">
        <Loader />
      </Block>
    );

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={USERS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Users List</h2>
        </>
      }
    >
      <UsersList users={users} />
    </Page>
  );
}
