import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import { Page, Breadcrumbs, Block, TileLink, Title } from '@borrow-ui/ui';

import { getRequest } from 'libs/requests/requests';
import { beautify } from 'utils/strings';
import {
  BACKEND_EXPORT_TREATMENTS_URL,
  BACKEND_EXPORT_COLLECTIONS_URL,
  BACKEND_EXPORT_TREATMENTS_LINKS_URL,
  TREATMENTS_BREADCRUMBS,
  TREATMENT_GROUPS_ICON_NAME,
  TREATMENTS_ICON_NAME,
} from 'apps/treatments/constants';
import { treatmentsEntity } from 'apps/treatments/models/treatment';
import { userCanPublishDdb } from 'utils/userGroups';

export function ExportHomePage() {
  const { activeTenant, activeEnvironment } = useSelector((s) => s.portal);
  const [isLoading, setIsLoading] = useState(false);

  const exportFile = (source) => {
    if (isLoading) return;

    setIsLoading(true);
    let url;
    let fileName;
    let fileType;
    if (source === 'treatments') {
      url = `${BACKEND_EXPORT_TREATMENTS_URL}/${activeTenant.codename}/${activeEnvironment.codename}/`;
      fileName = `ecommerce_treatments_${activeTenant.codename}.csv`;
      fileType = 'text/csv;charset=utf-8';
    }
    if (source === 'collections') {
      url = `${BACKEND_EXPORT_COLLECTIONS_URL}/${activeTenant.codename}/${activeEnvironment.codename}/`;
      fileName = `treatment-collections-${activeTenant.codename}.json`;
      fileType = 'text/json;charset=utf-8';
    }
    if (source === 'treatments-links') {
      url = `${BACKEND_EXPORT_TREATMENTS_LINKS_URL}/${activeTenant.codename}/${activeEnvironment.codename}/`;
      fileName = `ecommerce_treatments_links_${activeTenant.codename}.csv`;
      fileType = 'text/csv;charset=utf-8';
    }
    getRequest(url, { responseType: 'text' }).then((r) => {
      const blob = new Blob([r], { type: fileType });
      saveAs(blob, fileName);
      setIsLoading(false);
    });
  };

  const publishToDdb = (source, environment) => {
    if (!userCanPublishDdb(environment) || isLoading) return;

    const confirmed = window.confirm(
      `Are you sure? All ${beautify(source)} will be published to ${beautify(environment)} - ${
        activeTenant.name
      }.`
    );

    if (!confirmed) return;

    setIsLoading(true);
    treatmentsEntity
      .publishToDashDdb(source, environment, activeTenant.codename)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        alert(`An error occurred while publishing ${beautify(source)}.`);
        console.error(error);
      });
  };

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={TREATMENTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Export</h2>
        </>
      }
    >
      <Block outstanding={true}>
        <TileLink
          id="export-treatments"
          size="big"
          name="Treatments"
          icon={isLoading ? 'access_time' : TREATMENTS_ICON_NAME}
          onClick={() => exportFile('treatments')}
          getPath={() => '#'}
          description={`Treatments CSV - ${activeTenant.name} - ${activeEnvironment.name}`}
        />
        <TileLink
          id="export-collections"
          size="big"
          name="Treatment Collections"
          icon={isLoading ? 'access_time' : TREATMENT_GROUPS_ICON_NAME}
          onClick={() => exportFile('collections')}
          getPath={() => '#'}
          description={`Collections JSON - ${activeTenant.name} - ${activeEnvironment.name}`}
        />
        <TileLink
          id="export-treatments-links"
          size="big"
          name="Treatments Links"
          icon={isLoading ? 'access_time' : 'share'}
          onClick={() => exportFile('treatments-links')}
          getPath={() => '#'}
          description={`Treatments Links CSV - ${activeTenant.name} - ${activeEnvironment.name}`}
        />
      </Block>
      {userCanPublishDdb() && (
        <>
          <Title tag="h3">Publish to Dash DDB</Title>
          <Block outstanding={true}>
            {userCanPublishDdb('staging') && (
              <>
                <TileLink
                  id="publish-treatments-ddb"
                  size="big"
                  name="Publish Treatments"
                  icon={isLoading ? 'access_time' : TREATMENTS_ICON_NAME}
                  onClick={() => publishToDdb('treatments', 'staging')}
                  getPath={() => '#'}
                  description={`Publish Treatments to Staging - ${activeTenant.name}`}
                />
                <TileLink
                  id="publish-collections-ddb"
                  size="big"
                  name="Publish Collections"
                  icon={isLoading ? 'access_time' : TREATMENT_GROUPS_ICON_NAME}
                  onClick={() => publishToDdb('collections', 'staging')}
                  getPath={() => '#'}
                  description={`Publish Collections to Staging - ${activeTenant.name}`}
                />
              </>
            )}
            {userCanPublishDdb('production') && (
              <>
                <TileLink
                  id="publish-treatments-ddb"
                  size="big"
                  name="Publish Treatments"
                  icon={isLoading ? 'access_time' : TREATMENTS_ICON_NAME}
                  onClick={() => publishToDdb('treatments', 'production')}
                  getPath={() => '#'}
                  description={`Publish Treatments to Production - ${activeTenant.name}`}
                />
                <TileLink
                  id="publish-collections-ddb"
                  size="big"
                  name="Publish Collections"
                  icon={isLoading ? 'access_time' : TREATMENT_GROUPS_ICON_NAME}
                  onClick={() => publishToDdb('collections', 'production')}
                  getPath={() => '#'}
                  description={`Publish Collections to Production - ${activeTenant.name}`}
                />
              </>
            )}
          </Block>
        </>
      )}
    </Page>
  );
}
