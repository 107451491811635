import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';

import { setConfig, Loader } from '@borrow-ui/ui';

import { SET_APPLICATION_INITIALIZED } from 'store/actions';

import { authenticationService } from './libs/authentication/authentication';
import { reducers as authReducers } from './libs/authentication/reducers';
import { reducers as portalReducers } from 'apps/portal/reducers';
import { reducers as productsReducers } from 'apps/products/reducers';
import { reducers as treatmentsReducers } from 'apps/treatments/reducers';

import { Login } from 'components/authentication/Login';

import { store } from './store/store';

import './App.scss';
import { Dashboard } from './Dashboard';

store.registerReducer('authentication', authReducers.authentication);
store.registerReducer('portal', portalReducers.portal);
store.registerReducer('products', productsReducers.products);
store.registerReducer('treatments', treatmentsReducers.treatments);

setConfig('getLocation', useLocation);
setConfig('getLinkComponent', () => Link);

export default function App() {
  const authentication = useSelector((s) => s.authentication);

  useEffect(() => {
    store.dispatch({ type: SET_APPLICATION_INITIALIZED, initialized: true });

    // Try to auto login based on localStorage
    authenticationService.storageAutoLogin();
  }, []); // eslint-disable-line

  if (!authentication.loggedIn && !authentication.storageLoginAttempt)
    return (
      <div className="flex-center-center h-100vh">
        <Loader />
      </div>
    );

  if (!authentication.loggedIn) {
    return <Login />;
  }

  return (
    <>
      <Dashboard />
      <ToastContainer
        className="toast-container"
        transition={Slide}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
    </>
  );
}

App.propTypes = {
  autoLogin: PropTypes.bool,
};
