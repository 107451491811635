export const DASH_BASE_URLS = {
  staging: {
    roi: process.env.REACT_APP_DASH_STAGING_ROI_BASE_URL,
    uk: process.env.REACT_APP_DASH_STAGING_UK_BASE_URL,
    us: process.env.REACT_APP_DASH_STAGING_US_BASE_URL,
  },
  production: {
    roi: process.env.REACT_APP_DASH_PRODUCTION_ROI_BASE_URL,
    uk: process.env.REACT_APP_DASH_PRODUCTION_UK_BASE_URL,
    us: process.env.REACT_APP_DASH_PRODUCTION_US_BASE_URL,
  },
};

export const DASH_REVALIDATE_TOKENS = {
  staging: {
    roi: process.env.REACT_APP_DASH_ROI_REVALIDATE_TOKEN,
    uk: process.env.REACT_APP_DASH_UK_REVALIDATE_TOKEN,
    us: process.env.REACT_APP_DASH_US_REVALIDATE_TOKEN,
  },
  production: {
    roi: process.env.REACT_APP_DASH_ROI_REVALIDATE_TOKEN,
    uk: process.env.REACT_APP_DASH_UK_REVALIDATE_TOKEN,
    us: process.env.REACT_APP_DASH_US_REVALIDATE_TOKEN,
  },
};

export const getDashRevalidateUrl = (environment, tenant) =>
  `${DASH_BASE_URLS[environment][tenant]}/api/data/revalidate`;

export const getDashRevalidateToken = (environment, tenant) =>
  DASH_REVALIDATE_TOKENS[environment][tenant];
