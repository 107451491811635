import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Block, Breadcrumbs, Modal, Page, TileLink } from '@borrow-ui/ui';
import { PRODUCTS_BREADCRUMBS } from 'apps/products/constants';
import { beautify } from 'utils/strings';
import { userCanManageProducts } from 'utils/userGroups';
import { productsEntity, PRODUCT_INGESTION_TASK_NAME } from 'apps/products/models/product';
import { isTaskFailedOrCompleted } from 'utils/asyncTask';
import { TASK_STATUS_COLORS } from 'constants/asyncTask.constants';

export const ProductsIngestHomePage = () => {
  if (!userCanManageProducts()) {
    return <div>You do not have permission to access this page.</div>;
  }

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PRODUCTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Phorest Ingestion</h2>
        </>
      }
    >
      <Block outstanding={true}>
        <ProductIngestModal environment="staging" />
        <ProductIngestModal environment="production" />
      </Block>
    </Page>
  );
};

const ProductIngestModal = ({ environment }) => {
  const { activeTenant } = useSelector((s) => s.portal);
  const [taskId, setTaskId] = useState(
    window.localStorage.getItem(getLsKey(environment, activeTenant.name))
  );
  const [taskData, setTaskData] = useState({});
  const pollingRef = useRef();

  const onOpen = async ({ resolve, reject }) => {
    // on open, check if LS has taskId
    let _taskId = taskId;
    try {
      if (!taskId) {
        // if taskId not in LS, create new task
        const { id } = await productsEntity.createIngestionTask();
        _taskId = id;
        // update taskId in state
        setTaskId(_taskId);
        // save taskId to LS
        window.localStorage.setItem(getLsKey(environment, activeTenant.name), _taskId);
        // start the ingestion
        productsEntity.startIngestionTask(environment, activeTenant.codename, _taskId);
      }
      // set up polling of task
      pollingRef.current = setInterval(async () => {
        const task = await productsEntity.getIngestionTask(_taskId);
        setTaskData(task);
        resolve();
        if (isTaskFailedOrCompleted(task)) {
          window.localStorage.removeItem(getLsKey(environment, activeTenant.name));
          setTaskId(null);
          clearInterval(pollingRef.current);
        }
      }, 1000);
    } catch (e) {
      clearInterval(pollingRef.current);
      reject();
    }
  };

  const onClose = () => {
    clearInterval(pollingRef.current);
  };

  return (
    <Modal
      Trigger={({ setVisible }) => (
        <TileLink
          id={`ingest-products-${environment}`}
          size="big"
          name="Ingest Products"
          icon="cloud_download"
          onClick={() => {
            if (!userCanManageProducts()) {
              return alert('You do not have permission to perform this task');
            }
            if (!taskId) {
              const confirmed = window.confirm(
                `Are you sure? This will start the ingestion of Products from Phorest to ${beautify(
                  environment
                )} - ${activeTenant.name}`
              );
              if (!confirmed) return;
            }

            setVisible(true);
          }}
          getPath={() => '#'}
          description={
            <>
              Ingest Products from Phorest <b>{beautify(environment)}</b> -{' '}
              <b>{activeTenant.name}</b>
            </>
          }
        />
      )}
      getModalWindowProps={() => ({
        title: `Ingesting Products from Phorest to ${beautify(environment)} - ${activeTenant.name}`,
        content: (
          <div>
            <div>
              Status:{' '}
              <Badge color={TASK_STATUS_COLORS[taskData.status]}>
                {taskData.status?.toUpperCase()}
              </Badge>
            </div>
            {/** UI-TODO: make it pretty */}
            <pre>{JSON.stringify(JSON.parse(taskData.data_json || '{}'), null, 2)}</pre>
          </div>
        ),
        startHeight: '80%',
        hooks: {
          onOpen,
          onClose,
        },
      })}
    />
  );
};

function getLsKey(environment, tenant) {
  return `${PRODUCT_INGESTION_TASK_NAME}_${environment.toUpperCase()}-${tenant.toUpperCase()}`;
}
