import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Forms, Loader, Button, Row, Col, Block, Icon, Title } from '@borrow-ui/ui';

import { FormsErrors } from 'components/common/FormErrors';
import { beautify } from 'utils/strings';
import { newTreatment, treatmentsEntity } from 'apps/treatments/models/treatment';
import { createSelectOptionsFromStore } from 'utils/select';
import { useTaxRate } from 'apps/portal/hooks/useTaxRate';

const { Field, Input, Textarea, Select } = Forms;

const statusesOptions = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'LIVE', label: 'Live' },
];
const treatmentTypesOptions = [
  { value: 'SERVICE', label: 'Service' },
  { value: 'COURSE', label: 'Course' },
];

const requiredFields = ['crm_id', 'title', 'crm_title', 'price', 'number_of_sessions'];

export function TreatmentForm({ treatment, onSubmitSuccess, onCancel }) {
  const { activeTenant, tenants } = useSelector((s) => s.portal);
  const { user } = useSelector((s) => s.authentication);
  const { treatments, treatmentGroups } = useSelector((s) => s.treatments);
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [data, setData] = useState(treatment || newTreatment());
  const filterByTenant = (list) => list.filter((v) => v._entry.tenant === activeTenant.id);
  const treatmentsOptions = filterByTenant(
    createSelectOptionsFromStore(treatments, { labelAttribute: 'crm_title' })
  );
  const treatmentGroupsOptions = filterByTenant(createSelectOptionsFromStore(treatmentGroups));
  const taxRate = useTaxRate({ id: treatment?.tax_rate });

  const canEdit = user.groups.includes('treatments_managers');

  const onSubmit = (e) => {
    e.preventDefault();
    const message = { errors: {} };
    setSubmitMessage(null);

    for (let requiredField of requiredFields) {
      if (!data[requiredField])
        message.errors[requiredField] = `${beautify(requiredField)} is required`;
    }

    const price = parseFloat(data.price);
    if (isNaN(price)) message.errors.price = `Price should be a number`;
    const compare_price = data.compare_price ? parseFloat(data.compare_price) : null;
    if (compare_price !== null && isNaN(compare_price))
      message.errors.compare_price = `Compare Price should be a number`;
    const tax_rates = data.tax_rates ? parseFloat(data.tax_rates) : null;
    if (tax_rates !== null && isNaN(tax_rates))
      message.errors.tax_rates = `Tax Rates should be a number`;
    const duration = data.duration ? parseInt(data.duration) : null;
    if (duration !== null && isNaN(duration))
      message.errors.duration = `Duration should be a number`;
    const number_of_sessions = data.number_of_sessions ? parseInt(data.number_of_sessions) : null;
    if (number_of_sessions !== null && isNaN(number_of_sessions))
      message.errors.number_of_sessions = `Number of sessions should be a number`;
    const number_of_cycles = data.number_of_cycles ? parseInt(data.number_of_cycles) : null;
    if (number_of_cycles !== null && isNaN(number_of_cycles))
      message.errors.number_of_cycles = `Number of cycles should be a number`;

    if (Object.values(message.errors).length > 0) {
      setSubmitMessage(message);
      return;
    }

    const toSave = {
      ...data,
      price: price.toFixed(2),
      compare_price: compare_price ? compare_price.toFixed(2) : null,
      tax_rates: tax_rates ? tax_rates.toFixed(2) : null,
      duration: duration ? duration : null,
      number_of_sessions: number_of_sessions ? number_of_sessions : null,
      number_of_cycles: number_of_cycles ? number_of_cycles : null,
      tenant: treatment ? treatment.tenant : activeTenant.id,
    };

    setLoading(true);
    treatmentsEntity
      .save(toSave)
      .then((saved) => {
        setLoading(false);
        onSubmitSuccess && onSubmitSuccess(saved);
      })
      .catch(() => setLoading(false));
  };

  const { errors } = submitMessage || { errors: {} };

  if (!canEdit) {
    const message = "You don't have enough permissions to edit this treatment.";
    return <div>{message}</div>;
  }

  return (
    <form onSubmit={onSubmit}>
      <FormsErrors submitMessage={submitMessage} />
      <Block outstanding={true}>
        <Row>
          <Col>
            <Field label="Title">
              <Input
                invalid={!!errors.title}
                disabled={loading}
                onChange={(e) => setData({ ...data, title: e.target.value })}
                value={data.title}
              />
            </Field>
          </Col>
          <Col>
            <Field label="Status">
              <div className="w-200">
                <Select
                  disabled={loading}
                  options={statusesOptions}
                  onChange={(entry) => setData({ ...data, status: entry.value })}
                  value={data.status}
                />
              </div>
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field label="Ecommerce ID">
              <Input
                invalid={!!errors.ecomm_id}
                disabled={loading}
                onChange={(e) => setData({ ...data, ecomm_id: e.target.value })}
                value={data.ecomm_id}
              />
            </Field>
          </Col>
          <Col>
            <Field label="Tenant">
              {treatment ? tenants[treatment.tenant].name : activeTenant.name}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field label="Slug">
              <Input
                invalid={!!errors.slug}
                disabled={loading}
                onChange={(e) => setData({ ...data, slug: e.target.value })}
                value={data.slug}
              />
            </Field>
          </Col>
          <Col>
            <Field label="NUA Slug">
              <Input
                invalid={!!errors.nua_slug}
                disabled={loading}
                onChange={(e) => setData({ ...data, nua_slug: e.target.value })}
                value={data.nua_slug}
              />
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field label="Image - Desktop">
              <Input
                invalid={!!errors.image_original}
                disabled={loading}
                onChange={(e) => setData({ ...data, image_original: e.target.value })}
                value={data.image_original || ''}
                placeholder="Image name or full URL, i.e. LHR-Underarms-desktop.png or https://..."
              />
            </Field>
          </Col>
          <Col>
            <Field label="Image - Mobile">
              <Input
                invalid={!!errors.image_thumbnail}
                disabled={loading}
                onChange={(e) => setData({ ...data, image_thumbnail: e.target.value })}
                value={data.image_thumbnail || ''}
                placeholder="Image name or full URL, i.e. LHR-Underarms-mobile.png or https://..."
              />
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <Field label="Price">
                  <Input
                    className="w-100"
                    invalid={!!errors.price}
                    disabled={loading}
                    onChange={(e) => setData({ ...data, price: e.target.value })}
                    value={data.price}
                  />
                </Field>
              </Col>
              <Col>
                <Field label="Compare Price">
                  <Input
                    className="w-100"
                    invalid={!!errors.compare_price}
                    disabled={loading}
                    onChange={(e) => setData({ ...data, compare_price: e.target.value })}
                    value={data.compare_price || ''}
                  />
                </Field>
              </Col>
              <Col>
                <Field label="Tax rate">
                  <Input className="w-100" value={taxRate.tax_rate || ''} disabled />
                </Field>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <Field label="Cycles">
                  <Input
                    className="w-70"
                    invalid={!!errors.number_of_cycles}
                    disabled={loading}
                    onChange={(e) => setData({ ...data, number_of_cycles: e.target.value })}
                    value={data.number_of_cycles}
                  />
                </Field>
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            {treatment && treatment.treatment_type === 'SERVICE' && (
              <Field label="Book As Treatment">
                <div className="w-100pc">
                  <Select
                    disabled={loading}
                    options={treatmentsOptions}
                    onChange={(entry) =>
                      setData({ ...data, book_as_treatment: entry ? entry.value : null })
                    }
                    value={data.book_as_treatment}
                  />
                </div>
              </Field>
            )}
          </Col>
          <Col>
            <Field label="Treatment Group (override)">
              <div className="w-100pc">
                <Select
                  disabled={loading}
                  options={treatmentGroupsOptions}
                  onChange={(entry) =>
                    setData({ ...data, treatment_group: entry ? entry.value : null })
                  }
                  value={data.treatment_group}
                />
              </div>
            </Field>
          </Col>
        </Row>

        <Field label="Description">
          <Textarea
            disabled={loading}
            onChange={(e) => setData({ ...data, description: e.target.value })}
            value={data.description || ''}
          />
        </Field>
      </Block>
      <Block>
        <Title tag="h3" className="color-warning">
          <Icon name="warning" className="m-r-10" />
          Warning - These information should come from Phorest CRM
        </Title>
        <Row>
          <Col>
            <Row>
              <Col>
                <Field label="CRM ID">
                  <div style={{ fontSize: '10px' }}>{treatment.crm_id}</div>
                </Field>
              </Col>
              <Col>
                <Field label="CRM Status">{treatment ? treatment.crm_status : ''}</Field>
              </Col>
            </Row>
          </Col>
          <Col>
            <Field label="CRM Title">
              <div style={{ fontSize: '10px' }}>{treatment.crm_title}</div>
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <Field label="Treatment type">
                  <div className="w-200">
                    <Select
                      disabled={loading}
                      options={treatmentTypesOptions}
                      onChange={(entry) => setData({ ...data, treatment_type: entry.value })}
                      value={data.treatment_type}
                    />
                  </div>
                </Field>
              </Col>
              <Col>
                <Field label="Tax rates">{treatment.tax_rates}</Field>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <Field label="Sessions">{treatment.number_of_sessions}</Field>
              </Col>
              <Col>
                <Field label="Duration">{treatment.duration}</Field>
              </Col>
            </Row>
          </Col>
        </Row>
        {treatment && treatment.course_services.length > 0 && (
          <div>
            <Field label="Course Services (what the client will book)">
              <ul>
                {treatment.course_services.map((cs) => {
                  const cst = treatments[cs];
                  return (
                    <li key={cs} className="flex-start-center">
                      <span className="m-r-15">{cst.crm_title}</span>
                      <span className="color-neutral">{cst.crm_id}</span>
                    </li>
                  );
                })}
              </ul>
            </Field>
          </div>
        )}
        <div style={{ fontSize: 10 }}>Internal ID: {treatment.id}</div>
      </Block>
      <div className="flex-end-center">
        {loading && (
          <span className="m-r-10">
            <Loader type="inline" />
          </span>
        )}
        <Button type="submit" mean="positive" disabled={loading}>
          Save
        </Button>
        <Button onClick={onCancel} disabled={loading}>
          Close
        </Button>
      </div>
    </form>
  );
}

TreatmentForm.propTypes = {
  treatment: PropTypes.object,
  treatments: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};
