import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { TREATMENTS_TREATMENT_GROUPS_BASE_URL } from 'apps/treatments/constants';
import { TreatmentGroupsHomePage } from './TreatmentGroupsHomePage';
import { TreatmentGroupDetailPage } from './TreatmentGroupDetailPage';
import { EcommerceCategoryPreviewPage } from './EcommerceCategoryPreviewPage';

export { TREATMENTS_TREATMENT_GROUPS_BASE_URL };

export function TreatmentGroupsRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={`${TREATMENTS_TREATMENT_GROUPS_BASE_URL}`}
        component={TreatmentGroupsHomePage}
      />
      <Route
        exact
        path={`${TREATMENTS_TREATMENT_GROUPS_BASE_URL}/:id/ecommerce-categories/:categoryId/preview`}
        component={EcommerceCategoryPreviewPage}
      />
      <Route
        exact
        path={`${TREATMENTS_TREATMENT_GROUPS_BASE_URL}/:id`}
        component={TreatmentGroupDetailPage}
      />
    </Switch>
  );
}
