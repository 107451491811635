import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { authenticationService } from 'libs/authentication/authentication';

import { Block, Icon, Text, Title } from '@borrow-ui/ui';

import { LoginForm } from './LoginForm';
import './login.scss';

export function Login() {
  const authentication = useSelector((s) => s.authentication);

  const [state, setState] = useState({
    username: '',
    password: '',
    isLoading: false,
    error: null,
  });
  const { isLoading, username, password, error } = state;

  const updateField = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const login = () => {
    const { username, password } = state;
    setState({ ...state, isLoading: true, error: false });

    setTimeout(() => {
      authenticationService
        .requestAuthentication(username, password)
        .then(() => {
          setState({ ...state, isLoading: false });
        })
        .catch((exception) => {
          exception.errorData.json().then((errorData) => {
            setState({ ...state, isLoading: false, error: errorData });
          });
        });
    }, 1000);
  };

  if (authentication.loggedIn) {
    return (
      <div>
        <div>You are logged in.</div>
        <div>
          <button onClick={authenticationService.logout}>Logout</button>
        </div>
      </div>
    );
  }

  return (
    <div className="login__container">
      {/* eslint-disable-next-line */}
      <Block
        outstanding={true}
        padded={false}
        contentCentered={true}
        className="login__content-container"
      >
        <div className="login__inner-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <LoginForm
              username={username}
              password={password}
              isLoading={isLoading}
              login={login}
              updateField={updateField}
              error={error}
            />
          </form>
        </div>
        <div className="login__message__container">
          <div className="login__message">
            <div className="login__message__line login__message__line--center">
              <Title className="login__message__title">Dash Quick Admin</Title>
            </div>
            <div className="login__message__line login__message__line--center login__message__line--icon">
              <Icon name="lock_outline" size="huge" />
            </div>
            <div className="login__message__line login__message__line--center">
              <Text size="big">
                You need to login in order to use Dash Quick Admin functionalities.
              </Text>
            </div>
            <div className="login__message__line">
              <Text size="small">
                This application allows to set the treatments grouping for Dash - Therapie Ecommerce
                <br />
                It is based on
                <a
                  href="https://www.djangoproject.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="login__link"
                >
                  Django
                </a>
                ,
                <a
                  href="https://reactjs.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="login__link"
                >
                  React
                </a>
                and
                <a
                  href="https://www.borrow-ui.dev/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="login__link"
                >
                  borrow-ui
                </a>
                .
              </Text>
            </div>
          </div>
        </div>
      </Block>
    </div>
  );
}
