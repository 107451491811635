import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Title, Forms, Modal, Button, IconControl } from '@borrow-ui/ui';

import { Image } from 'components/common/Image';
import { TREATMENTS_TREATMENT_GROUPS_BASE_URL } from 'apps/treatments/constants';
import { ecommerceCategoriesEntity } from 'apps/treatments/models/ecommerceCategory';
import { EcommerceCategoryForm } from '../ecommerceCategory/EcommerceCategoryForm';
import { EcommerceCategoryUpdateTreatmentsImagesForm } from '../ecommerceCategory/EcommerceCategoryUpdateTreatmentsImagesForm';
import { EcommerceCategoryPropType } from './TGBPropTypes';

const { Field } = Forms;

export function TGBEcommerceCategory({ ecommerceCategory, sections, reload }) {
  const { user } = useSelector((s) => s.authentication);
  const userCanEdit = user.groups.includes('treatments_managers');

  const deleteCategory = async () => {
    const proceed = window.confirm('Are you sure you want to delete this category?');
    if (!proceed) return;

    await ecommerceCategoriesEntity.delete(ecommerceCategory.id);
    reload();
  };

  return (
    <div className="tgb__ecommerce-category">
      <div className="tgb__ecommerce-category__header">
        <Title tag="h3" className="m-0">
          {ecommerceCategory.name}
        </Title>
        {userCanEdit && (
          <div className="tgb__ecommerce-category__controls">
            <Modal
              Trigger={({ setVisible }) => {
                return <IconControl name="edit" onClick={() => setVisible(true)} />;
              }}
              getModalWindowProps={({ setVisible }) => ({
                title: `Edit ${ecommerceCategory.name} `,
                canMaximize: true,
                maximized: true,
                content: (
                  <EcommerceCategoryForm
                    ecommerceCategory={ecommerceCategory}
                    onCancel={() => setVisible(false)}
                    onSubmitSuccess={() => {
                      reload();
                    }}
                  />
                ),
              })}
            />
            <IconControl name="delete" onClick={deleteCategory} />
          </div>
        )}
      </div>
      <DetailField label="Title">{ecommerceCategory.title}</DetailField>
      <DetailField label="Slug">{ecommerceCategory.slug}</DetailField>
      <DetailField label="Status">{ecommerceCategory.status}</DetailField>
      <DetailField label="Grouped Sections">
        {ecommerceCategory.sections ? 'Yes' : 'No'}
      </DetailField>
      <DetailField label="Description">
        <div className="w-400">{ecommerceCategory.description}</div>
      </DetailField>
      <div className="flex-center-center" style={{ gap: 20 }}>
        <Image src={ecommerceCategory.image_original} />
        <Image src={ecommerceCategory.image_thumbnail} />
      </div>
      <DetailField label="Cover">
        <div className="flex-center-center" style={{ gap: 20 }}>
          <Image src={ecommerceCategory.catalog_cover} />
        </div>
      </DetailField>
      <div className="flex-center-center m-20">
        <Button
          mean="primary-reverse"
          size="small"
          tag={Link}
          to={`${TREATMENTS_TREATMENT_GROUPS_BASE_URL}/${ecommerceCategory.treatment_group}/ecommerce-categories/${ecommerceCategory.id}/preview`}
          target="_blank"
        >
          Preview
        </Button>
        {userCanEdit && (
          <Modal
            Trigger={({ setVisible }) => {
              return (
                <Button mean="primary-reverse" onClick={() => setVisible(true)}>
                  Batch update images
                </Button>
              );
            }}
            getModalWindowProps={({ setVisible }) => ({
              title: `Batch update images`,
              startWidth: 500,
              startHeight: 300,
              content: (
                <EcommerceCategoryUpdateTreatmentsImagesForm
                  ecommerceCategory={ecommerceCategory}
                  onCancel={() => setVisible(false)}
                  onSubmitSuccess={() => {
                    reload();
                  }}
                />
              ),
            })}
          />
        )}
      </div>
    </div>
  );
}

TGBEcommerceCategory.propTypes = {
  ecommerceCategory: EcommerceCategoryPropType,
  sections: PropTypes.array,
  reload: PropTypes.func,
};

function DetailField({ label, children }) {
  return (
    <Field className="tgb__detail-field" label={label} layout="horizontal" labelWidth={90}>
      {children}
    </Field>
  );
}

DetailField.propTypes = { label: PropTypes.string, children: PropTypes.any };
