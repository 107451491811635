import PropTypes from 'prop-types';

export const DataPropType = PropTypes.shape({
  ecommerceCategoriesIds: PropTypes.arrayOf(PropTypes.number),
  ecommerceSectionsIds: PropTypes.arrayOf(PropTypes.number),
});

export const EcommerceCategoryPropType = PropTypes.shape({
  name: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string,
  image_original: PropTypes.string,
  image_thumbnail: PropTypes.string,
});

export const EcommerceSectionPropType = PropTypes.shape({
  id: PropTypes.number,
  order: PropTypes.number,
  title: PropTypes.string,
  items: PropTypes.array,
});

export const EcommerceSubsectionItemPropType = PropTypes.shape({
  id: PropTypes.number,
  order: PropTypes.number,
});

export const EcommerceSubsectionPropType = PropTypes.shape({
  order: PropTypes.number,
  title: PropTypes.string,
  items: PropTypes.arrayOf(EcommerceSubsectionItemPropType),
});
