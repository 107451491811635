import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@borrow-ui/ui';
import { productsEntity } from 'apps/products/models/product';
import { userCanPublishDdb } from 'utils/userGroups';

export const ProductPublishToDdbButton = ({ product }) => {
  const { activeEnvironment, activeTenant } = useSelector((s) => s.portal);
  const [isLoading, setIsLoading] = useState(false);

  if (!userCanPublishDdb(activeEnvironment.codename)) return;

  const onClick = async () => {
    const confirmed = window.confirm(
      `Are you sure? "${product.title}" will be exported to ${activeEnvironment.name} - ${activeTenant.name}.`
    );

    if (!confirmed) return;

    try {
      setIsLoading(true);
      await productsEntity.publishToDashDdb(
        activeEnvironment.codename,
        activeTenant.codename,
        product.id
      );
    } catch (e) {
      alert('An error occurred while exporting this Product to Dash.');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button loading={isLoading} onClick={onClick}>
      Export to Dash
    </Button>
  );
};
