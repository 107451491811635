import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Forms, Button, Loader, Title, Badge, Table } from '@borrow-ui/ui';
import { userLookupEntity } from 'apps/dash/models/userLookup';
import { userCanLookupUser } from 'utils/userGroups';
import { VField, HField } from '@borrow-ui/ui/components/forms/field/Field';
import { YesNo } from 'apps/treatments/components/common/YesNo';
import { sortObjectsListByAttributes } from 'utils/data';
import { formatAddress, getFormattedPrice } from './UserLookupPage.utils';
import styles from './user-lookup-page.module.scss';

const { Input, Field } = Forms;

const VIEW_MODES = {
  NORMAL: 'normal',
  JSON: 'json',
};

export const UserLookupPage = () => {
  const { activeEnvironment, activeTenant } = useSelector((s) => s.portal);

  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODES.NORMAL);

  const { register, handleSubmit } = useForm({
    defaultValues: { phone: '', email: '', crm_id: '' },
  });

  const onSubmit = handleSubmit(async ({ phone, email, crm_id }) => {
    if (!userCanLookupUser()) return;

    setIsLoading(true);

    try {
      const user = await userLookupEntity.search(
        activeEnvironment.codename,
        activeTenant.codename,
        {
          phone,
          email,
          crm_id,
        }
      );
      setUser(user);
    } catch (e) {
      alert('An error occurred while looking up the User.');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  });

  if (!userCanLookupUser()) {
    return <div>You do not have permission to access this page.</div>;
  }

  return (
    <div className={styles['page']}>
      <form onSubmit={onSubmit}>
        <Field label="Phone">
          <Input placeholder="Search by phone..." {...register('phone')} />
        </Field>
        <Field label="Email">
          <Input placeholder="Search by email..." {...register('email')} />
        </Field>
        <Field label="CRM ID">
          <Input placeholder="Search by CRM ID..." {...register('crm_id')} />
        </Field>

        <Button className={styles['search-button']} disabled={isLoading} type="submit">
          Search
        </Button>
      </form>
      <div className={styles['page__result']}>
        <HField label="View Mode" className={styles['view-mode']}>
          <Button
            size="small"
            disabled={viewMode === VIEW_MODES.NORMAL}
            mean={viewMode === VIEW_MODES.NORMAL ? 'primary' : 'primary-reverse'}
            onClick={() => setViewMode(VIEW_MODES.NORMAL)}
          >
            Normal
          </Button>
          <Button
            size="small"
            disabled={viewMode === VIEW_MODES.JSON}
            mean={viewMode === VIEW_MODES.JSON ? 'primary' : 'primary-reverse'}
            onClick={() => setViewMode(VIEW_MODES.JSON)}
          >
            JSON
          </Button>
        </HField>

        <div className={styles['result-container']}>
          {isLoading ? (
            <Loader />
          ) : (
            user &&
            (viewMode === VIEW_MODES.JSON ? (
              <UserLookupResultJson user={user} />
            ) : (
              <UserLookupResult user={user} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const UserLookupResultJson = ({ user }) => {
  return <ReactJson src={user} name={false} collapsed={false} displayDataTypes={false} />;
};

const UserLookupResult = ({ user }) => {
  const { dash_user: dashUser, crm_user: crmUser } = user ?? {};

  return (
    <div className={styles['result']}>
      <DashUserResult {...dashUser} />
      <CrmUserResult user={crmUser} />
    </div>
  );
};

const DashUserResult = ({ user, orders = [] } = { user: {}, orders: [] }) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    crmId,
    id,
    addresses,
    signedUpDate,
    modified_by,
    modified_at = '',
    notificationPreferences = [],
    preferredClinic,
  } = user;

  const sortedOrders = sortObjectsListByAttributes(orders, ['-date']);

  const hasAddresses = addresses?.length > 0;
  const hasNotificationPreferences = notificationPreferences?.length > 0;
  const hasOrders = orders?.length > 0;

  return (
    <>
      <Title>Dash User</Title>
      <div className={styles['result']}>
        <VField label="Name">{firstName || lastName ? `${firstName} ${lastName}` : 'N/A'}</VField>
        <div className={styles['result__row']}>
          <VField label="CRM ID">{crmId || 'N/A'}</VField>
          <VField label="Dash ID">{id || 'N/A'}</VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="Phone Number">{phoneNumber || 'N/A'}</VField>
          <VField label="Email">{email || 'N/A'}</VField>
        </div>
        <VField label="Addresses">
          {hasAddresses ? (
            <ul>
              {addresses?.map((address = {}, index) => (
                <li key={index}>
                  {formatAddress(address)}
                  {address.defaultBilling && <Badge color="primary">Default Billing</Badge>}
                  {address.defaultShipping && <Badge color="primary">Default Shipping</Badge>}
                </li>
              ))}
            </ul>
          ) : (
            'N/A'
          )}
        </VField>
        <div className={styles['result__row']}>
          <VField label="Signup Date">{signedUpDate || 'N/A'}</VField>
          <VField label="Modified at">{modified_at || 'N/A'}</VField>
          <VField label="Modified by">{modified_by || 'N/A'}</VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="Notification Preferences">
            {hasNotificationPreferences ? notificationPreferences?.join(',') : 'N/A'}
          </VField>
          <VField label="Preferred Clinic ID">{preferredClinic || 'N/A'}</VField>
        </div>
        <VField label="Orders" controllerProps={{ style: { display: 'block' } }}>
          {hasOrders ? (
            <Table
              columns={[
                { prop: 'details', title: 'Details' },
                { prop: 'items', title: 'Items' },

                { prop: 'payments', title: 'Payments' },
              ]}
              entries={sortedOrders.map((order) => ({
                details: (
                  <div className={styles['orders__column']}>
                    <div className={styles['orders__column__row']}>
                      <div>ID:</div>
                      {order.orderId}
                    </div>
                    <div className={styles['orders__column__row']}>
                      <div>Date:</div>
                      {order.date}
                    </div>
                    <div className={styles['orders__column__row']}>
                      <div>Payment:</div>
                      {order.paymentStatus}
                    </div>
                    <div className={styles['orders__column__row']}>
                      <div>Fulfillment:</div>
                      {order.fulfillmentStatus}
                    </div>
                    <div className={styles['orders__column__row']}>
                      <div>Billing:</div>
                      <div className={styles['orders__address']}>
                        {formatAddress(order.billingDetails)}
                      </div>
                    </div>
                    <div className={styles['orders__column__row']}>
                      <div>Shipping:</div>
                      <div className={styles['orders__address']}>
                        {formatAddress(order.shippingDetails)}
                      </div>
                    </div>
                  </div>
                ),
                items: (
                  <ul>
                    {order.items?.map((item) => (
                      <li key={item.itemId}>
                        <div className={styles['orders__items']}>
                          <div>
                            <b>{item.name}</b>
                          </div>
                          <div>{item.type}</div>
                          <div>{item.itemId}</div>
                          <div>
                            {item.quantity} x {getFormattedPrice(item.itemCost)} ={' '}
                            {getFormattedPrice(item.totalCost)}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ),
                payments: (
                  <div className={styles['orders__column']}>
                    <div className={styles['orders__column__row']}>
                      <div>Delivery Cost:</div>
                      {getFormattedPrice(order.deliveryCost)}
                    </div>
                    <div className={styles['orders__column__row']}>
                      <div>Total Cost:</div>
                      {getFormattedPrice(order.total)}
                    </div>
                    <div className={styles['orders__payments-seperator']} />
                    <div className={styles['orders__column__row']}>
                      <div>Loyalty Points Payment:</div>
                      {getFormattedPrice(order.loyaltyPointsPayment)}
                    </div>
                    <div className={styles['orders__column__row']}>
                      <div>Voucher Payment:</div>
                      {getFormattedPrice(order.voucherPayment?.amount ?? 0)}
                    </div>
                    <div className={styles['orders__column__row']}>
                      <div>Card Payment:</div>
                      {getFormattedPrice(order.cardPayment)}
                    </div>
                  </div>
                ),
              }))}
            />
          ) : (
            'N/A'
          )}
        </VField>
      </div>
    </>
  );
};

const CrmUserResult = ({ user }) => {
  const {
    firstName,
    lastName,
    gender,
    id,
    mobile,
    email,
    firstVisit,
    clientSince,
    createdAt,
    updatedAt,
    creatingBranchId,
    preferredStaffId,
    notes,
    archived,
    banned,
    deleted,
    emailMarketingConsent,
    emailReminderConsent,
    smsMarketingConsent,
    smsReminderConsent,
  } = user ?? {};
  return (
    <>
      <Title>CRM User</Title>
      <div className={styles['result']}>
        <div className={styles['result__row']}>
          <VField label="Name">{firstName || lastName ? `${firstName} ${lastName}` : 'N/A'}</VField>
          <VField label="Gender">{gender || 'N/A'}</VField>
        </div>
        <VField label="CRM ID">{id || 'N/A'}</VField>
        <div className={styles['result__row']}>
          <VField label="Phone Number">{mobile || 'N/A'}</VField>
          <VField label="Email">{email || 'N/A'}</VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="Client since">{clientSince || 'N/A'}</VField>
          <VField label="First Visit">{firstVisit || 'N/A'}</VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="Created at">{createdAt || 'N/A'}</VField>
          <VField label="Updated at">{updatedAt || 'N/A'}</VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="Creating Branch ID">{creatingBranchId || 'N/A'}</VField>
          <VField label="Preferred Staff ID">{preferredStaffId || 'N/A'}</VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="Notes">{notes || 'N/A'}</VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="Email Marketing Consent">
            <YesNo value={emailMarketingConsent} />
          </VField>
          <VField label="Email Reminder Consent">
            <YesNo value={emailReminderConsent} />
          </VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="SMS Marketing Consent">
            <YesNo value={smsMarketingConsent} />
          </VField>
          <VField label="SMS Reminder Consent">
            <YesNo value={smsReminderConsent} />
          </VField>
        </div>
        <div className={styles['result__row']}>
          <VField label="Archived">
            <YesNo value={archived} />
          </VField>
          <VField label="Banned">
            <YesNo value={banned} />
          </VField>
          <VField label="Deleted">
            <YesNo value={deleted} />
          </VField>
        </div>
      </div>
    </>
  );
};
