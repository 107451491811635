import React from 'react';
import PropTypes from 'prop-types';

import { UI_PREFIX } from '../../config';
import { KEY_CODES } from '../../utils/constants';

import { Icon } from './Icon';

const ICON_CONTROL_CLASS = `${UI_PREFIX}__icon-control`;
const ICON_CONTROL_CLASS_DISABLED = `${UI_PREFIX}__icon-control--disabled`;

export function IconControl({ className = '', disabled, onKeyDown, onClick, ...rest }) {
  const disabledClass = disabled ? ICON_CONTROL_CLASS_DISABLED : '';
  const iconControlClassName = `${className} ${ICON_CONTROL_CLASS} ${disabledClass}`.trim();

  const onKeyDownC = onKeyDown
    ? onKeyDown
    : onClick
    ? (e) => {
        if (e.keyCode === KEY_CODES.SPACEBAR) {
          e.preventDefault();
          e.stopPropagation();
          rest.onClick();
        }
      }
    : undefined;

  return (
    <Icon
      size="smaller"
      className={iconControlClassName}
      onKeyDown={disabled ? () => {} : onKeyDownC}
      onClick={disabled ? () => {} : onClick}
      {...rest}
    />
  );
}

IconControl.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};
