import React from 'react';
import { useSelector } from 'react-redux';

import { Row, Col, TileLink, Title } from '@borrow-ui/ui';

import { TREATMENTS_BASE_URL, TREATMENTS_ICON_NAME } from 'apps/treatments/constants';
import { PORTAL_BASE_URL, PORTAL_ICON_NAME } from 'apps/portal/constants';
import { PRODUCTS_BASE_URL, PRODUCTS_ICON_NAME } from 'apps/products/constants';
import { DASH_USER_LOOKUP_ICON_NAME, DASH_USER_LOOKUP_BASE_URL } from 'apps/dash/constants';
import { userCanLookupUser } from 'utils/userGroups';
import './home-menu.scss';

export function HomeMenu() {
  const { user } = useSelector((s) => s.authentication);

  return (
    <Row className="home-menu">
      <Col>
        <div className="m-b-20">
          <Title>Resources</Title>
          <div className="flex-start-start">
            <TileLink
              id="home-menu--resources--products"
              size="big"
              name="Products"
              icon={PRODUCTS_ICON_NAME}
              path={PRODUCTS_BASE_URL}
              description="Products management"
            />
            <TileLink
              id="home-menu--resources--treatments"
              size="big"
              name="Treatments"
              icon={TREATMENTS_ICON_NAME}
              path={TREATMENTS_BASE_URL}
              description="Treatments Data management"
            />
            {user.groups && user.groups.includes('site_admin') && (
              <TileLink
                id="home-menu--resources--uploader"
                size="big"
                name="Portal"
                icon={PORTAL_ICON_NAME}
                path={PORTAL_BASE_URL}
                description="Portal data"
              />
            )}
            {userCanLookupUser() && (
              <TileLink
                id="home-menu--resources--dash"
                size="big"
                name="User Lookup"
                icon={DASH_USER_LOOKUP_ICON_NAME}
                path={DASH_USER_LOOKUP_BASE_URL}
                description="User Lookup"
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}
