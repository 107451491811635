import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Col, Row, Forms, Icon } from '@borrow-ui/ui';

import { useTaxRate } from 'apps/portal/hooks/useTaxRate';
import { Image } from 'components/common/Image';
import { YesNo } from '../common/YesNo';

import './treatment-detail.scss';

const { VField } = Forms;

export function TreatmentDetail({ treatment }) {
  const { treatments, treatmentGroups } = useSelector((s) => s.treatments);
  const taxRate = useTaxRate({ id: treatment?.tax_rate });
  return (
    <div className="treatment-detail">
      <Row>
        <Col>
          <VField label="Title">{treatment.title}</VField>
        </Col>
        <Col>
          <VField label="CRM Title">{treatment.crm_title}</VField>
        </Col>
      </Row>
      <Row>
        <Col>
          <VField label="ID">{treatment.ecomm_id}</VField>
        </Col>
        <Col>
          <VField label="CRM ID">{treatment.crm_id}</VField>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <VField label="Status">{treatment.status}</VField>
            </Col>
            <Col>
              <VField label="CRM Status">{treatment.crm_status}</VField>
            </Col>
          </Row>
        </Col>
        <Col>
          <VField label="Type">{treatment.treatment_type}</VField>
        </Col>
      </Row>
      <Row>
        <Col>
          <VField label="Slug">{treatment.slug ? treatment.slug : <Warning />}</VField>
        </Col>
        <Col>
          <VField label="NUA Slug">{treatment.nua_slug ? treatment.nua_slug : <Warning />}</VField>
        </Col>
      </Row>
      <Row>
        <Col colClassName="col-xs-12 col-sm-12 col-md-6">
          <VField label="Image - Desktop">
            <Image src={treatment.image_original} />
          </VField>
        </Col>
        <Col colClassName="col-xs-12 col-sm-12 col-md-6">
          <VField label="Image - Mobile">
            <Image src={treatment.image_thumbnail} />
          </VField>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <VField label="Price">{treatment.price}</VField>
            </Col>
            <Col>
              <VField label="Compare Price">{treatment.compare_price}</VField>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <Row>
                <Col>
                  <VField label="Tax Rates">
                    {taxRate.tax_rate} {taxRate.name ? `(${taxRate.name})` : null}
                  </VField>
                </Col>
                <Col>
                  <VField label="Price with Tax">{treatment.price_with_tax}</VField>
                </Col>
              </Row>
            </Col>
            <Col>
              <VField label="Price without Tax">{treatment.price_without_tax}</VField>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <VField label="CRM Category">{treatment.category}</VField>
        </Col>
        <Col>
          <VField label="Available online">
            <YesNo value={treatment.available_online} />
          </VField>
        </Col>
      </Row>
      <VField label="On Sale">
        <YesNo value={treatment.on_sale} />
      </VField>
      <Row>
        <Col>
          <Row>
            <Col>
              <Row>
                <Col>
                  <VField label="Sessions">{treatment.number_of_sessions}</VField>
                </Col>
                <Col>
                  <VField label="Cycles">{treatment.number_of_cycles}</VField>
                </Col>
              </Row>
            </Col>
            <Col>
              <VField label="Duration">{treatment.duration}</VField>
            </Col>
          </Row>
        </Col>
      </Row>
      {treatment.treatment_type === 'COURSE' && (
        <VField label="Course Services (what the client will book)">
          {treatment.course_services.length === 0 && 'This service'}
          {treatment.course_services.length > 0 && (
            <div>
              <ul>
                {treatment.course_services.map((cs) => {
                  const cst = treatments[cs];
                  return (
                    <li key={cs} className="treatment-detail__course-service">
                      <span>{cst.crm_title}</span>
                      <span className="color-neutral">{cst.crm_id}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </VField>
      )}
      <Row>
        <Col>
          {treatment.treatment_type === 'SERVICE' && treatment.book_as_treatment && (
            <VField label="Book Treatment As">
              {treatments[treatment.book_as_treatment].title}
            </VField>
          )}
        </Col>
        <Col>
          <VField label="Treatment Group (override)">
            {treatment.treatment_group ? treatmentGroups[treatment.treatment_group].name : ''}
          </VField>
        </Col>
      </Row>
    </div>
  );
}

TreatmentDetail.propTypes = {
  treatment: PropTypes.any,
};

function Warning() {
  return <Icon name="warning" className="color-warning" />;
}
