import { listToObject } from 'utils/data';

import * as actions from './actions';

const INITIAL_STATE = {
  initialized: false,
  initializedTenants: {},
  treatments: {},
  treatmentGroups: {},
  ecommerceCategories: {},
  ecommerceSections: {},
  ecommerceSubsections: {},
  loadedArchived: false,
};

const treatmentsReducers = (currentState, action) => {
  const state = currentState ? currentState : INITIAL_STATE;

  let treatments;
  let treatmentGroups;
  let ecommerceCategories;
  let ecommerceSections;
  let ecommerceSubsections;

  switch (action.type) {
    case actions.INITIALIZE:
      return { ...state, initialized: true };

    case actions.INITIALIZE_TENANT:
      return {
        ...state,
        initializedTenants: { ...state.initializedTenants, [action.tenantId]: true },
      };

    case actions.SET_TREATMENT_GROUPS:
      treatmentGroups = listToObject(action.data, 'id');
      return { ...state, treatmentGroups };
    case actions.SET_TREATMENT_GROUP:
      const treatmentGroup = action.data;
      treatmentGroups = { ...state.treatmentGroups };
      treatmentGroups[treatmentGroup.id] = treatmentGroup;
      return { ...state, treatmentGroups };
    case actions.DELETE_TREATMENT_GROUP:
      treatmentGroups = { ...state.treatmentGroups };
      if (treatmentGroups.hasOwnProperty(action.id)) delete treatmentGroups[action.id];
      return { ...state, treatmentGroups };

    case actions.SET_ECOMMERCE_CATEGORIES:
      ecommerceCategories = listToObject(action.data, 'id');
      return { ...state, ecommerceCategories };
    case actions.SET_ECOMMERCE_CATEGORY:
      const ecommerceCategory = action.data;
      ecommerceCategories = { ...state.ecommerceCategories };
      ecommerceCategories[ecommerceCategory.id] = ecommerceCategory;
      return { ...state, ecommerceCategories };
    case actions.DELETE_ECOMMERCE_CATEGORY:
      ecommerceCategories = { ...state.ecommerceCategories };
      if (ecommerceCategories.hasOwnProperty(action.id)) delete ecommerceCategories[action.id];
      return { ...state, ecommerceCategories };

    case actions.SET_ECOMMERCE_SECTIONS:
      ecommerceSections = listToObject(action.data, 'id');
      return { ...state, ecommerceSections };
    case actions.SET_ECOMMERCE_SECTION:
      const ecommerceSection = action.data;
      ecommerceSections = { ...state.ecommerceSections };
      ecommerceSections[ecommerceSection.id] = ecommerceSection;
      return { ...state, ecommerceSections };
    case actions.DELETE_ECOMMERCE_SECTION:
      ecommerceSections = { ...state.ecommerceSections };
      if (ecommerceSections.hasOwnProperty(action.id)) delete ecommerceSections[action.id];
      return { ...state, ecommerceSections };

    case actions.SET_ECOMMERCE_SUBSECTIONS:
      ecommerceSubsections = listToObject(action.data, 'id');
      return { ...state, ecommerceSubsections };
    case actions.SET_ECOMMERCE_SUBSECTION:
      const ecommerceSubsection = action.data;
      ecommerceSubsections = { ...state.ecommerceSubsections };
      ecommerceSubsections[ecommerceSubsection.id] = ecommerceSubsection;
      return { ...state, ecommerceSubsections };
    case actions.DELETE_ECOMMERCE_SUBSECTION:
      ecommerceSubsections = { ...state.ecommerceSubsections };
      if (ecommerceSubsections.hasOwnProperty(action.id)) delete ecommerceSubsections[action.id];
      return { ...state, ecommerceSubsections };

    case actions.SET_TREATMENTS:
      treatments = listToObject(action.data, 'id');
      return { ...state, treatments };
    case actions.SET_TREATMENT:
      const treatment = action.data;
      treatments = { ...state.treatments };
      treatments[treatment.id] = treatment;
      return { ...state, treatments };

    case actions.SET_LOADED_ARCHIVED:
      return { ...state, loadedArchived: true };
    default:
      return state;
  }
};

export const reducers = {
  treatments: treatmentsReducers,
};
