import React from 'react';
import PropTypes from 'prop-types';

import { Forms } from '@borrow-ui/ui';

const { Field } = Forms;

export function TreatmentGroupDetail({ treatmentGroup, tenants }) {
  const tenant = tenants[treatmentGroup.tenant];
  return (
    <div>
      <div className="flex-start-center">
        <img
          src={`/images/flags/${tenant.codename}.png`}
          width={30}
          height={30}
          alt={tenant.name}
          className="m-r-20"
        />
        <Field layout="vertical" className="m-r-20" label="Tenant">
          {tenant.name}
        </Field>
        <Field layout="vertical" className="m-r-20" label="Name">
          {treatmentGroup.name}
        </Field>
        <Field layout="vertical" className="m-r-20" label="Codename">
          {treatmentGroup.codename}
        </Field>
      </div>
    </div>
  );
}

TreatmentGroupDetail.propTypes = {
  treatmentGroup: PropTypes.object.isRequired,
};
