import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Loader } from '@borrow-ui/ui';

import { preload } from './preload';
import {
  PRODUCTS_BASE_URL,
  PRODUCTS_EXPORT_BASE_URL,
  PRODUCTS_INGESTION_URL,
  PRODUCTS_PRODUCT_FILTERS_BASE_URL,
} from './constants';
import { ProductsRoutes, PRODUCTS_PRODUCTS_BASE_URL } from './pages/products/ProductsRoutes';
import {
  ProductsConsolidationRoutes,
  PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL,
} from './pages/productsConsolidation/ProductsConsolidationRoutes';
import { ProductsHomePage } from './pages/ProductsHomePage';
import { ProductsExportHomePage } from './pages/export/ProductsExportHomePage';
import { ProductsIngestHomePage } from './pages/ingest/ProductsIngestHomePage';
import { ProductFiltersRoutes } from './pages/productFilters/ProductFiltersRoutes';

export function ProductsLanding() {
  const products = useSelector((state) => state.products);
  const [state, setState] = useState({ appInitialized: false });

  const setInitialized = () => setState((state) => ({ ...state, appInitialized: true }));
  const initialize = () => {
    preload().then(setInitialized);
  };

  useEffect(() => {
    if (!products.initialized) initialize();
    else setInitialized();
  }, []); // eslint-disable-line

  const { appInitialized } = state;

  if (!appInitialized) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route path={`${PRODUCTS_BASE_URL}`} exact component={ProductsHomePage} />
      <Route path={`${PRODUCTS_INGESTION_URL}`} component={ProductsIngestHomePage} />
      <Route path={`${PRODUCTS_EXPORT_BASE_URL}`} component={ProductsExportHomePage} />
      <Route path={`${PRODUCTS_PRODUCTS_BASE_URL}`} component={ProductsRoutes} />
      <Route
        path={`${PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL}`}
        component={ProductsConsolidationRoutes}
      />
      <Route path={PRODUCTS_PRODUCT_FILTERS_BASE_URL} component={ProductFiltersRoutes} />
    </Switch>
  );
}
