import { store } from 'store/store';
import { SET_APPLICATION_LOADING } from 'store/actions';

import { productsEntity } from './models/product';
import { productBrandsEntity } from './models/productBrand';
import { productCategoriesEntity } from './models/productCategory';
import { productTypesEntity } from './models/productType';
import { productRangeEntity } from './models/productRange';
import { skinConcernsEntity } from './models/skinConcern';
import { skinTypesEntity } from './models/skinType';

import { INITIALIZE } from './actions';

export function preload() {
  return new Promise((resolve) => {
    store.dispatch({ type: SET_APPLICATION_LOADING, loading: true });
    const preloadPromises = [
      productsEntity.fetch(),
      productCategoriesEntity.fetch(),
      productBrandsEntity.fetch(),
      productTypesEntity.fetch(),
      productRangeEntity.fetch(),
      skinConcernsEntity.fetch(),
      skinTypesEntity.fetch(),
    ];

    Promise.all(preloadPromises).then(() => {
      store.dispatch({ type: SET_APPLICATION_LOADING, loading: false });
      store.dispatch({ type: INITIALIZE });
      resolve();
    });
  });
}
