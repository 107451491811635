import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Forms } from '@borrow-ui/ui';

const { Field } = Forms;

export function UserDetail({ user }) {
  return (
    <div>
      <Row>
        <Col>
          <Field label="First Name">{user.first_name}</Field>
          <Field label="Last Name">{user.last_name}</Field>
          <Field label="Username">{user.username}</Field>
        </Col>
        <Col>{/* Projects will go here */}</Col>
      </Row>
    </div>
  );
}

UserDetail.propTypes = {
  user: PropTypes.object.isRequired,
};
