import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL } from 'apps/treatments/constants';
import { TreatmentsConsolidationHomePage } from './TreatmentsConsolidationHomePage';

export { TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL };

export function TreatmentsConsolidationRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={`${TREATMENTS_TREATMENTS_CONSOLIDATION_BASE_URL}`}
        component={TreatmentsConsolidationHomePage}
      />
    </Switch>
  );
}
