import BaseEntity from 'models/common/base';

import { DELETE_PRODUCT_RANGE, SET_PRODUCT_RANGE, SET_PRODUCT_RANGES } from '../actions';
export const BASE_URL = 'api/products/product-ranges/';

export class ProductRangeEntity extends BaseEntity {
  constructor(properties) {
    super('ProductsProductRange', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_PRODUCT_RANGES,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_PRODUCT_RANGE });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_PRODUCT_RANGE });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_PRODUCT_RANGE);
  }
}

export const productRangeEntity = new ProductRangeEntity();
