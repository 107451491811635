import { postRequest } from 'libs/requests/requests';
import BaseEntity from 'models/common/base';

export const BASE_URL = 'api/dash/user-lookup/';

export class UserLookupEntity extends BaseEntity {
  constructor(properties) {
    super('DashUser', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  search(environment, tenant, attributes) {
    const { phone, email, crm_id } = attributes;

    const sanitizedPhone = phone?.replace('+', '').replaceAll(' ', '');
    const sanitizedEmail = email?.trim().toLowerCase();
    const sanitizedCrmId = crm_id?.trim();

    return postRequest(BASE_URL, {
      environment,
      tenant,
      phone: sanitizedPhone,
      email: sanitizedEmail,
      crm_id: sanitizedCrmId,
    });
  }
}

export const userLookupEntity = new UserLookupEntity();
