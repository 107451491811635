import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@borrow-ui/ui';
import { propTypesChildren } from '@borrow-ui/ui/utils/types';

import { getInitials } from 'utils/user';

export function UserLink({ user, display, withMargin = true, useInitials = false, ...rest }) {
  const marginRight = withMargin ? 15 : undefined;
  const name = useInitials ? getInitials(user) : user.full_name;
  return (
    <Link to={`/${user.id}`} style={{ marginRight }} {...rest}>
      {display ? display : name}
    </Link>
  );
}

UserLink.propTypes = {
  user: PropTypes.object.isRequired,
  display: propTypesChildren,
  withMargin: PropTypes.bool,
  useInitials: PropTypes.bool,
};
