import BaseEntity from 'models/common/base';

import { SET_TAX_RATE, SET_TAX_RATES } from '../actions';

export const BASE_URL = 'api/company-portal/tax-rates/';

export class TaxRateEntity extends BaseEntity {
  constructor(properties) {
    super('PortalTaxRate', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_TAX_RATES,
      ...options,
    });
  }

  get(id, options) {
    return super.get(id, { actionSingleSet: SET_TAX_RATE, ...options });
  }
}

export const taxRatesEntity = new TaxRateEntity();
