import BaseEntity from 'models/common/base';

import { DELETE_PRODUCT_BRAND, SET_PRODUCT_BRAND, SET_PRODUCT_BRANDS } from '../actions';
export const BASE_URL = 'api/products/product-brands/';

export class ProductBrandEntity extends BaseEntity {
  constructor(properties) {
    super('ProductsProductBrand', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_PRODUCT_BRANDS,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_PRODUCT_BRAND });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_PRODUCT_BRAND });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_PRODUCT_BRAND);
  }
}

export const productBrandsEntity = new ProductBrandEntity();
