import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Forms, Loader, Button } from '@borrow-ui/ui';

import { FormsErrors } from 'components/common/FormErrors';
import { ecommerceCategoriesEntity } from 'apps/treatments/models/ecommerceCategory';
import { useSelector } from 'react-redux';
import { treatmentsEntity } from 'apps/treatments/models/treatment';

const { Field, Input } = Forms;

export function EcommerceCategoryUpdateTreatmentsImagesForm({
  ecommerceCategory,
  onSubmitSuccess,
  onCancel,
}) {
  const { activeTenant } = useSelector((s) => s.portal);
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [data, setData] = useState({ image_original: '', image_thumbnail: '' });

  const onSubmit = (e) => {
    e.preventDefault();
    const message = { errors: {} };
    setSubmitMessage(null);

    if (Object.values(message.errors).length > 0) {
      setSubmitMessage(message);
      return;
    }

    setLoading(true);
    ecommerceCategoriesEntity
      .updateTreatmentsImages(ecommerceCategory.id, data)
      .then(() => {
        treatmentsEntity
          .fetch({ qs: { tenant_id: activeTenant.id, include_archived: 1 } })
          .then(() => {
            setLoading(false);
            onSubmitSuccess && onSubmitSuccess();
          });
      })
      .catch(() => setLoading(false));
  };

  const { errors } = submitMessage || { errors: {} };

  return (
    <form onSubmit={onSubmit}>
      <div>Updates the images of all Treatments associated to this category.</div>
      <FormsErrors submitMessage={submitMessage} />
      <Field label="Image - Desktop">
        <Input
          invalid={!!errors.image_original}
          disabled={loading}
          onChange={(e) => setData({ ...data, image_original: e.target.value })}
          value={data.image_original}
        />
      </Field>
      <Field label="Image - Mobile">
        <Input
          invalid={!!errors.image_thumbnail}
          disabled={loading}
          onChange={(e) => setData({ ...data, image_thumbnail: e.target.value })}
          value={data.image_thumbnail}
        />
      </Field>
      <div className="flex-end-center">
        {loading && (
          <span className="m-r-10">
            <Loader type="inline" />
          </span>
        )}
        <Button type="submit" mean="positive" disabled={loading}>
          Save
        </Button>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </div>
    </form>
  );
}

EcommerceCategoryUpdateTreatmentsImagesForm.propTypes = {
  ecommerceCategory: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};
