import React from 'react';
import PropTypes from 'prop-types';

import { Block, Row, Col, Badge, Title, Button } from '@borrow-ui/ui';

import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { SET_ACTIVE_ENVIRONMENT, SET_ACTIVE_TENANT } from 'apps/portal/actions';

export function TenantHeaderBody({ resetState }) {
  const { tenants, environments, activeTenant, activeEnvironment } = useSelector((s) => s.portal);

  const setActiveTenant = (tenant) => {
    // get related environment
    const environment = Object.values(environments).find(
      (e) => e.codename === activeEnvironment.codename && e.tenant === tenant.id
    );
    console.log('Set tenant and environment:', tenant, environment);
    store.dispatch({ type: SET_ACTIVE_ENVIRONMENT, data: environment });
    store.dispatch({ type: SET_ACTIVE_TENANT, data: tenant });
    localStorage.setItem('activeTenantId', tenant.id);
    localStorage.setItem('activeEnvironmentId', environment.id);
    resetState();
  };

  return (
    <Block>
      <Row>
        <Col colClassName="col-xs-6 col-sm-2 flex-center-center">
          <Badge type="circular" color="accent" style={{ width: 62, height: 62 }}>
            <Title>{activeTenant.name}</Title>
          </Badge>
        </Col>
        <Col colClassName="col-xs-6 col-sm-4"></Col>
        <Col colClassName="col-xs-6 col-sm-3"></Col>
        <Col colClassName="col-xs-6 col-sm-3">
          <Title tag="h3" className="m-t-0">
            Change Tenant
          </Title>
          <div>
            {Object.values(tenants).map((tenant) => {
              return (
                <div key={tenant.id} className="">
                  <Button
                    mean="regular-reverse"
                    onClick={() => {
                      setActiveTenant(tenant);
                    }}
                    className="w-200"
                  >
                    <span className="flex-center-center">
                      <img
                        src={`/images/flags/${tenant.codename}.png`}
                        alt={tenant.name}
                        width={20}
                        height={20}
                        className="m-r-10"
                      />

                      {tenant.name}
                    </span>
                  </Button>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Block>
  );
}

TenantHeaderBody.propTypes = {
  resetState: PropTypes.func.isRequired,
};
