import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Breadcrumbs, Button, Modal, Page, Table, Forms } from '@borrow-ui/ui';
import { PRODUCTS_PRODUCT_FILTERS_BREADCRUMBS } from 'apps/products/constants';
import { productBrandsEntity } from 'apps/products/models/productBrand';
import { productTypesEntity } from 'apps/products/models/productType';
import { productRangeEntity } from 'apps/products/models/productRange';
import { skinTypesEntity } from 'apps/products/models/skinType';
import { skinConcernsEntity } from 'apps/products/models/skinConcern';
import { userCanManageProducts } from 'utils/userGroups';

import styles from './product-filters-page.module.scss';

const { Input, Field } = Forms;

export const ProductFiltersPage = () => {
  const { productBrands, productTypes, productRanges, skinTypes, skinConcerns } = useSelector(
    (state) => state.products
  );

  if (!userCanManageProducts()) {
    return <div>You do not have permission to access this page.</div>;
  }

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PRODUCTS_PRODUCT_FILTERS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Products Filters</h2>
        </>
      }
    >
      <div className={styles['page__content']}>
        <FilterTable
          title="Product Brands"
          model={productBrandsEntity}
          items={Object.values(productBrands)}
        />
        <FilterTable
          title="Product Types"
          model={productTypesEntity}
          items={Object.values(productTypes)}
        />
        <FilterTable
          title="Product Ranges"
          model={productRangeEntity}
          items={Object.values(productRanges)}
        />
        <FilterTable title="Skin Types" model={skinTypesEntity} items={Object.values(skinTypes)} />
        <FilterTable
          title="Skin Concerns"
          model={skinConcernsEntity}
          items={Object.values(skinConcerns)}
        />
      </div>
    </Page>
  );
};

const FilterTable = ({ title, items, model }) => {
  const { tenants } = useSelector((state) => state.portal);

  return (
    <Accordion title={title}>
      <div className={styles['accordion__content']}>
        <Table
          config={{ borderType: 'none' }}
          searchBar={{ visible: true }}
          columns={[
            { prop: 'tenant', title: 'Tenant', width: '10%' },
            { prop: 'name', title: 'Name', width: '70%' },
            { prop: 'count', title: '# Products', width: '10%' },
            { prop: 'actions', title: 'Actions', width: '10%' },
          ]}
          entries={items.map((item) => ({
            name: item.name,
            tenant: tenants[item.tenant].name,
            count: item.product_count,
            actions: userCanManageProducts() && (
              <>
                <FilterEditModal title={title} filter={item} model={model} />
                <FilterDeleteButton filter={item} model={model} />
              </>
            ),
          }))}
          pagination={{ pageSize: 5 }}
        />
      </div>
    </Accordion>
  );
};

const FilterEditModal = ({ title, filter, model }) => {
  const { name } = filter;
  const [filterName, setFilterName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const handleEdit = async () => {
    if (!userCanManageProducts()) return;

    setIsSaving(true);
    try {
      await model.save({ ...filter, name: filterName });
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      Trigger={({ setVisible }) => {
        return (
          <Button
            mean="primary-reverse"
            size="small"
            onClick={() => {
              setFilterName(name);
              setVisible(true);
            }}
          >
            Edit
          </Button>
        );
      }}
      getModalWindowProps={({ setVisible }) => ({
        title: `Edit ${title.substring(0, title.length - 1)}`,
        startWidth: 400,
        startHeight: 200,
        content: (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await handleEdit();
              setVisible(false);
            }}
          >
            <Field label="Name">
              <Input
                placeholder="Name"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
              />
            </Field>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button style={{ margin: 0 }} type="submit" loading={isSaving} disabled={!filterName}>
                Save
              </Button>
            </div>
          </form>
        ),
      })}
    />
  );
};

const FilterDeleteButton = ({ filter, model }) => {
  const handleDelete = ({ id, name, product_count }) => {
    if (!userCanManageProducts()) return;

    const confirmed = window.confirm(
      `Are you sure? ${name} will be deleted.\n\nThis will affect ${product_count || 0} Products.`
    );

    if (!confirmed) return;

    model.delete(id);
  };

  return (
    <Button mean="negative-reverse" size="small" onClick={() => handleDelete(filter)}>
      Delete
    </Button>
  );
};
