import { postRequest } from 'libs/requests/requests';
import BaseEntity from 'models/common/base';

import {
  SET_ECOMMERCE_SUBSECTIONS,
  SET_ECOMMERCE_SUBSECTION,
  DELETE_ECOMMERCE_SUBSECTION,
} from '../actions';
export const BASE_URL = 'api/treatments/ecommerce-subsections/';

export function newEcommerceSubsection() {
  return {
    id: null,
    tenant: null,
    section: null,
    title: '',
    order: 0,
    hide_title: false,
    collapse: false,
    collapse_by_attribute: '',
    read_only: false,
  };
}

export class EcommerceSubsectionEntity extends BaseEntity {
  constructor(properties) {
    super('TreatmentsEcommerceSubsection', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  static emptyEntry() {
    return newEcommerceSubsection();
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_ECOMMERCE_SUBSECTIONS,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_ECOMMERCE_SUBSECTION });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_ECOMMERCE_SUBSECTION });
  }

  addItem(id, treatmentId) {
    return postRequest(`${BASE_URL}${id}/add-item/`, { treatment_id: treatmentId }).then(
      (updatedSubsection) => {
        return this.localUpdate(updatedSubsection);
      }
    );
  }

  removeItem(id, itemId) {
    return postRequest(`${BASE_URL}${id}/remove-item/`, { item_id: itemId }).then(
      (updatedSubsection) => {
        return this.localUpdate(updatedSubsection);
      }
    );
  }

  reorderItems(id, newOrder) {
    return postRequest(`${BASE_URL}${id}/reorder-items/`, { new_order: newOrder }).then(
      (updatedSubsection) => {
        return this.localUpdate(updatedSubsection);
      }
    );
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_ECOMMERCE_SUBSECTION);
  }
}

export const ecommerceSubsectionsEntity = new EcommerceSubsectionEntity();
