// Set the application as initialized
export const INITIALIZE = 'INITIALIZE_PORTAL';

// Set the active tenant
export const SET_ACTIVE_TENANT = 'SET_ACTIVE_TENANT';

// Set the active environment
export const SET_ACTIVE_ENVIRONMENT = 'SET_ACTIVE_ENVIRONMENT';

// Set a list of tenants
export const SET_TENANTS = 'SET_TENANTS';

// Set a single tenant
export const SET_TENANT = 'SET_TENANT';

// Set a list of environments
export const SET_ENVIRONMENTS = 'SET_ENVIRONMENTS';

// Set a single tenant
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';

// Set a list of users
export const SET_USERS = 'SET_USERS';

// Set a single user
export const SET_USER = 'SET_USER';

// Set a list of Tax Rates
export const SET_TAX_RATES = 'SET_TAX_RATES';

// Set a single Tax Rate
export const SET_TAX_RATE = 'SET_TAX_RATE';
