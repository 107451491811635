import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Block, Breadcrumbs, Loader, Page } from '@borrow-ui/ui';

import {
  productStatuses,
  PRODUCTS_PRODUCTS_CONSOLIDATION_BREADCRUMBS,
} from 'apps/products/constants';
import { ProductsList } from '../../components/product/ProductsList';

export function ProductsConsolidationHomePage() {
  const [loaded, setLoaded] = useState(false);
  const { activeTenant } = useSelector((s) => s.portal);
  const { products } = useSelector((s) => s.products);

  // keep the same logic of all other pages, even if the entity
  // is already available in the store as it is a "reference"
  useEffect(() => {
    setLoaded(true);
  }, []);

  const productsList = useMemo(() => {
    return Object.values(products)
      .filter((p) => p.tenant === activeTenant.id)
      .filter((p) => p.status === productStatuses.PUBLISHED);
  }, [products, activeTenant]);

  if (!loaded)
    return (
      <Block contentCentered={true} className="h-100pc">
        <Loader />
      </Block>
    );

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PRODUCTS_PRODUCTS_CONSOLIDATION_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Published Products List</h2>
        </>
      }
    >
      <ProductsList
        productsList={productsList}
        showStatusFilter={false}
        showEdit={true}
        showImages={true}
      />
    </Page>
  );
}
