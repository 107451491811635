import BaseEntity from 'models/common/base';

import { SET_PRODUCT_CATEGORY, SET_PRODUCT_CATEGORIES } from '../actions';
export const BASE_URL = 'api/products/product-categories/';

export class ProductCategoryEntity extends BaseEntity {
  constructor(properties) {
    super('ProductsProductCategory', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_PRODUCT_CATEGORIES,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_PRODUCT_CATEGORY });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_PRODUCT_CATEGORY);
  }
}

export const productCategoriesEntity = new ProductCategoryEntity();
