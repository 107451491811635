import { getRequest, postRequest } from 'libs/requests/requests';
import BaseEntity from './base';

const BASE_URL = 'api/company-portal/async-tasks/';

export class AsyncTaskEntity extends BaseEntity {
  constructor(properties) {
    super('AsyncTask', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  create(name) {
    return postRequest(this.baseUrl, { name });
  }

  get(id) {
    return getRequest(`${this.baseUrl}${id}/`);
  }

  list() {
    return getRequest(this.baseUrl);
  }
}

export const asyncTaskEntity = new AsyncTaskEntity();
