// Set the application as initialized
export const INITIALIZE = 'INITIALIZE_PRODUCTS';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT = 'SET_PRODUCT';

export const SET_PRODUCT_BRAND = 'SET_PRODUCT_BRAND';
export const SET_PRODUCT_BRANDS = 'SET_PRODUCT_BRANDS';
export const DELETE_PRODUCT_BRAND = 'DELETE_PRODUCT_BRAND';

export const SET_PRODUCT_CATEGORY = 'SET_PRODUCT_CATEGORY';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';

export const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE';
export const SET_PRODUCT_TYPES = 'SET_PRODUCT_TYPES';
export const DELETE_PRODUCT_TYPE = 'DELETE_PRODUCT_TYPE';

export const SET_PRODUCT_RANGE = 'SET_PRODUCT_RANGE';
export const SET_PRODUCT_RANGES = 'SET_PRODUCT_RANGES';
export const DELETE_PRODUCT_RANGE = 'DELETE_PRODUCT_RANGE';

export const SET_SKIN_CONCERN = 'SET_SKIN_CONCERN';
export const SET_SKIN_CONCERNS = 'SET_SKIN_CONCERNS';
export const DELETE_SKIN_CONCERN = 'DELETE_SKIN_CONCERN';

export const SET_SKIN_TYPE = 'SET_SKIN_TYPE';
export const SET_SKIN_TYPES = 'SET_SKIN_TYPES';
export const DELETE_SKIN_TYPE = 'DELETE_SKIN_TYPE';
