import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Block, Loader, Page, Breadcrumbs, Button } from '@borrow-ui/ui';

import { TREATMENTS_BREADCRUMBS } from 'apps/treatments/constants';
import { TreatmentGroupsList } from 'apps/treatments/components/treatmentGroups/TreatmentGroupsList';
import { treatmentsEntity } from 'apps/treatments/models/treatment';

export function TreatmentGroupsListPage() {
  const [loaded, setLoaded] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const { tenants, activeEnvironment, activeTenant } = useSelector((s) => s.portal);
  const { treatmentGroups } = useSelector((s) => s.treatments);

  // keep the same logic of all other pages, even if the entity
  // is already available in the store as it is a "reference"
  useEffect(() => {
    setLoaded(true);
  }, []);

  const exportToDash = async () => {
    const confirmed = window.confirm(
      `Are you sure? All Treatment Groups and Categories will be exported to ${activeEnvironment.name} - ${activeTenant.name}.`
    );

    if (!confirmed) return;

    try {
      setIsExportLoading(true);
      await treatmentsEntity.publishToDashDdb(
        'collections',
        activeEnvironment.codename,
        activeTenant.codename
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsExportLoading(false);
    }
  };

  if (!loaded)
    return (
      <Block contentCentered={true} className="h-100pc">
        <Loader />
      </Block>
    );

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={TREATMENTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Treatment Groups List</h2>
        </>
      }
      pageHeaderProps={{
        controls: (
          <Button loading={isExportLoading} onClick={exportToDash}>
            Export to Dash
          </Button>
        ),
      }}
    >
      <TreatmentGroupsList treatmentGroups={treatmentGroups} tenants={tenants} />
    </Page>
  );
}
