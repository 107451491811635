import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TreatmentPropType } from './TreatmentPropTypes';
import { Icon, Panel, Title } from '@borrow-ui/ui';
import { treatmentWarningAttrs } from 'apps/treatments/utils';

export function TreatmentWarningAttrsPanel({ treatment }) {
  const warningAttrs = useMemo(() => treatmentWarningAttrs(treatment), [treatment]);
  if (warningAttrs.length === 0) return null;

  return (
    <Panel
      Trigger={({ setVisible }) => (
        <Icon name="warning" className="color-warning" onClick={() => setVisible(true)} />
      )}
      getPanelContentProps={() => ({
        title: `Treatment Detail - ${treatment.title}`,
        content: <WarningAttrs treatment={treatment} warningAttrs={warningAttrs} />,
      })}
    />
  );
}

TreatmentWarningAttrsPanel.propTypes = {
  treatment: TreatmentPropType,
};

function WarningAttrs({ treatment, warningAttrs }) {
  return (
    <div>
      <Title tag="h2" className="color-warning">
        Warning
      </Title>
      <Title tag="h4">{treatment.title}</Title>
      <div>
        The following attributes are missing or requiring attention before publishing to e-commerce:
        <ul>
          {warningAttrs.map((ma) => (
            <li key={ma}>{ma}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

WarningAttrs.propTypes = {
  treatment: TreatmentPropType,
  warningAttrs: PropTypes.arrayOf(PropTypes.string),
};
