import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Forms, Loader, Button } from '@borrow-ui/ui';

import { FormsErrors } from 'components/common/FormErrors';
import {
  ecommerceSectionsEntity,
  newEcommerceSection,
} from 'apps/treatments/models/ecommerceSection';

const { Field, Input } = Forms;

export function EcommerceSectionForm({
  ecommerceSection,
  ecommerceCategory,
  order,
  onSubmitSuccess,
  onCancel,
}) {
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [data, setData] = useState(ecommerceSection || newEcommerceSection());

  const onSubmit = (e) => {
    e.preventDefault();
    const message = { errors: {} };
    setSubmitMessage(null);

    if (!data.title) message.errors.title = 'Title is required';

    if (Object.values(message.errors).length > 0) {
      setSubmitMessage(message);
      return;
    }

    const newSection = {
      ...data,
      order: ecommerceSection ? ecommerceSection.order : order,
      category: ecommerceSection ? ecommerceSection.category : ecommerceCategory.id,
      tenant: ecommerceSection ? ecommerceSection.tenant : ecommerceCategory.tenant,
    };

    setLoading(true);
    ecommerceSectionsEntity
      .save(newSection)
      .then((newS) => {
        setLoading(false);
        onSubmitSuccess && onSubmitSuccess(newS);
      })
      .catch(() => setLoading(false));
  };

  const { errors } = submitMessage || { errors: {} };

  return (
    <form onSubmit={onSubmit}>
      <FormsErrors submitMessage={submitMessage} />
      <Field label="Title">
        <Input
          invalid={!!errors.title}
          disabled={loading}
          onChange={(e) => setData({ ...data, title: e.target.value })}
          value={data.title}
        />
      </Field>
      <div className="flex-end-center">
        {loading && (
          <span className="m-r-10">
            <Loader type="inline" />
          </span>
        )}
        <Button type="submit" mean="positive" disabled={loading}>
          Save
        </Button>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
      </div>
    </form>
  );
}

EcommerceSectionForm.propTypes = {
  ecommerceSection: PropTypes.object,
  ecommerceCategory: PropTypes.object,
  order: PropTypes.number,
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};
