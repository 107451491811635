import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { sortObjectsListByAttributes } from 'utils/data';
import { Button, Title } from '@borrow-ui/ui';

import './ecommerce-category-preview.scss';

export function EcommerceCategoryPreview({ ecommerceCategory, data }) {
  const { ecommerceSections, ecommerceSubsections, treatmentGroups } = useSelector(
    (s) => s.treatments
  );
  const treatmentGroup = treatmentGroups[ecommerceCategory.treatment_group];

  const sections = sortObjectsListByAttributes(
    data.ecommerceSectionsIds
      .map((esId) => ecommerceSections[esId])
      .filter((es) => es.category === ecommerceCategory.id),
    ['order']
  );
  return (
    <div>
      {treatmentGroup.codename === 'laser-hair-removal' && (
        <div className="flex-center-center">
          Note: LHR sections will be shown with a selector on top
        </div>
      )}
      {sections.map((section) => {
        const subsections = sortObjectsListByAttributes(
          data.ecommerceSubsectionsIds
            .map((essId) => ecommerceSubsections[essId])
            .filter((ess) => ess.section === section.id),
          ['order']
        );
        return (
          <div key={section.id}>
            {treatmentGroup.codename === 'laser-hair-removal' && <Title>{section.title}</Title>}
            <div className="ecp__subsections-container">
              {subsections.map((subsection) => {
                return (
                  <div
                    key={subsection.id}
                    className={`ecp__subsection ${
                      subsection.collapse ? 'ecp__subsection--collapse' : ''
                    }`}
                  >
                    {!subsection.hide_title && !subsection.collapse && (
                      <Title tag="h2">{subsection.title}</Title>
                    )}
                    {subsection.collapse && (
                      <ItemsCollapsed subsection={subsection} items={subsection.items} />
                    )}
                    {!subsection.collapse && <ItemsPlain items={subsection.items} />}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

EcommerceCategoryPreview.propTypes = {
  data: PropTypes.object,
  ecommerceCategory: PropTypes.object,
};

function ItemsPlain({ items }) {
  const { treatments } = useSelector((s) => s.treatments);
  return (
    <div className="ecp__items-plain">
      {items.map((item) => {
        const treatment = treatments[item.treatment];
        return (
          <div key={item.id} className="ecp__items-plain__item">
            <Title tag="h4" className="m-t-0">
              {treatment.title}
            </Title>
            <div className="ecp__items-plain__item__description">{treatment.description}</div>
            <div className="ecp__items-plain__item__price">{treatment.price}</div>
            <Button className="ecp__buy-button">
              {treatment.pay_in_clinic || treatment.treatment_type === 'SERVICE'
                ? 'Book now'
                : 'Buy now'}
            </Button>
          </div>
        );
      })}
    </div>
  );
}

ItemsPlain.propTypes = { items: PropTypes.array };

function ItemsCollapsed({ subsection, items }) {
  const [selected, setSelected] = useState(0);
  const { treatments } = useSelector((s) => s.treatments);
  const selectedTreatment = items.length > 0 ? treatments[items[selected].treatment] : {};
  const getInstances = (treatment) =>
    subsection.collapse_by_attribute === 'cycles'
      ? treatment.number_of_cycles
      : treatment.number_of_sessions;
  const perLabel = subsection.collapse_by_attribute === 'cycles' ? 'per cycle' : 'per session';

  return (
    <div className="ecp__items-collapsed">
      <Title tag="h3" className="m-t-0">
        {subsection.title}
      </Title>
      <div className="ecp__items-collapsed__selector">
        {items.map((item, index) => {
          const treatment = treatments[item.treatment];
          return (
            <div
              key={index}
              onClick={() => setSelected(index)}
              className={`ecp__items-collapsed__selector__value ${
                index === selected ? 'ecp__items-collapsed__selector__value--selected' : ''
              }`}
            >
              {getInstances(treatment)}
            </div>
          );
        })}
      </div>
      <div className="flex-spacebetween-end">
        <div>
          <div className="ecp__items-collapsed__price">{selectedTreatment.price}</div>
          <div>Total</div>
        </div>
        <div>
          <div className="ecp__items-collapsed__price">
            {(
              parseFloat(selectedTreatment.price) / parseFloat(getInstances(selectedTreatment))
            ).toFixed(2)}
          </div>
          <div>{perLabel}</div>
        </div>
      </div>
      {!subsection.read_only && (
        <Button className="ecp__buy-button">
          {selectedTreatment.pay_in_clinic || selectedTreatment.treatment_type === 'SERVICE'
            ? 'Book now'
            : 'Buy now'}
        </Button>
      )}
    </div>
  );
}

ItemsCollapsed.propTypes = { subsection: PropTypes.object, items: PropTypes.array };
