// These values can be set in current files:
// .env.development.local                      not committed, development values
// .env.development                            committed, development values
// .env.production.local                       not committed, production values
// .env.production                             committed, production values

// All the previous are with precedence from top to bottom, and overrides:
// .env                                        committed in repo, default values

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || '';
export const BACKEND_LOGIN_URL = process.env.REACT_APP_BACKEND_LOGIN_URL || '';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_DAY_FORMAT = 'EEE, d MMM';

export const TIMESHEET_APP_TIME_LOG_APPROVAL_REQUIRED =
  process.env.REACT_APP_TIMESHEET_APP_TIME_LOG_APPROVAL_REQUIRED === 'false' ? false : true;

export const TIMESHEET_APP_DISABLE_TIME_OFF =
  process.env.REACT_APP_TIMESHEET_APP_DISABLE_TIME_OFF === 'true';
