import { store } from 'store/store';

import { setApplicationLoading } from 'store/utils';

import { getRequest, postRequest, deleteRequest, putRequest } from 'libs/requests/requests';

const PAGINATED_DATA_RESULTS_ATTRIBUTE = 'results';

function getUrl(urlType, id) {
  switch (urlType) {
    case 'detail':
    case 'edit':
    case 'delete':
      return `${this.baseUrl}${id}/`;
    case 'get':
    case 'fetch':
    case 'add':
    default:
      return this.baseUrl;
  }
}

export default class BaseEntity {
  constructor(name, properties = {}) {
    this.name = name;
    this.baseUrl = properties.baseUrl || '';
    this.inMemory = !!properties.inMemory; // excludes the store and keeps result internally
    this.hasPagination = properties.hasPagination !== undefined ? properties.hasPagination : true;
    this.getUrl = properties.getUrl || getUrl;
    this.paginatedData = { results: {} };
    this.paginatedDataResultsAttribute =
      properties.paginatedDataResultsAttribute || PAGINATED_DATA_RESULTS_ATTRIBUTE;
  }

  getPaginatedData(page) {
    if (page) return this.paginatedData.results[page];

    return this.paginatedData;
  }

  get(id, options = {}) {
    setApplicationLoading(true);
    return getRequest(this.getUrl('detail', id, options), { timeout: 5000 })
      .then((response) => {
        let data = response;
        if (options.mapData) {
          data = options.mapData(data);
        }
        options.actionSingleSet &&
          !this.inMemory &&
          store.dispatch({ type: options.actionSingleSet, data });
        if (!options.keepLoading) {
          setApplicationLoading(false);
        }
        return data;
      })
      .catch((response) => {
        if (response.response === undefined) {
          // assuming timeout
        }
        setApplicationLoading(false);
        console.error('Impossible to load data: ', response);
        throw response.response;
      });
  }

  fetch({ getParams = {}, qs, ...options }) {
    const self = this;
    setApplicationLoading(true);
    return getRequest(this.getUrl('fetch', null, options), {
      timeout: 5000,
      ...getParams,
      qs,
    })
      .then((response) => {
        let data, paginatedData;
        if (self.hasPagination) {
          const { [this.paginatedDataResultsAttribute]: results, ...restPaginatedData } = response;
          data = results;
          paginatedData = restPaginatedData;
        } else {
          data = response;
        }
        if (options.mapData) {
          data = options.mapData(data);
        }
        if (self.hasPagination) {
          const page = (qs && qs.page) || options.page;

          self.paginatedData = { ...paginatedData, ...self.paginatedData };
          self.paginatedData.results[page] = data;
        } else {
          self.paginatedData = data;
        }
        options.fetchActionSet &&
          !this.inMemory &&
          store.dispatch({ type: options.fetchActionSet, data });
        if (!options.keepLoading) {
          setApplicationLoading(false);
        }
        return data;
      })
      .catch((response) => {
        if (response === undefined) {
          // assuming timeout
        }
        setApplicationLoading(false);
        console.error('Impossible to load data: ', response);
        throw response.response;
      });
  }

  delete(id, options = {}) {
    setApplicationLoading(true);
    return deleteRequest(this.getUrl('delete', id, options))
      .then((response) => {
        setApplicationLoading(false);
        options.deleteAction &&
          !this.inMemory &&
          store.dispatch({ type: options.deleteAction, id });
        return response;
      })
      .catch((response) => {
        setApplicationLoading(false);
        throw response;
      });
  }

  save(entry, options = {}) {
    setApplicationLoading(true);
    if (entry.id) {
      // EDIT
      return putRequest(this.getUrl('edit', entry.id, options), entry, options)
        .then((response) => {
          setApplicationLoading(false);
          if (options.autoGet) {
            return this.get(entry.id, options);
          } else {
            return response;
          }
        })
        .catch((response) => {
          setApplicationLoading(false);
          throw response.response;
        });
    } else {
      // ADD
      return postRequest(this.getUrl('add', null, options), entry, options)
        .then((response) => {
          options.actionSingleSet &&
            !this.inMemory &&
            store.dispatch({ type: options.actionSingleSet, data: response });
          setApplicationLoading(false);
          return response;
        })
        .catch((response) => {
          setApplicationLoading(false);
          throw response.response;
        });
    }
  }

  localUpdate(data, actionSingleSet) {
    return new Promise((resolve) => {
      store.dispatch({ type: actionSingleSet, data });
      resolve(data);
    });
  }

  localUpdateMany(data, actionMultipleSet) {
    return new Promise((resolve) => {
      store.dispatch({ type: actionMultipleSet, data });
      resolve(data);
    });
  }
}
