import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Icon, Panel, Title } from '@borrow-ui/ui';

import { productWarningAttrs } from 'apps/products/utils';

export function ProductWarningAttrsPanel({ product }) {
  const warningAttrs = useMemo(() => productWarningAttrs(product), [product]);
  if (warningAttrs.length === 0) return null;

  return (
    <Panel
      Trigger={({ setVisible }) => (
        <Icon name="warning" className="color-warning" onClick={() => setVisible(true)} />
      )}
      getPanelContentProps={() => ({
        title: `Product Detail - ${product.title}`,
        content: <WarningAttrs product={product} warningAttrs={warningAttrs} />,
      })}
    />
  );
}

ProductWarningAttrsPanel.propTypes = {
  product: PropTypes.object,
};

function WarningAttrs({ product, warningAttrs }) {
  return (
    <div>
      <Title tag="h2" className="color-warning">
        Warning
      </Title>
      <Title tag="h4">{product.title}</Title>
      <div>
        The following attributes are missing or requiring attention before publishing to e-commerce:
        <ul>
          {warningAttrs.map((ma) => (
            <li key={ma}>{ma}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

WarningAttrs.propTypes = {
  product: PropTypes.object,
  warningAttrs: PropTypes.arrayOf(PropTypes.string),
};
