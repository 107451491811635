import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PRODUCTS_PRODUCT_FILTERS_BASE_URL } from 'apps/products/constants';
import { ProductFiltersPage } from './page/ProductFiltersPage';

export function ProductFiltersRoutes() {
  return (
    <Switch>
      <Route exact path={`${PRODUCTS_PRODUCT_FILTERS_BASE_URL}`} component={ProductFiltersPage} />
    </Switch>
  );
}
