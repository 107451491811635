import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Block, Loader, Page, Breadcrumbs, TileLink, Tile, Title } from '@borrow-ui/ui';

import { PORTAL_BREADCRUMBS } from 'apps/portal/constants';
import {
  productStatuses,
  PRODUCTS_EXPORT_BASE_URL,
  PRODUCTS_ICON_NAME,
  PRODUCTS_INGESTION_URL,
  PRODUCTS_PRODUCTS_BASE_URL,
  PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL,
  PRODUCT_FILTERS_ICON_NAME,
  PRODUCTS_PRODUCT_FILTERS_BASE_URL,
} from '../constants';
import { productHasWarnings } from '../utils';

export function ProductsHomePage() {
  const [loaded, setLoaded] = useState(false);
  const { user } = useSelector((s) => s.authentication);
  const isProductsManager = user.groups.includes('products_managers');

  // keep the same logic of all other pages, even if the entity
  // is already available in the store as it is a "reference"
  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded)
    return (
      <Block contentCentered={true} className="h-100pc">
        <Loader />
      </Block>
    );

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PORTAL_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Products Home</h2>
        </>
      }
    >
      <div>
        <TileLink
          size="small"
          name="Products"
          icon={PRODUCTS_ICON_NAME}
          path={`${PRODUCTS_PRODUCTS_BASE_URL}`}
          description="List of all Products"
        />
        <TileLink
          size="small"
          name="Products Consolidation"
          icon={PRODUCTS_ICON_NAME}
          path={`${PRODUCTS_PRODUCTS_CONSOLIDATION_BASE_URL}`}
          description="Review Published Products"
        />
        <TileLink
          size="small"
          name="Product Filters"
          icon={PRODUCT_FILTERS_ICON_NAME}
          path={`${PRODUCTS_PRODUCT_FILTERS_BASE_URL}`}
          description="List of all Product Filters"
        />
        {isProductsManager && (
          <>
            <TileLink
              size="small"
              name="Export or Publish"
              icon="file_download"
              path={PRODUCTS_EXPORT_BASE_URL}
              description="Export Products to XLS/JSON or Publish to Dash"
            />
            <TileLink
              size="small"
              name="Phorest Ingestion"
              icon="cloud_download"
              path={PRODUCTS_INGESTION_URL}
              description="Ingest Products from Phorest"
            />
          </>
        )}
      </div>
      <div>
        <ProductsStats />
      </div>
    </Page>
  );
}

function ProductsStats() {
  const { activeTenant } = useSelector((s) => s.portal);
  const { products } = useSelector((s) => s.products);
  const productsList = Object.values(products).filter((p) => p.tenant === activeTenant.id);
  const tot = productsList.length;
  const isPublished = (p) => p.status === productStatuses.PUBLISHED;
  const draftTot = productsList.filter((p) => p.status === productStatuses.DRAFT).length;
  const publishedTot = productsList.filter(isPublished).length;
  const archivedTot = productsList.filter((p) => p.status === productStatuses.ARCHIVED).length;

  const publishedWarningsTot = productsList.filter(isPublished).filter(productHasWarnings).length;
  const warningsTot = productsList.filter(productHasWarnings).length;

  return (
    <>
      <div className="flex-start-start" style={{ gap: 20 }}>
        <Block outstanding className="w-150 flex-center-center">
          <Tile size="small" description="Total">
            <Title tag="h2" className="color-primary">
              {tot}
            </Title>
          </Tile>
        </Block>
        <Block outstanding className="w-150 flex-center-center">
          <Tile size="small" description="Published">
            <Title tag="h2" className="color-accent">
              {publishedTot}
            </Title>
          </Tile>
        </Block>
        <Block outstanding className="w-150 flex-center-center">
          <Tile size="small" description="Draft">
            <Title tag="h2">{draftTot}</Title>
          </Tile>
        </Block>
        <Block outstanding className="w-150 flex-center-center">
          <Tile size="small" description="Archived">
            <Title tag="h2">{archivedTot}</Title>
          </Tile>
        </Block>
      </div>
      <div className="flex-start-start" style={{ gap: 20 }}>
        <Block outstanding className="w-150 flex-center-center">
          <Tile size="small" description="With Warnings">
            <Title tag="h2" className="color-warning">
              {warningsTot}
            </Title>
          </Tile>
        </Block>
        <Block outstanding className="w-150 flex-center-center">
          <Tile size="small" description="Published w/ Warnings">
            <Title tag="h2" className="color-warning">
              {publishedWarningsTot}
            </Title>
          </Tile>
        </Block>
      </div>
    </>
  );
}
