import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Loader } from '@borrow-ui/ui';

import {
  PORTAL_BASE_URL,
  PORTAL_CLEAR_DATA_BASE_URL,
  PORTAL_FILE_UPLOADER_BASE_URL,
  PORTAL_IMPORT_DATA_BASE_URL,
  PREFERENCES_BASE_URL,
  USERS_BASE_URL,
} from './constants';
import { preload } from './preload';
import { UsersRoutes } from './pages/users/UsersRoutes';
import { UserPreferencesPage } from './pages/preferences/UserPreferencesPage';
import { PortalHomePage } from './pages/PortalHomePage';
import { FileUploaderPage } from './pages/fileUploader/FileUploaderPage';
import { ClearDataPage } from './pages/clearData/ClearDataPage';
import { ImportDataPage } from './pages/importData/ImportDataPage';

export function PortalLanding() {
  const portal = useSelector((state) => state.portal);
  const [state, setState] = useState({ appInitialized: false });

  const setInitialized = () => setState((state) => ({ ...state, appInitialized: true }));
  const initialize = () => {
    preload().then(setInitialized);
  };

  useEffect(() => {
    if (!portal.initialized) initialize();
    else setInitialized();
  }, []); // eslint-disable-line

  const { appInitialized } = state;

  if (!appInitialized) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route exact path={`${PORTAL_BASE_URL}`} component={PortalHomePage} />
      <Route exact path={`${PREFERENCES_BASE_URL}/user`} component={UserPreferencesPage} />
      <Route path={`${USERS_BASE_URL}`} component={UsersRoutes} />
      <Route path={`${PORTAL_FILE_UPLOADER_BASE_URL}`} component={FileUploaderPage} />
      <Route path={`${PORTAL_CLEAR_DATA_BASE_URL}`} component={ClearDataPage} />
      <Route path={`${PORTAL_IMPORT_DATA_BASE_URL}`} component={ImportDataPage} />
    </Switch>
  );
}
