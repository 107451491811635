import React, { useEffect, useState } from 'react';

import { Responsive, Loader, generateSidebarContextProvider } from '@borrow-ui/ui';
import { DEFAULT_QUERIES } from '@borrow-ui/ui/components/responsive/Responsive';

import { store } from 'store/store';
import { Header } from './scaffold/header/Header';
import { Routes } from './routes/Routes';
// import { MainSidebar } from 'scaffold/MainSidebar';

import { usersEntity } from 'apps/portal/models/user';
import { tenantsEntity } from 'apps/portal/models/tenant';
import { environmentsEntity } from 'apps/portal/models/environment';
import { SET_ACTIVE_ENVIRONMENT, SET_ACTIVE_TENANT } from 'apps/portal/actions';
import { taxRatesEntity } from 'apps/portal/models/taxRate';

const MEDIA_QUERIES = {
  ...DEFAULT_QUERIES,
  visibleSidebar: '(min-width: 725px)',
};

const { SidebarContext, SidebarProvider } = generateSidebarContextProvider({
  initialStatus: 'open',
});

export function Dashboard() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const preload = [
      usersEntity.fetch(),
      tenantsEntity.fetch(),
      environmentsEntity.fetch(),
      taxRatesEntity.fetch(),
    ];

    Promise.all(preload).then(async (responses) => {
      const tenants = responses[1];
      const environments = responses[2];
      if (tenants.length === 0) {
        console.error('Tenants have not been loaded!');
      } else {
        const activeTenantId = localStorage.getItem('activeTenantId');
        let tenant;
        if (activeTenantId) {
          tenant = tenants.find((t) => t.id === +activeTenantId);
        } else {
          tenant = tenants[0];
          localStorage.setItem('activeTenantId', tenant.id);
        }
        store.dispatch({ type: SET_ACTIVE_TENANT, data: tenant });
      }
      if (environments.length === 0) {
        console.error('Environments have not been loaded!');
      } else {
        const activeEnvironmentId = localStorage.getItem('activeEnvironmentId');
        let environment;
        if (activeEnvironmentId) {
          environment = environments.find((t) => t.id === +activeEnvironmentId);
        } else {
          environment = environments[0];
          localStorage.setItem('activeEnvironmentId', environment.id);
        }
        store.dispatch({ type: SET_ACTIVE_ENVIRONMENT, data: environment });
      }
      setLoaded(true);
    });
  }, []);

  if (!loaded)
    return (
      <div className="flex-center-center h-100vh">
        <Loader />
      </div>
    );

  return (
    <div className="company-portal borrow-ui borrow-ui__navbar--stycky-margin">
      <SidebarProvider>
        <Responsive queries={MEDIA_QUERIES}>
          {(matches) => {
            const isSmallScreen = !matches.visibleSidebar;
            return (
              <>
                <Header isSmallScreen={isSmallScreen} sidebarContext={SidebarContext} />
                <div className="flex">
                  {/*                                     <MainSidebar
                                        sidebarContext={SidebarContext}
                                        isSmallScreen={isSmallScreen}
                                    />
 */}{' '}
                  <div className="dashboard-content">
                    <Routes />
                  </div>
                </div>
              </>
            );
          }}
        </Responsive>
      </SidebarProvider>
    </div>
  );
}
