// Set the application as initialized
export const INITIALIZE = 'INITIALIZE_TREATMENTS';
export const INITIALIZE_TENANT = 'INITIALIZE_TREATMENTS_TENANT';

// Set a list of treatment groups
export const SET_TREATMENT_GROUPS = 'SET_TREATMENT_GROUPS';

// Set a single treatment group
export const SET_TREATMENT_GROUP = 'SET_TREATMENT_GROUP';

// Remove a single treatment group
export const DELETE_TREATMENT_GROUP = 'DELETE_TREATMENT_GROUP';

export const SET_ECOMMERCE_CATEGORIES = 'SET_ECOMMERCE_CATEGORIES';
export const SET_ECOMMERCE_CATEGORY = 'SET_ECOMMERCE_CATEGORY';
export const DELETE_ECOMMERCE_CATEGORY = 'DELETE_ECOMMERCE_CATEGORY';

export const SET_ECOMMERCE_SECTIONS = 'SET_ECOMMERCE_SECTIONS';
export const SET_ECOMMERCE_SECTION = 'SET_ECOMMERCE_SECTION';
export const DELETE_ECOMMERCE_SECTION = 'DELETE_ECOMMERCE_SECTION';

export const SET_ECOMMERCE_SUBSECTIONS = 'SET_ECOMMERCE_SUBSECTIONS';
export const SET_ECOMMERCE_SUBSECTION = 'SET_ECOMMERCE_SUBSECTION';
export const DELETE_ECOMMERCE_SUBSECTION = 'DELETE_ECOMMERCE_SUBSECTION';

export const SET_TREATMENTS = 'SET_TREATMENTS';
export const SET_TREATMENT = 'SET_TREATMENT';

export const SET_LOADED_ARCHIVED = 'SET_LOADED_ARCHIVED';
