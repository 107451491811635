import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Table, Forms } from '@borrow-ui/ui';

import { sortObjectsListByAttributes } from 'utils/data';
import { treatmentHasWarnings } from 'apps/treatments/utils';
import './treatments-list.scss';
import {
  TREATMENTS_TREATMENTS_BASE_URL,
  crmStatusesOptions,
  treatmentTypeOptions,
} from 'apps/treatments/constants';
import { userCanPublishDdb } from 'utils/userGroups';
import { EditTreatmentModal } from './EditTreatmentModal';
import { TreatmentWarningAttrsPanel } from './TreatmentWarningAttrsPanel';
import { TreatmentPublishToDdbButton } from './treatment-publish-to-ddb-button/TreatmentPublishToDdbButton';

const { Input, Field, Select } = Forms;

export function TreatmentsList({
  treatmentsList,
  treatments,
  defaultStatus = 'LIVE',
  showStatusFilter = true,
  showTypeFilter = true,
  showWarningFilter = true,
  showEdit = false,
}) {
  const { activeEnvironment } = useSelector((s) => s.portal);
  const [filters, setFilters] = useState({
    crm_status: defaultStatus,
    treatmentType: null,
    warningsOnly: 'no',
  });
  const [search, setSearch] = useState('');

  const treatmentsColumns = useMemo(() => {
    const columns = [
      { prop: 'content' },
      { prop: 'course_services_list', title: 'Services', width: 300 },
      { prop: 'treatment_type', title: 'Type', width: 70 },
      { prop: 'number_of_sessions', title: '# Sess.', width: 70 },
      { prop: 'actions', title: 'Actons', width: 60 },
      { prop: 'has_warnings', title: '', width: 70 },
    ];
    if (showEdit) columns.push({ prop: 'edit_button', title: '', width: 70 });
    return columns;
  }, [showEdit]);

  const filteredEntries = treatmentsList
    .map((t) => {
      return {
        ...t,
        course_services_list: t.course_services.map((csId) => treatments[csId].title).join(', '),
        content: (
          <div>
            <div className="m-b-10">
              <Link to={`${TREATMENTS_TREATMENTS_BASE_URL}/${t.id}`}>{t.title}</Link>
            </div>
            <div className="flex-start-start" style={{ fontSize: 11, gap: 10 }}>
              <div>
                <span className="color-neutral-light m-r-5">Ecomm ID:</span>
                {t.ecomm_id}
              </div>
              <div>
                <span className="color-neutral-light m-r-5">CRM ID:</span>
                {t.crm_id}
              </div>
              <div>
                <span className="color-neutral-light m-r-5">CRM Title:</span>
                {t.crm_title}
              </div>
              <div>
                <span className="color-neutral-light m-r-5">Status:</span>
                {t.crm_status}
              </div>
            </div>
          </div>
        ),
        edit_button: showEdit ? <EditTreatmentModal treatment={t} /> : null,
        actions: (
          <>
            {userCanPublishDdb(activeEnvironment.codename) && (
              <TreatmentPublishToDdbButton treatment={t} />
            )}
          </>
        ),
        has_warnings: <TreatmentWarningAttrsPanel treatment={t} />,
      };
    })
    .filter((tr) => {
      const hasFilters = Object.values(filters).length > 0;
      if (!search && !hasFilters) return true;
      let include = false;
      if (hasFilters) {
        if (filters.crm_status && tr.crm_status !== filters.crm_status) return false;
        if (filters.treatmentType && tr.treatment_type !== filters.treatmentType) return false;
        if (filters.warningsOnly === 'yes' && !treatmentHasWarnings(tr)) return false;
      }
      if (!search) return true;
      if (search) {
        include =
          tr.ecomm_id.toLowerCase().includes(search.toLowerCase()) ||
          tr.title.toLowerCase().includes(search.toLowerCase()) ||
          tr.crm_title.toLowerCase().includes(search.toLowerCase()) ||
          tr.crm_id.toLowerCase().includes(search.toLowerCase()) ||
          tr.course_services_list.toLowerCase().includes(search.toLowerCase());
      }
      return include;
    });
  const entries = sortObjectsListByAttributes(filteredEntries, ['title']);
  return (
    <div className="tl__treatments-list">
      <div className="pl__filters">
        <div className="pl__search-bar">
          <Field label="Search">
            <Input
              className="w-100pc"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Type your search here..."
            />
          </Field>
        </div>
        <div className="pl__filters__selectors">
          {showStatusFilter && (
            <Field label="CRM Status">
              <div className="w-150">
                <Select
                  options={crmStatusesOptions}
                  onChange={(e) => setFilters({ ...filters, crm_status: e ? e.value : null })}
                  value={filters.crm_status}
                  isClearable
                />
              </div>
            </Field>
          )}
          {showTypeFilter && (
            <Field label="Type">
              <div className="w-150">
                <Select
                  options={treatmentTypeOptions}
                  onChange={(e) => setFilters({ ...filters, treatmentType: e ? e.value : null })}
                  value={filters.treatmentType}
                  isClearable
                />
              </div>
            </Field>
          )}
          {showWarningFilter && (
            <Field label="Warnings Only">
              <div className="w-150">
                <Select
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  onChange={(e) => setFilters({ ...filters, warningsOnly: e ? e.value : null })}
                  value={filters.warningsOnly}
                  isClearable
                />
              </div>
            </Field>
          )}
        </div>
      </div>
      <div className="tl__list">
        <Table columns={treatmentsColumns} entries={entries} />
      </div>
    </div>
  );
}

TreatmentsList.propTypes = {
  treatmentsList: PropTypes.array.isRequired,
  treatments: PropTypes.object.isRequired,
  defaultStatus: PropTypes.string,
  showStatusFilter: PropTypes.bool,
  showTypeFilter: PropTypes.bool,
  showWarningFilter: PropTypes.bool,
  showEdit: PropTypes.bool,
};
