import { getRequest, postRequest } from 'libs/requests/requests';
import BaseEntity from 'models/common/base';
import { getDashRevalidateToken, getDashRevalidateUrl } from 'constants/dash.constants';
import { store } from 'store/store';
import { slugify } from 'utils/slugify';
import { asyncTaskEntity } from 'models/common/asyncTask';

import { SET_PRODUCT, SET_PRODUCTS } from '../actions';

export const BASE_URL = 'api/products/products/';
export const PRODUCT_INGESTION_TASK_NAME = 'PRODUCT_INGESTION';

export function newProduct() {
  return {
    id: null,
    tenant: null,
    title: '',
    description: '',
    slug: '', // handle
    status: 'DRAFT',
    price: 0,
    compare_price: null,
    barcode: '',
    measurement: null,
    measurementUnit: '',
    category: '',
    vendor: '',
    product_range: '',
    product_type: '',
    skin_types: [],
    skin_concerns: [],
    image_original: '',
    image_thumbnail: '',
    sku: '',
    out_of_stock: false,
  };
}

export class ProductEntity extends BaseEntity {
  constructor(properties) {
    super('ProductsProduct', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  static emptyEntry() {
    return newProduct();
  }

  get(id, options) {
    return super.get(id, { actionSingleSet: SET_PRODUCT, ...options });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_PRODUCTS,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_PRODUCT });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_PRODUCT);
  }

  publishToDashDdb(environment, tenant, id) {
    return postRequest('api/products/publish-to-dash-ddb/', {
      environment,
      tenant,
      product_id: id,
    });
  }

  async revalidatePageOnDash(environment, tenant, product) {
    const { slug, brand, product_type, skin_types, skin_concerns } = product;

    const state = store.getState();
    const brands = state.products?.productBrands;
    const productTypes = state.products?.productTypes;
    const skinTypes = state.products?.skinTypes;
    const skinConcerns = state.products?.skinConcerns;

    const revalidateUrl = getDashRevalidateUrl(environment, tenant);
    const token = getDashRevalidateToken(environment, tenant);
    const basePath = '/products';

    const paths = [
      // /products
      basePath,
      // /products/[slug]
      ...(slug ? [`${basePath}/${slug}`] : []),
      // /products/brands/[brand]
      ...(brand ? [`${basePath}/brands/${slugify(brands[brand]?.name)}`] : []),
      // /products/types/[productType]
      ...(product_type ? [`${basePath}/types/${slugify(productTypes[product_type]?.name)}`] : []),
      // /products/skin-types/[skinType]
      ...(skin_types?.map((s) => `${basePath}/skin-types/${slugify(skinTypes[s]?.name)}`) || []),
      // /products/skin-concerns/[skinConcern]
      ...(skin_concerns?.map(
        (s) => `${basePath}/skin-concerns/${slugify(skinConcerns[s]?.name)}`
      ) || []),
    ];

    const promises = paths.map((path) => getRequest(revalidateUrl, { qs: { path, token } }));

    return Promise.allSettled(promises).then((results) =>
      results.map((res) => {
        if (res.status === 'rejected') {
          console.error(res.reason);
        }
        return res;
      })
    );
  }

  createIngestionTask() {
    return asyncTaskEntity.create(PRODUCT_INGESTION_TASK_NAME);
  }

  getIngestionTask(taskId) {
    return asyncTaskEntity.get(taskId);
  }

  startIngestionTask(environment, tenant, taskId) {
    return postRequest(`api/products/ingest/${tenant}/${environment}/`, {
      async_task_id: taskId,
    });
  }
}

export const productsEntity = new ProductEntity();
