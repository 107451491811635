export const TASK_STATUSES = {
  QUEUED: 'queued',
  RUNNING: 'running',
  FAILED: 'failed',
  COMPLETED: 'completed',
};

export const TASK_STATUS_COLORS = {
  [TASK_STATUSES.QUEUED]: 'warning',
  [TASK_STATUSES.RUNNING]: 'primary',
  [TASK_STATUSES.FAILED]: 'negative',
  [TASK_STATUSES.COMPLETED]: 'positive',
};
