import BaseEntity from 'models/common/base';

import { SET_TENANTS, SET_TENANT } from '../actions';
export const BASE_URL = 'api/company-portal/tenants/';

export class TenantEntity extends BaseEntity {
  constructor(properties) {
    super('PortalTenant', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_TENANTS,
      ...options,
    });
  }

  get(id, options) {
    return super.get(id, {
      actionSingleSet: SET_TENANT,
      ...options,
    });
  }
}

export const tenantsEntity = new TenantEntity();
