import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@borrow-ui/ui';

import { productsEntity } from 'apps/products/models/product';

export const ProductRevalidateButton = ({ product }) => {
  const { activeEnvironment, activeTenant } = useSelector((s) => s.portal);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    try {
      setIsLoading(true);
      await productsEntity.revalidatePageOnDash(
        activeEnvironment.codename,
        activeTenant.codename,
        product
      );
    } catch (e) {
      alert('An error occurred while revalidating the pages for this Product.');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button loading={isLoading} onClick={onClick}>
      Revalidate
    </Button>
  );
};
