import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Navbar, NavbarLink, Icon, Badge, Title, Loader } from '@borrow-ui/ui';

import * as packageJson from '../../../package.json';

import { getInitials } from 'utils/user';
import { TREATMENTS_BASE_URL, TREATMENTS_ICON_NAME } from 'apps/treatments/constants';
import { UserHeaderBody } from './UserHeaderBody';
import { TenantHeaderBody } from './TenantHeaderBody';

import './header.scss';
import { PRODUCTS_BASE_URL, PRODUCTS_ICON_NAME } from 'apps/products/constants';
import { DASH_USER_LOOKUP_BASE_URL, DASH_USER_LOOKUP_ICON_NAME } from 'apps/dash/constants';
import { userCanLookupUser } from 'utils/userGroups';
import { EnvironmentHeaderBody } from './EnvironmentHeaderBody';

export function Header({ isSmallScreen, sidebarContext }) {
  const authentication = useSelector((s) => s.authentication);
  const { activeTenant, activeEnvironment, environments } = useSelector((s) => s.portal);
  const [sidebarState, setSidebarState] = useContext(sidebarContext);
  const sidebarWidth = 46;

  const sidebarTriggerStyle = {
    width: sidebarWidth,
    cursor: 'pointer',
  };
  const SidebarTrigger = (
    <span
      style={sidebarTriggerStyle}
      className="header__sidebar-trigger borrow-ui__navbar__group__item--clickable"
      onClick={() => {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          status: sidebarState.status === 'open' ? 'closed' : 'open',
        }));
      }}
    >
      {sidebarState.status === 'closed' ? <Icon name="menu" /> : <Icon name="close" />}
    </span>
  );

  const left = [
    (isSmallScreen || sidebarState.status === 'closed') && SidebarTrigger,
    {
      headerLabel: (
        <NavbarLink tag={Link} to="/" style={{ display: 'flex' }}>
          <Title tag="h3" className="header__title">
            {isSmallScreen ? 'Dash QA' : 'Dash Quick Admin'}
          </Title>
        </NavbarLink>
      ),
      name: 'home',
    },
    {
      headerLabel: (
        <NavbarLink tag={Link} to={PRODUCTS_BASE_URL} style={{ display: 'flex' }}>
          <Icon name={PRODUCTS_ICON_NAME} size="smaller" className="m-r-5" /> Products
        </NavbarLink>
      ),
      name: 'products',
    },
    {
      headerLabel: (
        <NavbarLink tag={Link} to={TREATMENTS_BASE_URL} style={{ display: 'flex' }}>
          <Icon name={TREATMENTS_ICON_NAME} size="smaller" className="m-r-5" /> Treatments
        </NavbarLink>
      ),
      name: 'treatments',
    },
    userCanLookupUser() && {
      headerLabel: (
        <NavbarLink tag={Link} to={DASH_USER_LOOKUP_BASE_URL} style={{ display: 'flex' }}>
          <Icon name={DASH_USER_LOOKUP_ICON_NAME} size="smaller" className="m-r-5" /> User Lookup
        </NavbarLink>
      ),
      name: 'user-lookup',
    },
  ].filter((l) => !!l);

  return (
    <Navbar
      sticky={false}
      fixed={true}
      left={left}
      right={[
        {
          headerLabel: `v${packageJson.version}`,
          name: 'version',
        },
        {
          headerLabel: (
            <div className="flex-center-center">
              Env:
              {activeEnvironment ? (
                <span className="m-l-15 flex-center-center">{activeEnvironment.name}</span>
              ) : !environments ? (
                <Loader type="inline" />
              ) : (
                'Not set'
              )}
            </div>
          ),
          name: 'environment',
          bodyItem: function HeaderTenantBody({ resetState }) {
            return <EnvironmentHeaderBody resetState={resetState} />;
          },
        },
        {
          headerLabel: (
            <div className="flex-center-center">
              Tenant:
              {activeTenant ? (
                <span className="m-l-15 flex-center-center">
                  {activeTenant.name}
                  <img
                    src={`/images/flags/${activeTenant.codename}.png`}
                    alt={activeTenant.name}
                    width={25}
                    height={25}
                    className="m-l-10"
                  />
                </span>
              ) : (
                <Loader type="inline" />
              )}
            </div>
          ),
          name: 'tenant',
          bodyItem: function HeaderTenantBody({ resetState }) {
            return <TenantHeaderBody resetState={resetState} />;
          },
        },
        {
          headerLabel: (
            <Badge data-header-item="user-badge" type="circular" color="accent" className="p-10">
              {getInitials(authentication.user)}
            </Badge>
          ),
          name: 'user',
          bodyItem: function HeaderUserBody({ resetState }) {
            return <UserHeaderBody user={authentication.user} resetState={resetState} />;
          },
        },
      ]}
    />
  );
}

Header.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
  sidebarContext: PropTypes.object.isRequired,
};
