import React from 'react';
import PropTypes from 'prop-types';

import { Title, Text } from '@borrow-ui/ui';

export function FormsErrors({ submitMessage }) {
  if (!submitMessage || !submitMessage.errors) return null;

  return (
    <div>
      <Title tag="h4" className="color-negative m-0">
        Errors
      </Title>
      {Object.values(submitMessage.errors).map((error, i) => {
        return (
          <Text key={i} size="small" className="m-0 m-b-5">
            {error}
          </Text>
        );
      })}
    </div>
  );
}

FormsErrors.propTypes = {
  submitMessage: PropTypes.shape({
    errors: PropTypes.object,
  }),
};
