import React from 'react';

import { HomeMenu } from './HomeMenu';

import './home.scss';

const IMAGE_PATHS = [
  '/images/login_image.png',
  '/images/organisation_tree.png',
  '/images/project_management.png',
  '/images/timesheets.png',
];

export function Home() {
  const imagePath = IMAGE_PATHS[Math.floor(Math.random() * IMAGE_PATHS.length)];

  return (
    <div className="home">
      <HomeMenu />
      <img src={imagePath} className="home__image" alt="home" />
    </div>
  );
}
