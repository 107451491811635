import React from 'react';
import PropTypes from 'prop-types';

import { Block, Row, Col, Text, Title, Button } from '@borrow-ui/ui';

import { store } from 'store/store';
import { useSelector } from 'react-redux';
import { SET_ACTIVE_ENVIRONMENT } from 'apps/portal/actions';

export function EnvironmentHeaderBody({ resetState }) {
  const { activeTenant, activeEnvironment, environments } = useSelector((s) => s.portal);
  const setActiveEnvironment = (environment) => {
    console.log('Set environment:', environment);
    store.dispatch({ type: SET_ACTIVE_ENVIRONMENT, data: environment });
    localStorage.setItem('activeEnvironmentId', environment.id);
    resetState();
  };

  return (
    <Block>
      <Row>
        <Col colClassName="col-xs-6 col-sm-2 flex-center-center">
          <div>
            <Title className="m-t-0">
              {activeEnvironment ? activeEnvironment.name : 'Not Set'}
            </Title>
            <Text>
              Environment selection only affects images buckets and link generations (including
              exported values)
            </Text>
          </div>
        </Col>
        <Col colClassName="col-xs-6 col-sm-4"></Col>
        <Col colClassName="col-xs-6 col-sm-3"></Col>
        <Col colClassName="col-xs-6 col-sm-3">
          <Title tag="h3" className="m-t-0">
            Change Environment
          </Title>

          <div>
            {Object.values(environments)
              .filter((e) => e.tenant === activeTenant.id)
              .map((environment) => {
                return (
                  <div key={environment.id} className="">
                    <Button
                      mean="regular-reverse"
                      onClick={() => {
                        setActiveEnvironment(environment);
                      }}
                      className="w-200"
                    >
                      <span className="flex-center-center">{environment.name}</span>
                    </Button>
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
    </Block>
  );
}

EnvironmentHeaderBody.propTypes = {
  resetState: PropTypes.func.isRequired,
};
