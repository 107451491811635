import BaseEntity from 'models/common/base';

import { DELETE_SKIN_TYPE, SET_SKIN_TYPE, SET_SKIN_TYPES } from '../actions';
export const BASE_URL = 'api/products/skin-types/';

export class SkinTypeEntity extends BaseEntity {
  constructor(properties) {
    super('ProductsSkinType', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_SKIN_TYPES,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_SKIN_TYPE });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_SKIN_TYPE });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_SKIN_TYPE);
  }
}

export const skinTypesEntity = new SkinTypeEntity();
