export function listToObject(list, getKey = 'id') {
  if (!Array.isArray(list)) {
    throw new Error('list - A list is required as first argument');
  }
  if (!getKey) {
    throw new Error('getKey - A function or a string is required as second argument');
  }

  if (list.length === 0) return {};

  const getKeyFunc = typeof getKey === 'function' ? getKey : (entry) => entry[getKey];

  return list.reduce((acc, entry) => {
    acc[getKeyFunc(entry)] = entry;
    return acc;
  }, {});
}

export function elementByAttribute(
  collectionObject,
  attribute,
  value,
  { multipleExpected = false } = {}
) {
  const filteredParameters = Object.values(collectionObject).filter(
    (obj) => obj[attribute] === value
  );

  if (multipleExpected && filteredParameters.length >= 1) return filteredParameters[0];

  return filteredParameters.length === 1 ? filteredParameters[0] : undefined;
}

export function groupBy(array, key) {
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
}

export function orderElementsByNameNumber(e1, e2) {
  // get number and compare with it
  const pattern = /\d+/;
  const i1_n = e1.name.match(pattern);
  const i2_n = e2.name.match(pattern);
  if (!i1_n || !i2_n) return e1.name > e2.name ? 1 : -1;
  return +i1_n[0] - +i2_n[0];
}

function compare(a, b, attributes) {
  /**
   * Recursively compare two objects by the first attribute.
   * If they have the same value, use the next attribute in the list.
   *
   * If the attribute starts with a '-', then the order will be reversed.
   */
  let attribute_s = attributes[0];
  let direction = 1;
  if (attribute_s[0] === '-') {
    direction = -1;
    attribute_s = attribute_s.substr(1);
  }
  const valA = a[attribute_s];
  const valB = b[attribute_s];

  if (valA && !valB) return 1 * direction;
  if (!valA && valB) return -1 * direction;

  if (valA > valB) return 1 * direction;
  if (valA < valB) return -1 * direction;
  if (attributes.length > 1) return compare(a, b, attributes.slice(1));
  return 1 * direction;
}

export function sortObjectsListByAttributes(list, attributes) {
  /**
   * Sort a list of objects by a list of attributes.
   * If the attribute name starts with '-', the order will be reversed.
   *
   * i.e. list = [
   *  {name: 'a', cat: '2'},
   *  {name: 'c', cat: '1'},
   *  {name: 'b', cat: '2'},
   * ]
   *
   * attributes = ['cat', '-name']
   * will sort by cat first (c) and reverse name if cat is the same (b, a).
   */
  return list.sort((a, b) => compare(a, b, attributes));
}
