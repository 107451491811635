export const PORTAL_BREADCRUMBS = [{ link: '/', label: 'Home' }];
export const PORTAL_BASE_URL = `/portal`;

export const PORTAL_ICON_NAME = 'apps';
export const FILE_UPLOADER_ICON_NAME = 'upload_file';

export const PORTAL_PORTAL_BREADCRUMBS = [
  ...PORTAL_BREADCRUMBS,
  { link: PORTAL_BASE_URL, label: 'Portal' },
];

/** File uploader */
export const PORTAL_FILE_UPLOADER_BASE_URL = `${PORTAL_BASE_URL}/file-uploader`;
export const PORTAL_FILE_UPLOADER_BREADCRUMBS = PORTAL_PORTAL_BREADCRUMBS;

/** Clear Data */
export const PORTAL_IMPORT_DATA_BASE_URL = `${PORTAL_BASE_URL}/import-data`;
export const PORTAL_IMPORT_DATA_BREADCRUMBS = PORTAL_PORTAL_BREADCRUMBS;

/** Clear Data */
export const PORTAL_CLEAR_DATA_BASE_URL = `${PORTAL_BASE_URL}/clear-data`;
export const PORTAL_CLEAR_DATA_BREADCRUMBS = PORTAL_PORTAL_BREADCRUMBS;

/** Users */

export const PREFERENCES_BASE_URL = `${PORTAL_BASE_URL}/preferences`;
export const USERS_BASE_URL = `${PORTAL_BASE_URL}/users`;

export const USERS_BREADCRUMBS = [...PORTAL_BREADCRUMBS, { link: USERS_BASE_URL, label: 'Users' }];

/** Other APIs */
export const PREFERENCES_USER_PASSWORD_CHANGE_URL =
  'api/company-portal/preferences/password-change';
export const FILE_UPLOADER_ENDPOINT = 'api/company-portal/file-uploader/upload-file';
export const CLEAR_DATA_ENDPOINT = 'api/treatments/clear-data';
export const IMPORT_DATA_ENDPOINT = 'api/treatments/import-data';
export const CLEAR__PRODUCT_DATA_ENDPOINT = 'api/products/clear-data';
