import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@borrow-ui/ui';
import { treatmentsEntity } from 'apps/treatments/models/treatment';
import { userCanPublishDdb } from 'utils/userGroups';

export const TreatmentPublishToDdbButton = ({ treatment }) => {
  const { activeEnvironment, activeTenant } = useSelector((s) => s.portal);
  const [isLoading, setIsLoading] = useState(false);

  if (!userCanPublishDdb(activeEnvironment.codename)) return;

  const onClick = async () => {
    const confirmed = window.confirm(
      `Are you sure? "${treatment.title}" will be exported to ${activeEnvironment.name} - ${activeTenant.name}.`
    );

    if (!confirmed) return;

    try {
      setIsLoading(true);
      await treatmentsEntity.publishToDashDdb(
        'treatments',
        activeEnvironment.codename,
        activeTenant.codename,
        treatment.id
      );
    } catch (e) {
      alert('An error occurred while exporting this Treatment to Dash.');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button loading={isLoading} onClick={onClick}>
      Export to Dash
    </Button>
  );
};
