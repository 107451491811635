import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Icon, Tooltip } from '@borrow-ui/ui';

export function Image({ src, warning = true, showName = true, width = 100 }) {
  const { activeEnvironment } = useSelector((s) => s.portal);
  if (!src)
    return warning ? (
      <Tooltip tooltip="Image is not set">
        <Warning />
      </Tooltip>
    ) : null;
  const fullSrc = `${activeEnvironment.image_bucket_base_url}${src}`;
  return (
    <>
      <a href={fullSrc} target="_blank" rel="noopener noreferrer">
        <img width={width} src={fullSrc} alt={src} />
      </a>
      {showName && <div className="flex-center-center">{src.split('/').at(-1)}</div>}
    </>
  );
}

Image.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  warning: PropTypes.bool,
  showName: PropTypes.bool,
};

function Warning() {
  return <Icon name="warning" className="color-warning" />;
}
