import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Page, Breadcrumbs } from '@borrow-ui/ui';
import { PRODUCTS_PRODUCTS_BASE_URL, PRODUCTS_PRODUCTS_BREADCRUMBS } from 'apps/products/constants';
import { ProductForm } from 'apps/products/components/product/ProductForm';
import { productsEntity } from 'apps/products/models/product';

export function EditProductFormPage() {
  const history = useHistory();
  const { products } = useSelector((s) => s.products);
  const [loaded, setLoaded] = useState(false);

  const { id: productId } = useParams();

  const product = products[+productId];

  useEffect(() => {
    if (loaded || !productId) return;

    productsEntity.get(productId).then(() => {
      setLoaded(true);
    });
  }, [loaded, productId]);

  const onSubmitSuccess = (savedProduct) => {
    history.push(`${PRODUCTS_PRODUCTS_BASE_URL}/${savedProduct.id}`);
  };

  const onCancel = () => {
    history.push(`${PRODUCTS_PRODUCTS_BASE_URL}/${product.id}`);
  };

  return (
    <Page
      title={
        <>
          <Breadcrumbs breadcrumbs={PRODUCTS_PRODUCTS_BREADCRUMBS} />
          <h2 className="m-t-0 color-primary">Edit {product?.title || ''}</h2>
        </>
      }
    >
      {loaded && (
        <ProductForm product={product} onSubmitSuccess={onSubmitSuccess} onCancel={onCancel} />
      )}
    </Page>
  );
}
