import BaseEntity from 'models/common/base';

import { DELETE_PRODUCT_TYPE, SET_PRODUCT_TYPE, SET_PRODUCT_TYPES } from '../actions';
export const BASE_URL = 'api/products/product-types/';

export class ProductTypeEntity extends BaseEntity {
  constructor(properties) {
    super('ProductsProductType', {
      baseUrl: BASE_URL,
      hasPagination: false,
      ...properties,
    });
  }

  fetch(options) {
    return super.fetch({
      fetchActionSet: SET_PRODUCT_TYPES,
      ...options,
    });
  }

  save(entry) {
    return super.save(entry, { autoGet: true, actionSingleSet: SET_PRODUCT_TYPE });
  }

  delete(id) {
    return super.delete(id, { deleteAction: DELETE_PRODUCT_TYPE });
  }

  localUpdate(entry) {
    return super.localUpdate(entry, SET_PRODUCT_TYPE);
  }
}

export const productTypesEntity = new ProductTypeEntity();
