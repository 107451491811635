import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@borrow-ui/ui/components/forms/input/Input';
import { useSelector } from 'react-redux';
import { Button, Table } from '@borrow-ui/ui';

import './treatment-selector.scss';

const treatmentsColumns = [
  { prop: 'crm_id', title: 'CRM ID', width: 80 },
  { prop: 'title', title: 'Title' },
  { prop: 'course_services_list', title: 'Services', width: 400 },
  { prop: 'treatment_type', title: 'Type', width: 70 },
  { prop: 'crm_status', title: 'CRM Status', width: 100 },
  { prop: 'number_of_sessions', title: '# Sess.', width: 90 },
  { prop: 'number_of_cycles', title: '# Cycles', width: 90 },
  { prop: 'controls', title: '', width: 100 },
];

export function TreatmentSelector({ selectedTreatment, setSelectedTreatment }) {
  const { treatments } = useSelector((s) => s.treatments);
  const { activeTenant } = useSelector((s) => s.portal);
  const [filters] = useState({});
  const [search, setSearch] = useState('');

  const entries = Object.values(treatments)
    .filter((t) => t.tenant === activeTenant.id)
    .map((e) => {
      return {
        ...e,
        course_services_list: e.course_services.map((csId) => treatments[csId].title).join(', '),
        controls: (
          <div>
            {e.id !== selectedTreatment && (
              <Button
                size="smaller"
                mean="primary"
                onClick={() => setSelectedTreatment(e.id)}
                disabled={!!selectedTreatment}
              >
                Select
              </Button>
            )}
            {e.id === selectedTreatment && (
              <Button size="smaller" mean="primary" onClick={() => setSelectedTreatment(null)}>
                Deselect
              </Button>
            )}
          </div>
        ),
      };
    })
    .filter((tr) => {
      if (!search && Object.values(filters).length === 0) return true;
      let include = false;
      if (search) {
        include =
          tr.title.toLowerCase().includes(search.toLowerCase()) ||
          tr.crm_id.toLowerCase().includes(search.toLowerCase()) ||
          tr.course_services_list.toLowerCase().includes(search.toLowerCase());
      }
      return include;
    });

  return (
    <div className="ts__treatment-selector">
      <div className="ts__search-bar">
        <Input className="w-100pc" onChange={(e) => setSearch(e.target.value)} value={search} />
      </div>
      <div className="ts__filters"></div>
      <div className="ts__list">
        <Table columns={treatmentsColumns} entries={entries} />
      </div>
    </div>
  );
}

TreatmentSelector.propTypes = {
  selectedTreatment: PropTypes.number,
  setSelectedTreatment: PropTypes.func,
};
